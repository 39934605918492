export default{
    kwaf: {
        userDash: "User Dashboard",
        hostname: "Public Hostname",
        collumName: 'Name',
        publicIp: 'Public IP',
        notDefined: '(Not defined)',
        privateIp: 'Private IP',
        vpcIp:  'VPC IP',
        state: 'State',
        running: 'Running',
        down: 'Stopped',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
    },
    vullify: {
        collumName: 'Name',
        publicIp: 'Public IP',
        notDefined: '(Not defined)',
        privateIp: 'Private IP',
        vpcIp:  'VPC IP',
        running: 'Running',
        down: 'Stopped',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
    }
}