export default{
    state: "État",
    running: "En marche",
    down: "En arrêt",
    start: "Démarrer",
    stop: "Arrêter",
    reboot: "Redémarrer",
    publicHostname: "Nom d'hôte public",
    privateHostname: "Nom d'hôte privé",
    notDefined: "Non défini",
    publicIp: "Adresse IP public",
    ipVpc: "IP du VPC",
    passwordUpdated: "Mot de passe changé.",
    "location": "Emplacement",
    "openPorts": "Ports ouverts",
    "port": "Port",
    "openPortTooltips": "Les ports seront uniquement accessibles depuis le domaine. Pour utiliser d'autres ports, vous pouvez utiliser NGROK, Cloudflare Tunnels ou d'autres alternatives.",
    vps: {
        tab1: {
            title: "Connexion",
            h1: "Se connecter à vos instances",
            desc: "Vous pouvez vous connecter en utilisant votre navigateur ou votre propre client SSH compatible",
            useBrowser: "Utiliser votre navigateur",
            usingWebBrowserText: "Se connecter en utilisant notre client SSH basé sur un navigateur",
            useOwn: "Utiliser votre propre client SSH",
            connectToSSH: "Comment se connecter en utilisant un client SSH",
            connectTo: "SE CONNECTER À",
            username: "NOM D'UTILISATEUR",
            newPass: "Nouveau mot de passe",
            enterNewpass: "Saisir le nouveau mot de passe",
            confirmNewPass: "Confirmation du nouveau mot de passe",
            enterConfirmNewPass: "Saisir la confirmation du nouveau mot de passe",
            updatePassBtn: "Modifier le mot de passe",
            ipVpc: "IP du VPC",
        },
        networkTab:{
            title: "Réseaux",
            h1: "Réseau IPv4",
            desc: "L'adresse IP de votre instance est accessible à Internet, l'adresse IP privée est accessible uniquement aux autres ressources de votre compte KeepSec.",
            publicIp: "Adresse IP public",
            ipi4firewall: "Pare-feu IPv4",
            createRuleOpenPort: "Créer des règles pour ouvrir des ports vers Internet ou pour spécifier des adresses IPv4 et des plages.",
            leanHowCreateRule: "En savoir plus sur les règles de pare-feu",
            whatIs: "À quoi cela sert ?",
            needEnabled: "Le VPC doit être activé et le serveur doit se trouver dans la zone de disponibilité",
            vpcSubnet: "Sous-réseau VPC",
            vpcNeedEnabled: "Le VPC doit être activé",
            ipv4net: "Réseau dédié IPv4",
            ipv4NetDesc: "Ce réseau ne sera accessible qu'aux autres VPS ajoutés dans votre zone de disponibilité VPC.",
            enable: "Activer",
            leanMore: "En savoir plus",
            addRemoveVpc: "Ajouter et supprimer des VPS de votre réseau VPC",
            zone: "Zone",
            search: "Saisir la recherche",
            myVps: "Mes VPS",
            addTozone: "Ajouter {{serveName}} à la zone actuelle",
            removeTozone: "Supprimer {{serveName}} de la zone actuelle",
            az: "Zone de disponibilité",
            addRule: "Ajouter une règle",
            ipVpc: "VPC IP",
            ____: "______",
            ____: "______",
            ____: "______",
            ____: "______",
        },
        rebuildTab:{
            title: "Reconstruire",
            h1: "Reconstruction de cet instance",
            go: "Reconstruire",
            pickTorebuild: "Choisissez votre image de reconstruction"
        },
        upgradeTab:{
            title: "Mettre à niveau",
            h1: "Mettre à niveau",
            pickToUpgrade: "Choisissez votre plan de mise à niveau",
            noPlan: "Aucun plan de mise à niveau disponible !",
            upgradeNow: "Mettre à niveau",
            _____: "_______"
        },
        blueprint:{
            title: "Blueprint",
            h1: "Sélectionner un blueprint"
        },
        security: {
            title: "Sécurité",
            vuln: {
                title: "Analyse de vulnérabilité des ports",
                desc: "Analyser les vulnérabilités potentielles sur les ports ouverts du serveur",
                scan: "Analyser",
                title2: "Vulnérabilités",
                desc: "Notez que l'appareil peut ne pas être affecté par tous ces problèmes, les vulnérabilités sont implicites en fonction du logiciel et de la version.",
                noVuln: "Aucune vulnérabilité trouvée sur votre serveur !",
                noInfo: "Aucune information disponible",
                _____: "_______"
            },
            port: {
                title: "Port ouvert",
                noPort: "Aucun port ouvert sur votre serveur !",
                noInfo: "Aucune information disponible"
            },
            general: {
                title: "Informations générales",
                hostname: "Nom d'hôte",
                general: "Aucun nom d'hôte trouvé !",
                noInfo: "Aucune information disponible"
            },
            tech: {
                title: "Technologies Web",
                noTech: "Aucune technologie Web trouvée sur votre serveur !",
                noInfo: "Aucune information disponible",
                _____: "_______"
            }
        },
        storageTab: {
            title: "Stockage",
            h1: "Blocs de Volume de Stockage",
        }
    },
    "console": "Console",
    "files": "Fichiers",
    "vpsmini": {
        "overview": {
            "title": "Aperçu",
            "howtoConnectUsingFTP": "Se connecter en utilisant un client FTP",
            "username": "Nom d'utilisateur",
            "useOwnFTP": "Utiliser votre propre client FTP",
            "useOwnDomain": "Utiliser votre propre domaine",
            "makeSureTypeAEnabled": "Veuillez vous assurer d'avoir un enregistrement DNS de type \"A\" \n pointant vers",
            "domainList": "Liste de domaines",
            "addNewDomain": "Ajouter un nouveau domaine",
            "removeDomain": "Supprimer ce domaine",
            "addFirstDomain": "Ajouter votre premier domaine",
            "microBlueprint": "Micro Blueprint",
            "cutomDomainAddInput": "Mon propre domaine ici",
            "cutomDomainSave": "Enregistrer",
            "______": "_________",
            "______": "_________",
        },
        "changePassworw": {
            "title": "Mot de passe",
            "h1": "Modifier le mot de passe de cette instance",
            "newPassLabel": "Nouveau mot de passe",
            "newPassPlaceHolder": "Saisissez le nouveau mot de passe",
            "newPassConfirmLabel": "Confirmation du nouveau mot de passe",
            "newPassConfirmLabel": "Saisissez la confirmation du nouveau mot de passe",
            "newPassConfirmLabelPlaceholder": "Saisissez la confirmation du nouveau mot de passe",
            "changePassword": "Modifier le mot de passe",
            "______": "_________",
        }
    }
}