import { Alert, Button, Checkbox, Divider } from "antd"
import React , { useEffect, useState } from "react"
import { TabPanel, useTabs } from "react-headless-tabs";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { updateHeadLink } from "reducer/action/coreActions";

import { InputTextAdd, LabelAdd } from '../../../components/Bloc';
import { TabSelector } from "../../../components/TabSelector";
import UserAccountConfirmPrompt from '../../../components/UserAccountConfirmPrompt'
import { STANDARD_ERROR_MESSAGE } from "../../../constants/message"
import K8S from '../../../lib/k8s';
import LB from '../../../lib/loadBalancer';
import UserMgt from '../../../lib/user_managment';
import  Utils  from '../../../utils';
import { ComputeLoadBalancer, ComputeLoadBalancerInstance, CustomIpDomaineInput, KubernetesLoadBalancer, KubernetesLoadBalancerInstance, Source, Type } from './KsIbSection'

const tabs = [
  "Compute",
  "Platform",
  "Kubernetes",
  "Custom",
//   "Infrastructure"
]


const IPandPortContrainct = {
	external: "ip;port;customDomain:kubernetes;",
	vpc: "ip;port",
	custom: "ip;port",
	default: "ip;port",

	ha:"master;externalApiPort"
}


export default function NewKsIb(props){
	const [server, setvps] = useState(null)
	const { id } = useParams()
	const user = useSelector(app => app?.user?.user)
	// const vpc = useSelector(app => app?.vpc)
	const navigate = useNavigate()
	const dispatch  = useDispatch()
    const {messageApi} = useSelector(app => app.core )
	const [ filterByvpsM, setBalanceByVpsM] = useState(false)
	const [vps_params, setVpsParams] = useState({})
	const [errorMessage, setErrorMesssage] = useState(null)
    const [creating, setCreating] = useState(false)
    const [selectedTab, setSelectedTab] = useTabs(tabs);
	const isCustom = selectedTab==="Custom"

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

	const filterByVpMChanged = (status) => {
		setVpsParams({...vps_params, source: null, destination: null, type: null, instances: []})
		setBalanceByVpsM(status)
	}
	const [customSourceDomain, setDest] = useState([
		{id: 1},
		{id: 2},
	])

    
    async function loadItems(){
        // await UserMgt.get_user_vps() 
        await K8S.getK8sInstance() 
    }

    const onChange = (key) => {
	  console.log(key);
	};

	const loadVps = async () => {
        const vps = await UserMgt.getUniqueVps(id)
        if(!vps?.error){
            setvps(vps?.server)
            return vps?.server
        }else{
            return navigate(-1)
        }
    }

    async function onCreate(ev){
    	console.log(' Submited ')
    	ev.preventDefault()
    	setErrorMesssage(null)
		const dest = () => {
			if(selectedTab === "Compute" || selectedTab === "Platform"){
				return vps_params?.instances
			}
			if(isCustom){
				return customSourceDomain && customSourceDomain.map(s => s.serviceAdress)
			}
			if(selectedTab === "Kubernetes"){
				// const clusters =  vps_params?.clusters
				// const nodeMasterInstance = []
				// clusters.forEach(cluster => {
				// 	if(!!cluster?.isPrimaryInstance){
				// 		nodeMasterInstance.push(cluster?.instances?.find(inst => !!inst.isPrimarykubeMaster))
				// 	}
				// });
				return vps_params?.clusters
			}
			return []
		}
		const destinations = dest()
		console.log(' Start check ', destinations)
		if(isCustom){
			for (let k = 0; k < destinations.length; k++) {
				if(!Utils.validateDNSorIP(destinations[k])){
					return messageApi.open({
						type: 'error',
						content: "The value '"+destinations[k]+"' is not valid as IP address or domain name"
					})
				}
				
			}
		}
		const shouldCheckDest = !(vps_params?.httpsredirect && vps_params?.type?.name === "http") || selectedTab === "Kubernetes"
	
		if(shouldCheckDest){
			if(!destinations || destinations?.length < 2){
				return messageApi.open({
		          type: 'error',
		          content: "Select at least 2 services to load balance",
		        });
			}
			if(!isCustom){
				if(!vps_params?.destination){
					return messageApi.open({
					  type: 'error',
					  content: "Please pick the destination",
					});
				}
			}
			if(!vps_params?.port){
		        return messageApi.open({
		          type: 'error',
		          content: "Enter a port",
		        });
			}
		}
		if(!vps_params?.source){
	        return messageApi.open({
	          type: 'error',
	          content: "Choose a source",
	        });
		}
		if(!vps_params?.type){
	        return messageApi.open({
	          type: 'error',
	          content: "Choose a type",
	        });
		}
		console.log(' End check ')
		
		const data = {
			"destinations": destinations,
			"hostendpoint": {
				desc: vps_params?.source?.desc,
				name: vps_params?.source?.name
			},
			"upstreamport": vps_params?.port,
			destinationType: isCustom ? "Custom domain" :  vps_params?.destination?.name,
		}
		if(vps_params?.httpstohttp && vps_params?.type?.name === "https"){
			data.httpstohttp = true
		}
		if(vps_params?.httpsredirect && vps_params?.type?.name === "http"){
			data.httpsredirect = true
		}
		const body = {
			data,
			type: vps_params?.type?.name,
			ksLbServerId: server?.Id,
			customDomain: vps_params?.customDomain,
			isKubernetes: selectedTab === "Kubernetes",
			isCustom: isCustom
		}
		console.log(' End check ', body)
		setCreating(true)
		const result = await LB.createBalance(body)
		console.log('data data data ', data, result)
		setCreating(false)
        if(!result || result?.error){
        	setErrorMesssage(result?.message || "An error occured, please try again or contact us.")
        }else{
			messageApi.open({
	          type: "success",
	          content: " New Load balancer created!" ,
	        });
			navigate(-1)
        }
	}

	useEffect(()=>{
		loadItems() 
        loadVps().then((server) => {
            const headLinks = [
                {
                    link: "/networking/kslb",
                    item: 'kslb',
                    alt: "kslb short link"
                },
                {label: server?.DisplayName, arrow: true, link: "/networking/kslb/"+server?.Id+"/manage"},
                {label: "Add LoadBalancer", arrow: true}
    
            ]
            dispatch(updateHeadLink(headLinks))

            setVpsParams({
            	...vps_params,
		        source: {
					name: "Default domain",
					type: "default_domain",
					desc: server?.domain
				},
				type: {
					label: "HTTPS",
					name: "https",
				}
			    
            })

                
        })
        return () => dispatch(updateHeadLink([]))
    }, [])


	return(
		<div className='relative pb-10'>
            <div className={user && !user?.EmailConfirmed ?'opacity-[20%]'  : ''}>
            	<form onSubmit={onCreate} className="px-3 md:px-8 relative  dark:text-darkTextColor ">
                    <div className="container max-w-full">
                        
						
						{!(vps_params?.httpsredirect && vps_params?.type?.name === "http") ?
							
							<div 
								className={`mt-10x `}
							>
								<p className='
										text-center 
										xl:text-xl 2xl:text-2xl 
										font-bold 
										text-primary 
										dark:text-darkTextColor
									'
								>   
									Pick service to load balance
								</p>
								<div className="mt-5 2xl:px-10 xl:px-5">
									<nav className="flex justify-center  border-b border-gray-300 dark:border-gray-800 overflow-x-auto">
										{tabs.map((tab, k) =>(
											<TabSelector 
												key={k}
												isActive={selectedTab === tab}
												onClick={() => {
													if(tab==="Kubernetes") setVpsParams({...vps_params, type: {label: "TCP", name: "tcp" }, port: '6443'})
													if(tab !=="Compute"){ 
														setBalanceByVpsM(false)
														// setVpsParams({...vps_params, port: ''})
													}
													setSelectedTab(tab)
												}}
												isMini
											>
												{tab}
											</TabSelector>
										))}
									</nav>
								</div>
								<div className="mt-5">
									<TabPanel hidden={selectedTab !== "Kubernetes"}>
										<KubernetesLoadBalancer 
											creating={creating}
											setVpsParams={setVpsParams}
											vps_params={vps_params}
										/>
									</TabPanel>
									<TabPanel hidden={selectedTab !== "Compute"}>
										<ComputeLoadBalancer  
											creating={creating}
											setVpsParams={setVpsParams}
											vps_params={vps_params}
											filterByvpsM={filterByvpsM}
											setBalanceByVpsM={filterByVpMChanged}
										/>
									</TabPanel>
									<TabPanel hidden={selectedTab !== "Platform"}>
										<ComputeLoadBalancer 
											creating={creating}
											setVpsParams={setVpsParams}
											vps_params={vps_params}
											filterByvpsM={filterByvpsM}
											setBalanceByVpsM={filterByVpMChanged}
											app
										/>
									</TabPanel>
									<TabPanel hidden={selectedTab !== "Custom"}>
										{isCustom ?
											<CustomIpDomaineInput 
												creating={creating}
												setVpsParams={setVpsParams}
												vps_params={vps_params}
												sourceIps={customSourceDomain}
												setSourceIp={setDest}
											/>
											:
											null
										}
									</TabPanel>
								</div>
							</div>
							:
							null
						}

						<div className='mx-auto mt-10'>
                        	<p className='
                                    text-center 
                                    xl:text-xl 2xl:text-2xl 
                                    font-bold 
                                    text-primary 
                                    dark:text-darkTextColor
                                '
                            >   
                            	Choose a source
                            </p>

                            <Source 
                            	creating={creating}
                            	setVpsParams={setVpsParams}
                            	vps_params={vps_params}
                            	server={server}
                            	filterByvpsM={filterByvpsM}
                            	selectedType = {vps_params?.source?.type}
                            	
                            />
						</div>

						<div className="mt-10">
							<p className='
                                    text-center 
                                    xl:text-xl 2xl:text-2xl 
                                    font-bold 
                                    text-primary 
                                    dark:text-darkTextColor
                                '
                            >   
                            	Choose a type 
                            </p>

                            <Type 
                            	filterByvpsM={filterByvpsM}
                            	creating={creating}
                            	setVpsParams={setVpsParams}
                            	vps_params={vps_params}
                            />
						</div>

						{(vps_params?.type?.name === "http" || vps_params?.type?.name==="https")?
							<div className="mt-10">
								<p className='
	                                    text-center 
	                                    xl:text-xl 2xl:text-2xl 
	                                    font-bold 
	                                    text-primary 
	                                    dark:text-darkTextColor
	                                '
	                            >   
	                            	Extra configuration
	                            </p>

	                            <div className="mt-5 text-center">
	                            	{vps_params?.type?.name === "http" ?
			                            <Checkbox 
			                                className="text-sm mx-auto 2xl:text-base font-semibold" 
			                                size='large'
			                                disabled={creating} 
			                                checked={vps_params?.httpsredirect}
			                                onChange={(e) =>{
			                                    setVpsParams({...vps_params, httpsredirect: e.target.checked, httpstohttp: null})
			                                } }
			                            >
			                                Redirect to HTTPS
			                            </Checkbox>
			                            :
			                            <Checkbox 
			                                className="text-sm mx-auto 2xl:text-base font-semibold" 
			                                size='large'
			                                disabled={creating} 
			                                checked={vps_params?.httpstohttp}
			                                onChange={(e) =>{
			                                    setVpsParams({...vps_params, httpstohttp: e.target.checked, httpsredirect: null})
			                                } }
			                            >
			                                All my services is over http
			                            </Checkbox>

	                            	}
								</div>
							</div>
							:
							null
						}

						{!(vps_params?.httpsredirect && vps_params?.type?.name === "http") ?
							<>
								{!isCustom ?
									<div 
										className={`mt-10 `}
									>
										<p className='
												text-center 
												xl:text-xl 2xl:text-2xl 
												font-bold 
												text-primary 
												dark:text-darkTextColor
											'
										>   
											Choose your destination
										</p>
										<Source  
											creating={creating}
											setVpsParams={setVpsParams}
											vps_params={vps_params}
											selectedTab={selectedTab}
											filterByvpsM={filterByvpsM}
											destination
											isCustom={isCustom}
											selectedType = {vps_params?.destination?.type}
											server={server}
										/>
									</div>
									:
									null
								}

								<div 
									className={`mt-10 `}
								>
									<p className='
		                                    text-center 
		                                    xl:text-xl 2xl:text-2xl 
		                                    font-bold 
		                                    text-primary 
		                                    dark:text-darkTextColor
		                                '
		                            >   
		                            	{(selectedTab === "Kubernetes" || selectedTab === "Infrastructure") ? 
		                            		"Select IP and " : 
		                            		null
		                            	} Port
		                            </p>

		                            {false && selectedTab === "Kubernetes" && vps_params?.clusters?.length ?

			                            <KubernetesLoadBalancerInstance 
											creating={creating}
			                            	setVpsParams={setVpsParams}
			                            	vps_params={vps_params}
			                            	handleChange={handleChange}
										/>

										:
										null
		                            }
		                            {false && (selectedTab === "Compute" || selectedTab === "Platform") && vps_params?.instances?.length ?

			                            <ComputeLoadBalancerInstance 
											creating={creating}
			                            	setVpsParams={setVpsParams}
			                            	vps_params={vps_params}
			                            	handleChange={handleChange}
			                            	app={selectedTab === "Platform"}
										/>

										:
										null
		                            }
		                            <div className="flex justify-center justify-center w-full">
			                            <div className="px-5 xl:px-0 gap-5 mx-auto xl:w-[800px] flex-col  w-full flex-wrap flex items-center">
				                            <div className="w-full xl:w-[390px] mt-2">
							                    <LabelAdd
							                        htmlFor="port"
							                        placeholder={"Identify the port"}
							                    />
							              		<InputTextAdd
							                        idx="port"
							                        value={vps_params.port || ""}
							                        name="port"
							                        onChange={handleChange}
							                        required
							                        disabled={creating}
							                        placeholder='Identify the port'
							                    />
							                </div>
							                {/* {vps_params?.destination?.type ==="external" && selectedTab === "Kubernetes" ?
							                	<div className="w-full xl:w-[390px] mt-2">
								                	<Checkbox 
						                                className="text-sm mx-auto 2xl:text-base font-semibold" 
						                                size='large'
						                                checked={vps_params?.customDomainEnabled}
						                                disabled={creating} 
						                                onChange={(e) =>{
						                                    setVpsParams({...vps_params, customDomainEnabled: e.target.checked})
						                                } }
						                            >
						                                Enable custom domain
						                            </Checkbox>	
					                            </div>	
					                            :
					                            null	
							                } */}
							                {
											// vps_params?.destination?.type ==="custom" || 
							                // vps_params?.customDomainEnabled &&  vps_params?.destination?.type ==="external"?
								            //     <div className="w-full xl:w-[390px] mt-2">
								            //         <LabelAdd
								            //             htmlFor="customDoamin"
								            //             placeholder={"Enter the custom domain"}
								            //         />
								            //   		<InputTextAdd
								            //             idx="customDoamin"
								            //             value={vps_params.customDoamin}
								            //             name="destinationCustomDomain"
								            //             onChange={handleChange}
								            //             required
								            //             disabled={creating}
								            //             placeholder='Identify the custom domain'
								            //         />
								            //     </div>
								            //     :
								            //     null
							                }
							                {/* {vps_params?.destination?.type ==="ha" ?
								                <div className="w-full xl:w-[390px] mt-2">
								                    <LabelAdd
								                        htmlFor="extPortApiPort"
								                        placeholder={"Enter the external port api port"}
								                    />
								              		<InputTextAdd
								                        idx="extPortApiPort"
								                        value={vps_params.extPortApiPort}
								                        name="extPortApiPort"
								                        onChange={handleChange}
								                        required
								                        disabled={creating}
								                        placeholder='Identify the external api port'
								                    />
								                </div>
								                :
								                null
							                } */}

						                </div>
					                </div>
									
								</div>
							</>
							:
							null
						}
						
						

						{/* <div className="mt-10">
							<p className='
                                    text-center 
                                    xl:text-xl 2xl:text-2xl 
                                    font-bold 
                                    text-primary 
                                    dark:text-darkTextColor
                                '
                            >   
                            	Visualization
                            </p>

                            <div className="mt-5 text-center">
	                            
							</div>
						</div> */}
						<div className="w-full py-3 ">
							<Divider />
						</div>
						{errorMessage ?
							<Alert
						      message="Error"
						      description={STANDARD_ERROR_MESSAGE}
						      type="error"
						      showIcon
						    />
						    :
						    null
						}
						<div className=" py-3 flex justify-center ">
							<Button 
								className="mx-auto w-72 font-semibold h-10 2xl:h-12 " 
								style={{borderRadius: "0px"}} 
								type="primary"
								htmlType="submit"
								loading={creating}
								// onClick={
								// 	()=>onCreate()
								// }
							>
								Create Load balancer
							</Button>
						</div>

					</div>
				</form>
			</div>
            {user && !user?.EmailConfirmed ?
                <UserAccountConfirmPrompt user={user} />
                :
                null
            }
        </div>
	)
}