import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Spiner } from "components/Bloc";
import { PageTitle } from 'components/Bloc';
import { LabelAdd } from 'components/Bloc';
import { InputTextAdd } from 'components/Bloc';
import { InputPassAdd } from 'components/Bloc';
import { DeployButton } from 'components/Bloc';
import { Texto } from 'layouts/TextoLayout';

import debian from '../../../assets/img/debian.png'
import ubuntu from '../../../assets/img/ubuntu.png'
import { RBuildVpsConfirm } from "../../../components/Modals";
import VpsImage from '../../../components/VpsImage'
import VPSapi from '../../../lib/vps_service';

const imgs = {
    ubuntu,
    debian
}


export default function RebuildMini({server, onRbuild, loadVps}) {
    const navigate = useNavigate()
    const { flavors } = useSelector(app => app.vps)
    const [selectedImage, setSelectedImage] = useState(null)
    const [showRebuildModal, setShowRebuildModal] = useState(false)
    const [clickedImage, setClickedImage] = useState(null)
    const { messageApi } = useSelector(app => app.core)
    const [creating, setCreating] = useState(false)
    const { t } = useTranslation()
    const  images  = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "Debian"];
        const Tab = []
        if(app.vps.images){
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i]; 
                const concerts = imgs.filter(img => (img.name.toLowerCase()).includes(os.toLowerCase()))
                Tab.push({name: os, list: concerts, id: i})
            }
            return Tab
        }
        return null;
    })


    const [vps_params, setVpsParams] = useState({
        clientos: '',
        clientusername: null,
        clientpassword: '',
        passwordCon: '',
        serviceType: "VPS_INSTANCE"
    })

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {
        !flavors && VPSapi.flavor_list() 
    }
    async function formSubmited(ev) {
        ev.preventDefault()
        
        try {
            if(!vps_params.clientusername){
                return messageApi.error(t("core.constants.INVALID_USERNAME"))
            }
            if(!vps_params.image){
                return messageApi.error(t("core.constants.INVALID_SERVICE_IMAGE"))
            }
             if(!vps_params.clientpassword ||  vps_params.clientpassword !== vps_params.passwordCon){
                return  messageApi.error(t("core.constants.PASSWORD_DONT_MATCH"))
            }
            
            return setShowRebuildModal(true)
        } catch (error) {
        }
    }

    async function onContinue(){
        try {
            setCreating(true)
            const data = { 
                ...vps_params, 
                Id: server?.Id,
                clientos: vps_params?.image && vps_params.image?.split('.')[0].split(' ').join('').toLowerCase()
            }
            const result = await onRbuild("rebuild", data)
            setCreating(false)
            if(result){
                loadVps()
                navigate('/compute/vpsmini')
            }
        } catch (error) {
            
        }
    }
    async function loadImages() {
        !images &&   VPSapi.image_list()
    }


     useEffect(() => {
        loadFlavors()
        loadImages()
        
        
    }, [])
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    return (
        <div className=' '>

            <RBuildVpsConfirm 
                open={showRebuildModal}  
                onContinue={onContinue} 
                setShowRebuildModal={setShowRebuildModal} 
            />
            {images ?
                <form onSubmit={formSubmited} className="">
                    
                    <PageTitle text={t("compute.vps.rebuildTab.h1")} />
                    <Texto small className="text-center font-semibold mt-7">{t("compute.vps.rebuildTab.pickTorebuild")}</Texto>
                    <div className="mx-auto justify-center gap-2 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                        {images && images.filter(image => image.name !== "KPS_cloud").map((image, k) =>
                            <VpsImage 
                                key={k}
                                image={image}
                                creating={creating}
                                clickedImage={clickedImage} 
                                setClickedImage={setClickedImage}
                                setVpsParams={setVpsParams}
                                vps_params={vps_params}
                                setSelectedImage={setSelectedImage}
                                selectedImage={selectedImage}
                                uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                            /> 
                        )} 
                    </div>
                    <Texto small className="text-center font-semibold mt-7">{t('serverInformations')}</Texto>
                    
                    <div className='w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10'>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                <LabelAdd
                                    htmlFor="clientusername"
                                    placeholder={t("deploy.instanceUser")}
                                />
                                <Tooltip open={hoverServerName}   title={t('indentifyInstanceHelpMessage')}>
                                    <InputTextAdd
                                        idx="clientusername"
                                        value={vps_params.clientusername}
                                        name="clientusername"
                                        pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                                        onChange={handleChange}
                                        required
                                        hover
                                        onHover={(state) => setHoverToolTopServerName(state)}
                                        disabled={creating}
                                        placeholder={t('indentifyInstancePlaceholder')}
                                    />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                <LabelAdd
                                    htmlFor="clientpassword"
                                    placeholder={t('serverPasswordLabel')}
                                />
                                <InputPassAdd
                                        
                                    idx="clientpassword"
                                    value={vps_params.password}
                                    name="clientpassword"
                                    // pattern={true}
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    placeholder={t('serverPasswordpalceHolder')}
                                />
                            </div>
                            <div className="w-full xl:w-1/2 px-3">
                                <LabelAdd
                                    htmlFor="serv-pass-confirm"
                                    placeholder={t('serverPasswordConfirmLabel')}
                                />
                                <InputTextAdd
                                    idx='serv-pass-confirm'
                                    type="password"
                                    value={vps_params.passwordCon}
                                    name="passwordCon"
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    placeholder={t('serverPasswordConfirmPlaceholder')}
                                />
                            </div>
                        </div> 
                    </div>
                    <div className="mx-auto">
                        <div className='text-center w-full'>
                            <DeployButton
                                creating={creating}
                                label={t('compute.vps.rebuildTab.go')}
                            />
                        </div>
                    </div>
                </form>
                :
                <div className='flex justify-center pt-48'>
                    <Spiner fontSize={40} />
                </div>
            }
        </div> 
    );
}
