import { InboxOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Tag, Upload } from 'antd'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import CAPTAIN from '../../../../lib/libCaprover';
import Utils from '../../../../utils';
import { useTranslation } from 'react-i18next';

const CodeEdit =  ({
    value,
    placeholder,
    rows,
    defaultValue,
    onChange,
}) => {
    return (
        <Input.TextArea
            defaultValue={defaultValue}
            spellCheck={false}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            placeholder={placeholder}
            style={{
                overflowX: 'auto',
                whiteSpace: 'pre',
                fontSize: 12,
            }}
            className="code-input"
            rows={rows || 4}
            value={value}
            onChange={(e) => onChange(e)}
        />
    )
}
export const UploaderPlainTextBase = ({appName, onUploadSucceeded, type}) =>{
    const {t} = useTranslation()
    const [userEnteredValue, setUserEnteredValue] = useState("")
    const [uploadInProcess, setUploadInProgress] = useState(false)

    const getPlaceHolderValue = () => {
        if(type === "dockerfile"){
            return `FROM apache/airflow:2.10.3-python3.9\n\n# Install the OpenSearch package\nRUN pip install apache-airflow[opensearch]\nRUN pip install opensearch-py\nRUN pip install apache-airflow\n\nCMD ['airflow', 'webserver']`
        }
        if(type === "appDefinition"){
            return `{
                "schemaVersion": 2,
                "imageName" : "mysql:5.7"
            }`     
        }
        if(type === "imageName"){
            return `apache/airflow:latest`
        }
    }
    const convertDataToCaptainDefinition = (val) => {
        if(type === "dockerfile"){
            return JSON.stringify({
                schemaVersion: 2,
                dockerfileLines: val.trim().split('\n'),
            })
        }
        if(type === "appDefinition"){
            return userEnteredValue.trim()
        }
        if(type === "imageName"){
            return JSON.stringify({
                schemaVersion: 2,
                imageName: val.trim(),
            })
        }
    }
    
    const isSingleLine = () => {
        if(type === "imageName"){
            return true
        }
        return false
    } 

    const startDeploy = (captainDefinitionToBeUploaded) => {
        Promise.resolve() //
            .then(function () {
                setUploadInProgress(true)
                return CAPTAIN.uploadCaptainDefinitionContent(
                    appName,
                    JSON.parse(captainDefinitionToBeUploaded),
                    '',
                    true
                )
                
            })
            .then(function () {
                setUserEnteredValue("")
                onUploadSucceeded()
                setUploadInProgress(false)
            })
            .catch()
            .then(function (e) {
                console.error('Error uploadingCaptainDefinition', e)
                setUploadInProgress(false)
            })
    }

    const createTextArea = () => {
        if (isSingleLine()) {
            return (
                <Input
                    spellCheck={false}
                    autoCorrect="off"
                    autoComplete="off"
                    autoCapitalize="off"
                    className="code-input"
                    placeholder={getPlaceHolderValue()}
                    value={userEnteredValue}
                    onChange={(e) => {
                        setUserEnteredValue(e.target.value)
                    }}
                />
            )
        }

        return (
            <CodeEdit
                placeholder={getPlaceHolderValue()}
                rows={9}
                value={userEnteredValue}
                onChange={(e) => {
                    setUserEnteredValue(e.target.value)
                }}
            />
        )
    }

    return (
        <div style={{ padding: 16 }}>
            <Row>{createTextArea()}</Row>
            <div style={{ height: 20 }} />
            <Row justify="end">
                <Button
                    disabled={
                        uploadInProcess ||
                        !userEnteredValue.trim()
                    }
                    // type="primary"
                    loading={uploadInProcess}
                    type={!(uploadInProcess ||!userEnteredValue.trim()) ? "primary" : "default"}
                    onClick={() =>
                        startDeploy(
                            convertDataToCaptainDefinition(
                                userEnteredValue
                            )
                        )
                    }
                >
                    {t('platform.configPage.deploymentTab.deployNow')}
                </Button>
            </Row>
        </div>
    )
    
}

export const TarUploader = ({appName, onUploadSucceeded}) => {
    const {t} = useTranslation()
    const [fileToBeUploaded, setFileToBeUploaded] = useState(false)
    const {messageApi} = useSelector(app => app.core )
    
    const beforeUpload = (file) => {
        return false
    }

    const handleChange = (info) => {
        if (info.fileList.length > 1) {
            messageApi.error(
                t('platform.configPage.deploymentTab.method3_2')
            )
            return
        }

        if (info.fileList.length === 0) {
            setFileToBeUploaded(undefined)
            messageApi.info(t('platform.configPage.deploymentTab.fileRemoved'))
            return
        }

        let file = info.fileList[0]

        if (file.name.indexOf('.tar') < 0) {
            messageApi.error(t('platform.configPage.deploymentTab.method3_3'))
            return
        }

        setFileToBeUploaded(file)
    }

    const startUploadAndDeploy = () => {
        const file = fileToBeUploaded
        setFileToBeUploaded(undefined)
        messageApi.info(t('platform.configPage.deploymentTab.uploadStarted'))

        const reader = new FileReader();
        reader.onload = async function(e) {
            const base64 = e.target.result; 
            const globalData = {
                path: `/user/apps/appData/${appName}?detached=1`,
                data: {
                    base64Data: base64,
                    fileName: "app.tar"
                },
                type: "formData",
            }
            const r = await CAPTAIN.globalPoster(globalData)
            if(r && r.status === 101){
                // onUploadSucceeded()
                messageApi.info(t('platform.configPage.deploymentTab.uploadFinished'))
            }else{
                setFileToBeUploaded(file)
                messageApi.error(r?.message || "An error occurred, please try again or contact us")
            }
        };
        reader.readAsDataURL(file.originFileObj);

    }
    return (
        <div>
            <Row justify="center">
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Upload.Dragger
                        name="files"
                        accept="*/*"
                        multiple={false}
                        fileList={
                            fileToBeUploaded
                                ? [fileToBeUploaded]
                                : undefined
                        }
                        listType="text"
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        action="//" // this is unused as beforeUpload always returns false
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            {t('platform.configPage.deploymentTab.method3_4')}
                        </p>
                        <p>{t('platform.configPage.deploymentTab.method3_5')}{' '}<code>25MB</code></p>
                        <p>{t('platform.configPage.deploymentTab.method3_6')}{' '}</p>
                        <Tag color='magenta' >
                        <code>Dockerfile</code>
                        </Tag>
                    </Upload.Dragger>
                </Col>
            </Row>

            <Row justify="center">
                <Button
                    style={{ marginTop: 40 }}
                    disabled={!fileToBeUploaded}
                    onClick={() => startUploadAndDeploy()}
                    type={fileToBeUploaded ? "primary" : "default"}
                    size="large"
                >
                    {t('platform.configPage.deploymentTab.uploadDeploy')}
                </Button>
            </Row>
        </div>
    )
    
}


const PasswordField =(
    {
        placeholder,
        addonBefore,
        defaultValue,
        onChange
    }
) => {
    const {t} = useTranslation()
    const [isShowing, setisShowing] = useState(!defaultValue)

    const createButton = () => {
        return (
            <Button
                style={{ width: '100%' }}
                onClick={() => {
                    setisShowing(true)
                }}
            >
                {t('platform.configPage.deploymentTab.showPass')}
            </Button>
        )
    }

    const createInput = () => {
        return (
            <Input
                type="text"
                spellCheck={false}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={placeholder}
                addonBefore={addonBefore}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e)}
            />
        )
    }
    return (
        <Fragment>
            {isShowing
                ? createInput()
                : createButton()}
        </Fragment>
    ) 
}
export const GitRepoForm = ({gitRepoValues, updateRepoInfo}) => {
    const {t} = useTranslation()
    return(
        <div>
            <form action="/" autoComplete="off">
                <Row gutter={20}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Input
                            style={{ marginBottom: 20 }}
                            value={gitRepoValues?.repo}
                            addonBefore={t('platform.configPage.deploymentTab.method4_repository')}
                            placeholder="github.com/someone/something"
                            type="url"
                            spellCheck={false}
                            autoCorrect="off"
                            autoComplete="off"
                            autoCapitalize="off"
                            onChange={(e) => {
                                const newObj = Utils.copyObject(
                                    gitRepoValues
                                )
                                newObj.repo = e.target.value
                                updateRepoInfo(newObj)
                            }}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Input
                            style={{ marginBottom: 20 }}
                            value={gitRepoValues?.branch}
                            addonBefore={
                                <span>{t('platform.configPage.deploymentTab.method4_branch')}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            placeholder="main"
                            type="text"
                            spellCheck={false}
                            autoCorrect="off"
                            autoComplete="off"
                            autoCapitalize="off"
                            onChange={(e) => {
                                const newObj = Utils.copyObject(
                                    gitRepoValues
                                )
                                newObj.branch = e.target.value
                                updateRepoInfo(newObj)
                            }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                        className={
                            gitRepoValues?.sshKey
                                ? 'hidden'
                                : ''
                        }
                    >
                        <Input
                            style={{ marginBottom: 20 }}
                            value={gitRepoValues?.user}
                            addonBefore={<span>{t('platform.configPage.deploymentTab.method4_username')}&nbsp;</span>}
                            placeholder="user@example.com"
                            type="email"
                            onChange={(e) => {
                                const newObj = Utils.copyObject(
                                    gitRepoValues
                                )
                                newObj.user = e.target.value
                                updateRepoInfo(newObj)
                            }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                        className={
                            gitRepoValues.sshKey
                                ? 'hidden'
                                : ''
                        }
                    >
                        <PasswordField
                            defaultValue={gitRepoValues.password}
                            addonBefore={t('platform.configPage.deploymentTab.method4_password')}
                            placeholder="github_password"
                            onChange={(e) => {
                                const newObj = Utils.copyObject(
                                    gitRepoValues
                                )
                                newObj.password = e.target.value
                                updateRepoInfo(newObj)
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <span>
                            {t('platform.configPage.deploymentTab.method4_3')}
                        </span>
                        <Input.TextArea
                            style={{ marginBottom: 20 }}
                            rows={9}
                            value={gitRepoValues?.sshKey}
                            placeholder={
                                '-----BEGIN RSA PRIVATE KEY-----\nMIIBOgIBAAJBAKj34GkxFhD90vcNLYLInFEX6Ppy1tPf9Cnzj4p4WGeKLs1Pt8Qu\nKUpRKfFLfRYC9AIKjbJTWit+CqvjWYzvQwECAwEAAQJAIJLixBy2qpFoS4DSmoEm\no3qGy0t6z09AIJtH+5OeRV1be+N4cDYJKffGzDa88vQENZiRm0GRq6a+HPGQMd2k\nTQIhAKMSvzIBnni7ot/OSie2TmJLY4SwTQAevXysE2RbFDYdAiEBCUEaRQnMnbp7\n9mxDXDf6AU0cN/RPBjb9qSHDcWZHGzUCIG2Es59z8ugGrDY+pxLQnwfotadxd+Uy\nv/Ow5T0q5gIJAiEAyS4RaI9YG8EWx/2w0T67ZUVAw8eOMB6BIUg0Xcu+3okCIBOs\n/5OiPgoTdSy7bcF9IGpSE8ZgGKzgYQVZeN97YE00\n-----END RSA PRIVATE KEY-----'
                            }
                            onChange={(e) => {
                                const newObj = Utils.copyObject(
                                    gitRepoValues
                                )
                                newObj.sshKey = e.target.value
                                if (newObj.sshKey) {
                                    // Upon changing SSH key, we forcefully remove user/pass to inform the user that SSH will take priority
                                    newObj.password = ''
                                    newObj.user = ''
                                }
                                updateRepoInfo(newObj)
                            }}
                        />
                    </Col>
                </Row>
            </form>
        </div>
    )
}
