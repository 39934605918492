export default{
    vpc: {
      h1: "Réseaux VPC",
      createBtn: "Créer un réseau VPC",
      description: "Les ressources attribuées au même réseau VPC peuvent communiquer de manière sécurisée entre elles via une IP privée. Pour communiquer avec les ressources en dehors du VPC, vous devez utiliser une IP public.",
      howToCreateVpc: "Comment utiliser un réseau VPC",
      notHavingVpc: "Il semble que vous n'ayez aucun réseau",
      createFirstBtn: "Créer un premier réseau VPC",
      ressourceInOption: "Ressources",
      deleteInOption: "Supprimer",
      tagDefault: "Par défaut",
      noRessource: "Aucune ressource",
      ressources: "Ressources",
      manage: "Gérer",
      vpcLinkAlt: "Lien court VPC",
      overview: {
        name: "Vue d'ensemble",
        title: "Vue d'ensemble",
        description: "DESCRIPTION",
        defaultDescription: "Par défaut",
        descriptionText: "Ceci est défini comme VPC par défaut pour la région {{locationName}}",
        netDetails: "DÉTAILS DU RÉSEAU",
        ipRange: "Plage d'IP",
        availAddr: "Adresse disponible",
        ress: "Ressources",
        vpcNetRess: "AIDE",
        docs: "Docs",
        apiDoc: "API Docs",
        tuto: "Tutoriel",
        _____: "______",
        _____: "______"
      },
      ressources: {
        name: "Ressources",
        title: "Ressources",
        addServiceBtn: "Ajouter une ressource",
        noRessource: "Vous n'avez aucune ressources dans ce réseau",
        allCommunication: "Toute communication entre ces ressources via des IPs privée sont sécurisée.",
        traffic: "Le trafic vers les IPs publiques doit être sécurisé avec un",
        firewall: "Pare-feu",
        services: "SERVICES",
        addServiceModal: {
          title: "Ajouter un nouveau service dans la zone",
          subtitle: "Sélectionnez une nouvelle ressource à ajouter.",
          selectlabel: "Sélectionnez la ressource",
          placeholder: "Sélectionnez une ressource",
          addServicBtn: "Ajouter une ressource",
          cancel: "Annuler",
          _____: "______"
        },
        collumName: "Nom",
        publicIp: "IP public",
        notDefined: "(Non défini)",
        privateIp: "IP privée",
        deleteServiceInZoneTooltip: "Supprimer {{displayName}} dans cette zone",
        _____: "______",
        _____: "______"
      },
      settings: {
        name: "Paramètres",
      },
      delete: {
        name: "Supprimer",
      },
      _____: "______",
      _____: "______",
      _____: "______",
      _____: "______",
      _____: "______"
    }
  }