export default{
    onClickApps: "",
    app: {
        titlePage: "Manage",
        customAppBtn: "Custom App",
        newOneClickAppBtn: "One-Click Apps",
        customAppModal: {
            title: "New custom app",
            hasPersistantData: "Has persistent data",
            cancel: "Cancel",
            create: "Create",
            myAmazingApp: "my-amazing-app",
        },
        goToCofigPageBtn: "Config",
    },
    listOneClicApp: {
        listAppPageTitle: "One-Click Apps",
        selectNewAPP: "Select your one click install app",
        searchPlaceHolder: "Search",
        ________: "_______",

    },
    appDetails: {
        deploy: "deploy",
        deployBtn: "Deploy",
        labelMessageAppNameField: "App Name",
        helpMessageAppNameField: "This is your app name. Pick a name such as my-first-1-click-app",
        setupAppText: "Setup your {{displayName}}",
        errorRegexvalidation: "Invalid value. Does not match Regex: {{validRegex}}"
    },
    appDeploying:{
        h1: "Deploying {{ appName }} {{displayName}} ",
        preventMessage: "This process takes a few minutes to  complete. DO NOT refresh this page and DO NOT navigate away!",
        goBackButton: "Go Back & Try Again",
        deploySuccessMessage: "Deployment success!",
        deploySuccessTitle: "Success",
        goListAppButton: "Go to list app page ",
        deplyErrorTitle: "Error",
        parsingTemplate: "Parsing the template",
        parsingTemplateErrorMesssage: "Cannot parse the template. Dependency tree cannot be resolved. Infinite loop!",
        parsingTemplateErrorMesssage2: "Cannot parse the template. No services found!",
        registering: "Registering {{appName}} ",
        configuring: "Configuring {{appName}} (volumes, ports, environmental variables",
        deploying: "Deploying {{appName}} (might take up to a minute)",
        ________: "_______",
        
    },
    configPage: {
        titlePage: "Config",
        appNotFound: "App not found!",
        saveRestartBtn: "Save & Restart",
        deleteAppBtn: "Delete App",
        httpSettingsTab: {
            title: "HTTP Settings",
            httpsEnabledSuccessMessage: "HTTPS is now enabled for your app",
            removeCustomDomainSuccesssMessge: "Your custom domain is successfully removed!",
            httpsActivatedSuccessMssg: "HTTPS is successfully activated for your domain!",
            editnginxConfigBtn: "Edit Default Nginx Configurations",
            newDomainConnectedSUccessMessage: "New domain is now successfully connected!",
            alreadyACtivated: "Already activated",
            clickToActive: "Click to activate HTTPS for this domain",
            htppsEnabled: "HTTPS enabled",
            enabledHtpps: "Enable HTTPS",
            removeDomainBtn: "Remove",
            doNotExposeApp: "Do not expose as web app externally",
            dontExposeHelpMessage: "Use this if you don't want your app to be externally available.",
            appPublicAvailableUrl: "Your app is publicly available at",
            newDomainHelpMessage: "Make sure the new domain points to the KPS IP, otherwise verification will fail.",
            connectNewDomain: "Connect New Domain",
            containerPortHelpMessage: "HTTP port inside the container. Default is 80. Change only if the app is running in a different port. This is used only for HTTP apps, not databases.",
            containerPortBefore: "Container HTTP Port",
            forceRedirectHtpps: "Force HTTPS by redirecting all HTTP traffic to HTTPS",
            forceRedirectHttpsTooltip: "Forcing HTTPS causes domains without HTTPS to malfunction. Make sure you enable HTTPS for the domain you want to use, before enabling Force HTTPS option.",
            websockedtSupport: "Websocket Support",
            websockedtSupportTooltip: "Adds the upgrade proxy headers to NGINX config",
            editBasicAuth: "Edit HTTP Basic Auth",
            currentState: "Current State",
            inactive: "inactive",
            active: "active",
            sslActivtedMessage: "SSL Certificate is already activated",
            dangerousHtml1: "Templates are built using EJS template pattern. Do not change the areas between <code>&lt;%</code> and&nbsp; <code>%&gt;</code> , unless you really know what you're doing! To revert to default, simply remove all the content.",
            dangerousHtml2: "Your app is internally available as <code class='text-red-500 bg-red-50'>srv-captain--{{appName}}</code> to other KPS apps. In case of a web app, it is accessible via <code class='text-red-500 bg-red-50'>{`http://srv-captain--{{appName}}`}</code> from other apps.",
            basicAuthModal: {
                title: "Edit HTTP Basic Auth",
                p1: "HTTP Basic authentication is the simplest way to enforce access controls to web resources.",
                p2: "You can use it to restrict access to HTTP apps, especially those you create via the One-Click app deployments such as phpMyAdmin, etc.",
                password: "password",
                username: "username",
                confirm: "Confirm",
                cancel: "Cancel"

            },
            ________: "_______",

        },
        appConfigsTab: {
            title: "App Configs",
            portNotUsedTooltip: "Make sure the port is not already used!",
            servPort: "Server Port",
            containerPort: "Container Port",
            noTagAssoc: "Currently, this app does not have any service tags associated.",
            pathInApp: "Path in App",
            label: "Label",
            pathInhostTooltip: "IMPORTANT: Ensure Host Path exists before assigning it here",
            pathInHost: "Path on Host",
            letKeepSecManage: "Let KeepSec manage path",
            setSpecific: "Set specific host path",
            addPersistantDirectory: "Add Persistent Directory",
            leaveEmpty: "Leave empty for automatic placement",
            nodeId: "Node ID",
            changeNodeIdTooltip: "WARNING: Changing Node ID causes the content of your persistent directories to be deleted!",
            editNodeIdButton: "Edit",
            addKeypair: "Add Key/Value Pair",
            envVars: "Environmental Variables",
            notHavingEnvVars: "Currently, this app does not have any custom environmental variables.",
            buildEdit: "Bulk Edit",
            portMap: "Port Mapping",
            notHavingportmapping: "Currently, this app does not have any custom port mapping.",
            addPortmappingBtn: "Add Port Mapping",
            persistantDirectory: "Persistent Directories",
            notHavingPersiDirrectory: "Currently, this app does not have any persistent directories.",
            nberRunningRunn: "Number of running instances of this app",
            instanceCount: "Instance Count",
            multipleInstancePreventTooltip: "Multiple instances of apps with persistent data can be very dangerous and bug prone as they can be accessing the same file on the disk resulting in data corruption. Edit the instance count only if you understand the risk.",
            multipleInstancePreventEditBtn: "Edit",
            prevScript: "Pre-Deploy Script",
            servupOveride: "Service Update Override",
            nothavingPersisDirectory: "Currently, this app does not have any persistent directories.",
            servTags: "Service Tags"
        },
        deploymentTab: {
            title: "Deployment",
            viewAppLogs: "View App Logs",
            hideAppLogs: "Hide App Logs",
            viewBuildLogs: "View Build Logs",
            hideBuildLogs: "Hide Build Logs",
            deployNow: "Deploy Now",
            copy: "Copied to clipboard",
            fileRemoved: "File removed",
            uploadStarted: "Upload has started",
            uploadFinished: "Upload has finished successfully, please check build logs to see progress",
            uploadDeploy: "Upload & Deploy",
            method1: "Method 1: Deploy from image name",
            method2: "Method 2: Deploy from Dockerfile",
            method3: "Method 3: Deploy from TAR file",
            method3_1: "You can simply create a %s file of your project and upload it here.",
            method3_2: "You can only upload one TAR file! Remove the currently selected file first.",
            method3_3: "You can only upload a TAR file!",
            method3_4: "Click or drag TAR file to this area to upload",
            method3_5: "Max size:",
            method3_6: "Must contain this file:",
            method4: "Method 4: Deploy from GitHub / Bitbucket / GitLab",
            method4_1: "Enter your repository informations in the form and save. Then copy the URL in the box below as a webhook on either GitHub, Bitbucket, GitLab, etc. Once you push a commit, the KPS will start a new build.",
            method4_2: "Add repo infos and save for this webhook to appear",
            method4_3: "Or instead of a username and password, use a SSH Key:",
            method4_repository: "Repository",
            method4_branch: "Branch",
            method4_username: "Username",
            method4_password: "Password",
            forceBuild: "Force Build",
            buildStarted: "Build has started",
            saveRestart: "Save & Restart",
            showPass: "Show Password"
        },
    },
    deleteModalApp: {
        appNameDontMatch: "App name did not match. Operation cancelled.",
        appDeleted: "App deleted",
        title: "Delete this app?",
        warning: "Warning",
        aboutToDelete: "You are about to delete",
        notreversible: "Please note that this is not reversible.",
        p1: "Please select the volumes that you want to delete. If any of the volumes are being used by other KPS apps, they will not be deleted.",
        p2: "Note: Deleting volumes takes more than 10 seconds, please be patient.",
        cinfigAppName: "Confirm app name",
        appNotGoodDeleted: "App deleted but some volumes weren't deleted because they were probably being used by other containers.",
        confirmDelete: "Yes, Delete",
        cancel: "Cancel",
        undestand: "I understand",

    },
    ________: "_______",
}



