import { Affix, Button, Col, Modal, Row } from 'antd'
import { useEffect, useState } from 'react';
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { updateHeadLink } from 'reducer/action/coreActions';

import { Toast } from '../../../components/Notify';
import { TabSelector } from "../../../components/TabSelector";
import CAPTAIN from '../../../lib/libCaprover';
import Utils from '../../../utils';
import AppConfig from './AppConfig'
import Deployment from './deploy/Deployment'
import Http from './Http'

export const ONE_CLICK_APP_NAME_VAR_NAME = '$$cap_appname'
export const ONE_CLICK_ROOT_DOMAIN_VAR_NAME = '$$cap_root_domain'

export default function CaptainAppConfig(){
    const {t} = useTranslation()
	const [loading, setLoading] = useState(false)
    const {messageApi} = useSelector(app => app.core)
	const [apiData, setApp] = useState(null)
	const [openDeleteAppModal, setOpenDeleteAppModal] = useState(false)
    const [app, setAppData] = useState(null)
	const { appName } = useParams()
    const { server_id } = useParams()
    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = useTabs([
        "http",
        "config",
        "Deployment",
        "Delete"
     ]);




    const headLinks=[
        {
            link: "/app-platform/kps",
            item: "kps",
            alt: "kps short link"
        },
        {label: t("platform.app.titlePage"), link: "/app-platform/kps/"+server_id, arrow: true},
        {
            arrow: true,
            label: appName
        },
        {
            arrow: true,
            label: t("platform.configPage.titlePage")
        } 
    ] 

	useEffect(() => {
        dispatch(updateHeadLink(headLinks))
        reFetchData().then(app => {

        })

        return () => {
            dispatch(updateHeadLink([]))
        } 
    }, [])

    
 
    
 
	function reFetchData() {

        return CAPTAIN.getAllApps()
            .then(function (data) {
                console.log('data data data captainSubdomain ', data)
                for (
                    let index = 0;
                    index < data?.data.appDefinitions.length;
                    index++
                ) {
                	
                    const element = data.data.appDefinitions[index]
                    if (element.appName === appName) {
                        setApp({
                            appDefinition: element,
                            rootDomain: data.data.rootDomain,
                            captainSubDomain: data.data.captainSubDomain,
                            defaultNginxConfig: data.data.defaultNginxConfig,
                        })
                        setAppData(element)
                        return element
                    }
                }

                // App Not Found!
                //self.goBackToApps()
            })
            .catch(function(e){
            	console.log('error --', e)
            	messageApi.error(t('platform.configPage.appNotFound'))
            })
            .then(function () {
                setLoading(false)
            })
    }

    



    const rootDomain = apiData?.rootDomain
    const basicAuthUsername = apiData?.appDefinition.httpAuth
            ? apiData?.appDefinition.httpAuth.user
            : ''

    function onDelete(){
        return setOpenDeleteAppModal(true)
    }

    async function onUpdateConfigAndSave(newApiData) {
        const appDef = newApiData ? Utils.copyObject(newApiData?.appDefinition) : Utils.copyObject(apiData?.appDefinition)
        const globalOb = {
            path: "/user/apps/appDefinitions/update",
            data: {
                appName, 
                ...appDef
            }
        }
        setLoading(true)
        const result = await CAPTAIN.globalPoster(globalOb)
        if(result && result?.status !== 100){
            messageApi.error(result.description || "An error occurred while saving the configuration")
        }
        setLoading(false)
        reFetchData()
        return result
    }

	return (
		<div className={" dark:bg-black dark:text-darkTextColor "}> 
            {app && <DeleteApp server_id={server_id} appDef={app} open={openDeleteAppModal} setOpen={setOpenDeleteAppModal} />}
            
            <div className="px-3 md:px-10 h-auto  ">
                <div className="rounded-lg  shadow-lg  w-full p-8 dark:bg-bgPagedark dark:border-2 dark:border-gray-800">
                    <div className="flex items-center text-2xl">
	                    <AiFillEdit className="cursor-pointer text-primary dark:text-darkTextColor" />
	                    <span> {app?.appName} </span>
                    </div>



                    <div className="pt-6">
                    	 <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "http"}
                              onClick={() => setSelectedTab("http")}
                            >
                            {t('platform.configPage.httpSettingsTab.title')}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "config"}
                              onClick={() => setSelectedTab("config")}
                            >
                            {t('platform.configPage.appConfigsTab.title')}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Deployment"}
                              onClick={() => setSelectedTab("Deployment")}
                            >
                            {t('platform.configPage.deploymentTab.title')}
                            </TabSelector> 
                           
                          </nav>

                          <div className="py-6">
                              <TabPanel hidden={selectedTab !== "http"}>
                                 <Http 
                                    apiData={apiData} 
                                    rootDomain={rootDomain}
                                    onUpdateConfigAndSave={onUpdateConfigAndSave}
                                    // setLoading={(value) =>
                                 //        //this.setState({ isLoading: value })
                                 //    }
                                    reFetchData={() => reFetchData()}

                                    updateApiData={(newData) => setApp({...newData}) }
                                    // onUpdateConfigAndSave={() =>
                                    //     //self.onUpdateConfigAndSave()
                                    // }
                                />
                              </TabPanel>
                               

                               <TabPanel hidden={selectedTab !== "config"}>
                                   <AppConfig 
                                    apiData={apiData} 
                                    rootDomain={rootDomain}
                                    // setLoading={(value) =>
                                 //        //this.setState({ isLoading: value })
                                 //    }
                                    reFetchData={() => reFetchData()}
                                    onUpdateConfigAndSave={onUpdateConfigAndSave}
                                    updateApiData={(newData) => setApp({...newData})}
                                    // onUpdateConfigAndSave={() =>
                                    //     //self.onUpdateConfigAndSave()
                                    // }
                                />
                              </TabPanel>
                               

                               <TabPanel hidden={selectedTab !== "Deployment"}>
                                     <Deployment  
                                        apiData={apiData} 
                                        rootDomain={rootDomain}
                                        loading={loading}
                                        // setLoading={(value) =>
                                     //        //this.setState({ isLoading: value })
                                     //    }
                                        reFetchData={() => reFetchData()}
                                        onUpdateConfigAndSave={onUpdateConfigAndSave}
                                        updateApiData={(newData) => setApp({...newData})}
                                        // onUpdateConfigAndSave={() =>
                                        //     //self.onUpdateConfigAndSave()
                                        // }
                                    />
                              </TabPanel>
                               



                        </div>
                            {selectedTab !== "Deployment" ?
                                <Affix
                                    offsetBottom={10}
                                    target={() => {
                                        return window
                                        // const newLocal = self.props.mainContainer
                                        // return newLocal && newLocal.current? newLocal.current
                                            // : window
                                    }}
                                >
                                    <div
                                        // className={}
                                        style={{
                                            borderRadius: 8,
                                            background: 'rgba(242, 242, 242, 0.9)',
                                            paddingTop: 20,
                                            paddingBottom: 20,
                                        }}
                                    >
                                        <Row justify="center" gutter={20}>
                                            <Col span={8}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button
                                                        className="min-w-[35px] bg-white lg:min-w-[135px] bg-red-500"
                                                        danger
                                                        style={{color: "white"}}                                
                                                        size="large"
                                                        onClick={() =>{
                                                            onDelete()
                                                        }
                                                        }
                                                    >
                                                        {t('platform.configPage.deleteAppBtn')}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button
                                                        className="min-w-[35px] lg:min-w-[135px] "
                                                        type="primary"
                                                        size="large"
                                                        loading={loading}
                                                        onClick={() =>onUpdateConfigAndSave()}
                                                        
                                                    >
                                                        {t('platform.configPage.saveRestartBtn')}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Affix>
                                :
                                null
                            }



                          

                    	
                    </div>
                </div>
            </div>
        </div>
	)
}






function DeleteApp(props){
    console.log('props props', props)
    const [volumesToDelete, setVolumeV] = useState({})
    const [confirmedAppNameToDelete, setAppName] = useState('')
    const [loanding, setloading] = useState(false)
    const [allVolumes, setAllVolume] = useState([]) 
    const {t} = useTranslation()
    const { messageApi } = useSelector(app => app.core)
    const [failled, setFailled] = useState(false) 
    const [volumesFailedToDeleteG, setVolumeFailled] = useState(null) 

    const navigate = useNavigate()

    
    function run(){
        const appDef = Utils.copyObject(props?.appDef)


        const allVolumes = []

 
        if (appDef.volumes) {
            appDef.volumes.forEach((v) => {
                if (v.volumeName) {
                    allVolumes.push(v.volumeName)
                    setAllVolume(allVolumes)
                    volumesToDelete[v.volumeName] = true
                    setVolumeV(volumesToDelete)
                }
            })
        }
    }

    useEffect(()=>{
        run()
    }, [])
    
   

    function onCancel(operate){
        props.setOpen(false)
        if(operate || failled){
            navigate('/app-platform/kps/'+props.server_id)
        }
 
    } 
    
    async function onDeleteMethod(){
        if (confirmedAppNameToDelete !== props?.appDef?.appName) {
            onCancel()
            return messageApi.error(t('platform.deleteModalApp.appNameDontMatch'))
        }

        setloading(true)
        const volumes2 = []
        Object.keys(volumesToDelete).forEach((v) => {
            if (volumesToDelete[v]) {
                volumes2.push(v)
            }
        })

        const resul = await CAPTAIN.deleteApp(props?.appDef?.appName, volumes2)

        console.log('result ondel', resul)
        setloading(false)
        const volumesFailedToDelete = resul?.data?.volumesFailedToDelete 
        if (
            volumesFailedToDelete &&
            volumesFailedToDelete.length
        ){
            setFailled(true)
            setVolumeFailled(volumesFailedToDelete)

        }else{
            onCancel(true)
            return messageApi.success(t('platform.deleteModalApp.appDeleted'))

        }

        

    }
    
    return( 
        <Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={900}
	        open={props.open}
	    >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            {t('platform.deleteModalApp.title')}
                        </h4>
                        {/* <p className='text-sm xl:text-base'>
                            Pick new size to increase. 
                        </p> */}
                    </div>
                     
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6  py-6">						
                        <span className="text-orange-600 font-semibold">{t('platform.deleteModalApp.warning')}: </span> 
                            {t('platform.deleteModalApp.aboutToDelete')} <span className="text-red-600 font-semibold">{props?.appDef?.appName}</span>.
                        <br/>
                        {t('platform.deleteModalApp.notreversible')}
                        {allVolumes && allVolumes.length ?
                            <>
                                <br/><br/>
                                {t('platform.deleteModalApp.p1')}
                                <br/><br/>
                                {t('platform.deleteModalApp.p2')}
                                <br/><br/>
                            </>
                            : ""
                        }

                        {!failled && !volumesFailedToDeleteG &&
                            <div>
                                {allVolumes.map((v) => {
                                    return (
                                        <div key={v}>
                                            <div className="flex gap-2 pt-2">
                                                <input 
                                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white dark:bg-black checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                                    type="checkbox" 
                                                    value="" 
                                                    id={v}
                                                    defaultChecked={!!volumesToDelete[v]}
                                                    onChange={(e) => {
                                                        volumesToDelete[v] = !volumesToDelete[v]
                                                        setVolumeV(volumesToDelete)
                                                    }}
                                                />
                                                <label className="form-check-label text-red-600 inline-block" htmlFor={v}>
                                                    {v}
                                            </label>
                                            </div>
                                        </div>
                                    )
                                })}
                                <br/>
                                <p>{t('platform.deleteModalApp.cinfigAppName')}:</p>
                                <div className="flex border-2 dark:border-gray-800 rounded">
                                    <input 
                                    className="px-4 h-8 w-full focus:border-sky-500 focus:ring-1 focus:ring-sky-500 dark:bg-bgPagedark dark:text-darkTextColor" 
                                        type="text" 
                                        placeholder={props?.appDef?.appName}
                                        onChange={(e) => {
                                            setAppName(e.target.value.trim())
                                        }}
                                        value={confirmedAppNameToDelete}
                                    />
                                </div>
                            </div>
                        }
                        {failled && volumesFailedToDeleteG &&
                            <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                                <p className="font-bold text-base">
                                    {t('platform.deleteModalApp.appNotGoodDeleted')}
                                    <ul>
                                        {volumesFailedToDeleteG.map((v) => (
                                            <li>
                                                <code>{v}</code>
                                            </li>
                                        ))}
                                    </ul>
                                </p>
                            </div>
                        }
					</div>
                </div>

                <div className="py-5 flex gap-2 justify-end  px-6">
                    <Button size="large" onClick={() => {
                            onCancel()
                        }}
                    >
                        {!(failled && volumesFailedToDeleteG ) ? t('platform.deleteModalApp.cancel') : t('platform.deleteModalApp.undestand')} 
                    </Button>
                    {!failled && !volumesFailedToDeleteG &&
                    <Button 
                        loading={loanding} 
                        className="bg-red-500" 
                        danger
                        style={{color: "white"}}
                        size="large" 
                        onClick={() => {
                            onDeleteMethod()
                        }}
                    >
                        {t('platform.deleteModalApp.confirmDelete')}
                    </Button>
                    }
            	</div>
            </div>
        </Modal>
    )
}