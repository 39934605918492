import React, { useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { updateHeadLink } from "reducer/action/coreActions";

import { DeleteServiceTap, Spiner } from '../../../components/Bloc'
import { TabSelector } from "../../../components/TabSelector";
import VPC from '../../../lib/vpc';
import Utils from '../../../utils'
import { Overview, Ressources } from './VpcManageSection'

const img = require('../../../assets/img/networking/left.png')

const tabs = [
      "Overview",
      "Ressources",
      "Delete",
    ] 
export default function VpcManage(){
	const { id } = useParams();
	const {t} = useTranslation()
	const navigate = useNavigate()
	const [selectedTab, setSelectedTab] = useTabs(tabs);
	const [vpc, setVpc] = useState(null);
	const dispatch = useDispatch()
	
	async function loadNetwork() {
	    const data = await VPC.getUniquenetwork(id);
	    const headLinks = [
           {
              link: "/networking/vpc",
              item: 'vpc',
              alt: t("network.vpc.vpcLinkAlt")
           },
           {label: data?.vpc?.network?.name, arrow: true},
        ]
        dispatch(updateHeadLink(headLinks))
        setVpc(data?.vpc)
        VPC.getAll()
	    return data?.vpc
    }


    // const onCreatePort = async (vps) => {
	// 	setObjectPortCreate({...objectPortCreate, [vps.Id]: true})
	// 	try {
	// 		const result  = await UserMgt.onPortCreate({server_id: vps?.Id, isDefault: true})
	// 		console.log('Result Port ', result)
	// 		if(result && !result.error){
	// 			messageApi.open({
	// 				type: 'success',
	// 				content: 'Done !'
	// 			});
	// 			await getListVps()
	// 			await loadVps()
	// 		}else{
	// 			messageApi.open({
	// 				type: 'error',
	// 				content: 'An error occurs, please try again or contact an admin!'
	// 			});
	// 		}
	// 	} catch (error) {
	// 		console.error('Error createPort', error)
	// 	}
	// 	setObjectPortCreate({...objectPortCreate, [vps.Id]: null})
	// }
	

 
	
   useEffect(()=>{
        loadNetwork().then(()=>{
	        if (Utils.getUrlParam('act') == "nodes") {
				setSelectedTab('Ressources')
			}
        })
		return () => dispatch(updateHeadLink([]))
    }, [])

	async function onDelete() {
		Utils.dispatchToDelete(vpc, '/networking/vpc')
	}

	return( 
		<>
			<div className=" dark:text-darkTextColor pb-10">
            	<div className="container mx-auto max-w-full px-3  md:px-8">
            		<div style={{display: !vpc && "none"}}>
	            		<div className="md:flex gap-6 items-center">
	                        <div className="md:w-1/12 md:flex hidden">
	                           <img
	                              src={img}
	                              alt=""
	                              className="w-14 h-14 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20"
	                           />
	                        </div>
	                        <div className="md:w-11/12 w-full ">
	                           <div>
	                              
	                              <div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
	                                 <div>
		                                 <img
		                                    src={img}
		                                    alt=""
		                                    className="w-14 h-14 md:hidden"
		                                 />
	                                    <span className="2xl:text-3xl text-xl xl:text-2xl">
	                                       	{vpc?.network?.name}
	                                    </span>{" "}
	                                    <br />
	                                    <span className="text-sm 2xl:text-base">
	                                    	{vpc?.ressources?.length}
										</span>{" "}
	                                    <br />
	                                    <span className="text-sm 2xl:text-base">
	                                       	{vpc?.location?.name}
	                                    </span>{" "}
	                                    <br />
	                                 </div>

	                                 <div className="">
	                                    
	                                 </div>

	                              </div>
	                           </div>




	                        </div>
	                    </div>

	                    <div className="mt-5">
	                    	<nav className="flex  border-b border-gray-300 dark:border-gray-800 overflow-x-auto">
	                           {tabs.map(tab =>(
		                            <TabSelector 
		                              isActive={selectedTab === tab}
		                              onClick={() => setSelectedTab(tab)}
		                            >
		                            	{t("network.vpc."+tab.toLocaleLowerCase()+".name")}
		                            </TabSelector>
		                        ))}
	                        </nav>

	                        <div className="py-5">
	                        	<TabPanel hidden={selectedTab !== "Overview"}>
                           			<Overview 
                           				t={t} 
                           				vpc={vpc} 
                           			/>
	                        	</TabPanel>
	                        	<TabPanel hidden={selectedTab !== "Ressources"}>
                           			<Ressources 
                           				t={t} 
                           				vpc={vpc} 
                           				loadNetwork={loadNetwork} 
                           			/>
	                        	</TabPanel>
								<TabPanel hidden={selectedTab !== "Delete"}>
									<div className="">
										<DeleteServiceTap 
											server={vpc} 
											onDelete={onDelete}
											canotDelete={vpc?.isDefault}
										/>
									</div>
								</TabPanel>
                           		{/*<TabPanel hidden={selectedTab !== "Overview"}>
                           			<Overview networks={networks} createFile={()=>Utils.createK8SFile(k8s)} messageApi={messageApi} masterCluster={masterCluster} k8s={k8s} master={server} instances={listNode} />
	                        	</TabPanel>
	                        	<TabPanel hidden={selectedTab !== "Storage"}>
                           			<StorageK8s  
                           				messageApi={messageApi} 
                           				masterCluster={masterCluster} 
                           				k8s={k8s} 
                           				volumes={volumes} 
                           				master={server} 
                           				instances={listNode}
                           				reloadNetworks={loadK8} 
                           			/>
	                        	</TabPanel>
	                        	<TabPanel hidden={selectedTab !== "Networking"}>
                           			<Networking listNode={listNode} messageApi={messageApi} masterCluster={masterCluster} k8s={k8s} master={server} instances={listNode} />
	                        	</TabPanel>
	                        	<TabPanel hidden={selectedTab !== "Ressources"}>
                           			<Ressource 
                           				listNode={listNode} 
                           				messageApi={messageApi} 
                           				masterCluster={masterCluster} 
                           				k8s={k8s} 
                           				master={server} 
                           				instances={listNode || []}
                           				volumes={volumes} 
                           				reloadK8s={loadK8}  
                           			/>
	                        	</TabPanel>
	                        	<TabPanel hidden={selectedTab !== "Delete"}>
	                              <div className="">
	                                 <div className="flex gap-2 items-center">
	                                    <p className="font-bold text-primary text-xl 2xl:text-2xl">
	                                       Delete this instance
	                                    </p>
	                                 </div>
	                                 <p className="2xl:text-base text-sm pt-2">
	                                    Deleting this instance will permanently destroy it including all of its data.
	                                 </p>
	                                 <p className="2xl:text-base text-sm font-bold pt-2">
	                                    Are you sure you want to delete this instance?
	                                    <br />
	                                    <br />
	                                    <OkButton
	                                       danger
	                                       loanding={false}
	                                       click
	                                       server={server}
	                                       fun={()=>onDelete()}
	                                       title={"Delete"}
	                                    />
	                                 </p>
	                              </div>
	                            </TabPanel>*/}
	                        </div>
	                    </div>
	                </div>

	                <div style={{display: vpc && "none"}} className='flex h-[400px] items-center justify-center'>
	                	<Spiner fontSize={50} />
	                </div>
            	</div>
            </div>
        </>


	)
}