import { Button, Card } from 'antd';
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ExtLink } from 'components/Bloc';
import { Texto } from "layouts/TextoLayout";

import volume from "../../../assets/img/storage/volume.png"
import { Spiner } from '../../../components/Bloc'
import { ModalIncreaseSize, TableFunction } from '../../../components/volume/VolumeSection'
import STORAGE from '../../../lib/storage';

export default function ListVolumeStorage(){
	const navigate = useNavigate()
	const [loadingData, setLoading] = useState(false)
	const {list} = useSelector(state => state.volume)
	const [editedVolume, setEditVolume] = useState(null)
	const {t} = useTranslation()
	async function listStorageVolume(){
		setLoading(true)
        await STORAGE.list()
		setLoading(false)
    }


	useEffect(()=>{ 
        !list && listStorageVolume() 
    }, [])


	return (
		<div className="px-3 md:px-8 pb-10">

			<ModalIncreaseSize 
				open={editedVolume} 
				setOpen={setEditVolume} 
				vlume={editedVolume} 
				reloadVolumeList={listStorageVolume} 
			/>
			{!loadingData ?
				<div>
					<div>
						<div className="flex justify-between items-end text-primary mt-5" >
							<Texto big className="font-semibold">{t('volume.volumes')}  </Texto>

							<span className="flex gap-3 justify-end items-center">
								{list && list.length ?
									<Button 
										className="mx-auto w-32 font-semibold h-10 2xl:h-12 flex justify-center items-center " 
										// style={{borderRadius: "0px"}} 
										type="primary"
										onClick={
											()=>navigate('/storage/volume/deploy')
										}
									>
										{t('volume.create')}  
									</Button>
									:
									null
								}
							</span>
						</div>
						<div className="mt-5 ">
							{list && list.length ?
								<TableFunction 
									setEditVolume={setEditVolume}  
									data={list.sort((v1, v2) => v2.id - v1.id)}
									reloadVolumeList={listStorageVolume}
								/>
									:
								<div>

									<div className="border border-primary border-1 py-8 flex flex-col justify-center">
										<img src={volume} className="w-28 mx-auto" />
										<Texto className="text-center py-5">
											<span className="font-semibold text-base 2xl:text-xl">
												{t('volume.lookDOntHave')}  
											</span>	
											<br/>
											<span className="text-sm 2xl:text-base">
												{t('volume.create')}  
											</span>	
										</Texto>
										<Button 
											className="mx-auto w-32 font-semibold h-10 2xl:h-12 " 
											style={{borderRadius: "0px"}} 
											type="primary"
											onClick={
												()=>navigate('/storage/volume/deploy')
											}
										>
											{t('volume.create')}  
										</Button>
									</div>
								</div>
							}
							<div className='static  bottom-0 w-full mt-20'>
								<Card 
									classNames="w-full hidden bg-neutral-50" 
									bordered={true} 
									type='inner'
									title={
										<span className="font-semibold text-base 2xl:text-xl">
											{t('volume.bsb')}  
										</span>	
									}
									style={{borderRadius: 0}}
								>
									<div className="flex flex-wrap mt-3 gap-3 justify-between">	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"https://help.keepsec.ca/"}
													text={t('volume.overview')}
												/>
												<br/>	
												{t('volume.discover')}  
											</p> 	
										</div>	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"https://help.keepsec.ca/"}
													text={t('volume.apiDocs')}  
												/>
												<br/>	
												{t('volume.useStorageviaKeepApi')}  
											</p>	
										</div>	
										<div>
											<p className="text-sm">
												<ExtLink 
													href={"https://www.keepsec.ca/contact"}
													text={t('volume.tellWhatThink')} 
												/>
												<br/>	
												{t('volume.subFeelBack')}  
											</p>	
										</div>	
									</div> 
								</Card>
							</div>
						</div>
					</div>
				</div>
				:
				<div className='flex justify-center'>
	                <Spiner fontSize={40} />
	            </div>
	        }
		</div>
	)
}