import store from "../reducer"
import { caprover_save_onclick_app, caprover_token_save } from "../reducer/action/webappAction"
import Utils from '../utils';
import { get, post } from "./api"
import UserMgmt from "./user_managment"

const BASE_URL =  process.env.REACT_APP_USER_CAPTAIN_API 
const BASE_DOMAIN = "https://oneclickapps.caprover.com"

const getDomain = (server) =>{
    const object = {
        "x-server-url": "https://"+ (server?.domain || "captain."+server.OpenstackInstanceName+".kps.keepsec.cloud")
    }
    return object
}
 const onCaproverLogin = async (app_server) => { 
    console.log('login on server: ', app_server)
    try {
        
        const result = await UserMgmt.login_on_captain({vpsId: app_server.Id}) 
        console.log('result result result', result) 
        if(result?.data?.token){
            store.dispatch(caprover_token_save({caprover_token: result?.data?.token, ...app_server}))
            return result?.data?.token
        }
        return null;
    } catch (error) {
        console.log('error logged caprover', error) 

    }

} 
 const onClickTemplaceList = async (server_id) => {
     console.log('onClickTemplaceList start here', server_id)
    try {
        //const app_server = store.getState().webapp?.current_server
        const {list} = store.getState().webapp
        const app_server = list.find(serv => serv.Id === (server_id))
        const caprover_token =  app_server?.caprover_token || await onCaproverLogin(app_server)
        const result = await get(
                BASE_URL + "user/oneclick/template/list/", 
                { "x-access-token": caprover_token, ...getDomain(app_server) }
            )
        store.dispatch(caprover_save_onclick_app(result?.data?.oneClickApps))
        return result
    } catch (error) {
        console.log('error onClickTemplaceList caprover', error) 
    }

}
 const onGetOnClickApp = async (appName, server_id) => {
    try {
        const bsDomain = Utils.getUrlParam('repositoryUrl') || BASE_DOMAIN
        console.log('START HERE ', appName, server_id)
        const {list} = store.getState().webapp
        const app_server = list.find(serv => serv.Id === (server_id))
        console.log('app_server onGetOnClickApp ', app_server)
        const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)
        console.log('caprover_token caprover_token', caprover_token)
        const result = await get(BASE_URL + "user/oneclick/template/app?appName=" + appName + "&baseDomain=" + bsDomain, { "x-access-token": caprover_token, ...getDomain(app_server)  })
        console.log('result result result', result)
        return result
    } catch (error) {
        console.log('error logged caprover', error)
    }

}
 const getCaptainInfo = async (server_id) => {
    try {
        const app_server = store.getState().webapp?.current_server
        const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)
        console.log('caprover_token caprover_token', caprover_token)
        const result = await get(BASE_URL + "user/system/info", { "x-access-token": caprover_token, ...getDomain(app_server) })
        console.log('result result result', result)
        return result
    } catch (error) {
        console.log('error logged caprover', error)
    }

}   
const registerNewApp = async (appName, hasPersistentData, detached) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)
    return post(BASE_URL + "user/apps/appDefinitions/register", { detached, appName, hasPersistentData }, { "x-access-token": caprover_token, ...getDomain(app_server) })
}

const getAllApps = async () => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)
    return get(BASE_URL + "user/apps/appDefinitions", { "x-access-token": caprover_token, ...getDomain(app_server) })
}

const getAllApps2 = async () => {
    try{
        const app_server = store.getState().webapp?.current_server
        const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)
        if(caprover_token){
            const result = await get(BASE_URL + "user/apps/appDefinitions", { "x-access-token": caprover_token, ...getDomain(app_server) })
            return result
        }
    }catch(e){
        console.log('error fetching als app', e)
    }
}

const updateConfigAndSave = async (appName, appDefinition) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server)

    let instanceCount = appDefinition.instanceCount
    let captainDefinitionRelativeFilePath = appDefinition.captainDefinitionRelativeFilePath
    let envVars = appDefinition.envVars
    let notExposeAsWebApp = appDefinition.notExposeAsWebApp
    let forceSsl = appDefinition.forceSsl
    let websocketSupport = appDefinition.websocketSupport
    let volumes = appDefinition.volumes
    let ports = appDefinition.ports
    let nodeId = appDefinition.nodeId
    let appPushWebhook = appDefinition.appPushWebhook
    let customNginxConfig = appDefinition.customNginxConfig
    let preDeployFunction = appDefinition.preDeployFunction
    let serviceUpdateOverride = appDefinition.serviceUpdateOverride
    let containerHttpPort = appDefinition.containerHttpPort
    let description = appDefinition.description
    let httpAuth = appDefinition.httpAuth
    let appDeployTokenConfig = appDefinition.appDeployTokenConfig

    return  post(BASE_URL+'user/apps/appDefinitions/update', {
            appName: appName,
            instanceCount: instanceCount,
            captainDefinitionRelativeFilePath:
                captainDefinitionRelativeFilePath,
            notExposeAsWebApp: notExposeAsWebApp,
            forceSsl: forceSsl,
            websocketSupport: websocketSupport,
            volumes: volumes,
            ports: ports,
            customNginxConfig: customNginxConfig,
            appPushWebhook: appPushWebhook,
            nodeId: nodeId,
            preDeployFunction: preDeployFunction,
            serviceUpdateOverride: serviceUpdateOverride,
            containerHttpPort: containerHttpPort,
            description: description,
            httpAuth: httpAuth,
            envVars: envVars,
            appDeployTokenConfig: appDeployTokenConfig,
        },
        { "x-access-token": caprover_token, ...getDomain(app_server) }
    )
        
}

const uploadCaptainDefinitionContent = async( appName, captainDefinition, gitHash,  detached ) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )
    
    return post(BASE_URL+`user/apps/appData?appName=${appName}`+(detached?"?detached=1": ""),
        {
            captainDefinitionContent: JSON.stringify(captainDefinition),
            gitHash,
        },
        { "x-access-token": caprover_token, ...getDomain(app_server) }
    ) 
        
}

const fetchAppLogsInHex = async( appName ) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )
   return  get(BASE_URL+'user/apps/appData/logs?appName='+appName, { "x-access-token": caprover_token, ...getDomain(app_server) })
        
}


const fetchBuildLogs = async (appName) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )
    return get(BASE_URL+`user/apps/appData?appName=${appName}`, { "x-access-token": caprover_token, ...getDomain(app_server) })
}

const enableSslForBaseDomain = async (appName) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )

    return post(BASE_URL+`user/apps/appDefinitions/enablebasedomainssl`,
        {
            appName,
        },
        { "x-access-token": caprover_token, ...getDomain(app_server) }
    )
}
const globalPoster = async (gobalData) => {
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )

    return post(BASE_URL+`global-poster`,
        {
            ...gobalData, 
        },
        { "x-access-token": caprover_token, ...getDomain(app_server) }
    )
}

const deleteApp =  async (appName, volumes) => {
    
    const app_server = store.getState().webapp?.current_server
    const caprover_token = app_server?.caprover_token || await onCaproverLogin(app_server )

    return post(BASE_URL+`user/apps/appDefinitions/delete`,
        {
            appName,
            volumes,
        },
        { "x-access-token": caprover_token, ...getDomain(app_server) }
    )
}


export default {
    onCaproverLogin,
    uploadCaptainDefinitionContent,
    updateConfigAndSave, 
    getAllApps,
    registerNewApp,
    getCaptainInfo,
    onGetOnClickApp,
    onClickTemplaceList,
    getAllApps2,
    fetchAppLogsInHex,
    fetchBuildLogs,
    enableSslForBaseDomain,
    deleteApp,
    globalPoster


}