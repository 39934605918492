
import { useState, Fragment } from 'react';
import Utils from '../../../utils';
import { EditFilled, EditOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Switch, Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next';


function convertEnvVarsToBulk(envVars) {
    return envVars?.map((e) => {
            let val = e.value
            if (val.indexOf('\n') >= 0) {
                val = `"${val.split('\n').join('\\n')}"`
            }
            return `${e.key}=${val}`
        })
        .join('\n')
}
function parseEnvVars(src) {
    const obj = {}
    src?.toString()
        .split('\n')
        .forEach(function (line, idx) {
            // matching "KEY' and 'VAL' in 'KEY=VAL'
            const keyValueArr = line.match(/^\s*([\w.-]+)\s*=\s*(.*)?\s*$/)
            // matched?
            if (!!keyValueArr) {
                const key = keyValueArr[1]

                // default undefined or missing values to empty string
                let value = keyValueArr[2] || ''

                // expand newlines in quoted values
                const len = value ? value.length : 0
                if (
                    len > 0 &&
                    value.charAt(0) === '"' &&
                    value.charAt(len - 1) === '"'
                ) {
                    value = value.replace(/\\n/gm, '\n')
                }

                // remove any surrounding quotes and extra spaces
                value = value.replace(/(^['"]|['"]$)/g, '').trim()

                obj[key] = value
            }
        })

    return obj
}

export default function AppConfig(props){

	const [envsVar, setEnv] = useState([])
	const [envVarBulk, setEnvVarBulkEdit] = useState(null)
	const [tagsEditMode, setTagsEditMode] = useState(null)
	const [forceEditableNodeId, setForceEditableNodeId] = useState(false)
	const [forceEditableInstanceCount, setForceEditableInstanceCount] = useState(false)
    const { t } = useTranslation()

	const ports = props.apiData?.appDefinition.ports || []
	const app = props?.apiData?.appDefinition
    const volumes = props?.apiData?.appDefinition.volumes || []


	function createPortRows() {
        return ports.map((value, index) => {
            return (
                <Row style={{ paddingBottom: 12 }} key={`${index}`}>
                    <Col span={12}>
                        <Tooltip title={t('platform.configPage.appConfigsTab.portNotUsedTooltip')}>
                            <Input
                                addonBefore={t('platform.configPage.appConfigsTab.servPort')}
                                placeholder="5050"
                                value={
                                    value.hostPort ? value.hostPort + '' : ''
                                }
                                type="number"
                                onChange={(e) => {
                                    const newApiData = Utils.copyObject(
                                        props.apiData
                                    )
                                    const p = Number(e.target.value.trim())
                                    newApiData.appDefinition.ports[
                                        index
                                    ].hostPort = p > 0 ? p : 0 // to avoid NaN
                                    props.updateApiData(newApiData)
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col style={{ paddingLeft: 12 }} span={12}>
                        <Input
                            addonBefore={t('platform.configPage.appConfigsTab.containerPort')}
                            placeholder="6060"
                            value={
                                value.containerPort
                                    ? value.containerPort + ''
                                    : ''
                            }
                            onChange={(e) => {
                                const newApiData = Utils.copyObject(
                                    props.apiData
                                )
                                const p = Number(e.target.value.trim())
                                newApiData.appDefinition.ports[
                                    index
                                ].containerPort = p > 0 ? p : 0 // to avoid NaN
                                props.updateApiData(newApiData)
                            }}
                        />
                    </Col>
                </Row>
            )
        })
    }

    function createTagsValues() {

        if (tagsEditMode) {
            return (
                <Input.TextArea
                    className="code-input"
                    placeholder={'tag1,comma,separated,cannot-contain-space'}
                    rows={1}
                    defaultValue={(app?.tags || [])
                        .map((it) => it.tagName)
                        .join(',')}
                    onChange={(e) => {
                        const newValueRaw = e.target.value

                        const newApiData = Utils.copyObject(props.apiData)
                        const newTags = newValueRaw
                            .split(',')
                            .map((it) => it.trim().toLocaleLowerCase())
                            .filter((it) => !!it)
                            .map((it) => {
                                return {
                                    tagName: it,
                                }
                            })
                        newApiData.appDefinition.tags = newTags
                        props.updateApiData(newApiData)
                    }}
                />
            )
        }

        return (
            <Fragment>
                {app?.tags && app?.tags.length > 0 ? (
                    app?.tags.map(
                        (
                            it
                            // if non-edit mode, otherwise, display a comma separated textbox
                        ) => <Tag key={it.tagName}>{it.tagName}</Tag>
                    )
                ) : (
                    <i> 
                        {t('platform.configPage.appConfigsTab.noTagAssoc')}
                    </i>
                )}
            </Fragment>
        )
    }

	function createVolRows() {
        return volumes.map((value, index) => {
            return (
                <Row style={{ paddingBottom: 12 }} key={`${index}`}>
                    <Col span={8}>
                        <Input
                            addonBefore={t('platform.configPage.appConfigsTab.pathInApp')}
                            className="code-input"
                            placeholder="/var/www/html"
                            value={value.containerPath}
                            type="text"
                            onChange={(e) => {
                                const newApiData = Utils.copyObject(
                                    props.apiData
                                )
                                newApiData.appDefinition.volumes[
                                    index
                                ].containerPath = e.target.value
                                props.updateApiData(newApiData)
                            }}
                        />
                    </Col>
                    <Col
                        style={{ paddingLeft: 12 }}
                        span={8}
                        className={value.hostPath ? 'hidden' : ''}
                    >
                        <Input
                            addonBefore={t('platform.configPage.appConfigsTab.label')}
                            className="code-input"
                            placeholder="some-name"
                            value={value.volumeName}
                            onChange={(e) => {
                                const newApiData = Utils.copyObject(
                                    props.apiData
                                )
                                newApiData.appDefinition.volumes[
                                    index
                                ].volumeName = e.target.value
                                props.updateApiData(newApiData)
                            }}
                        />
                    </Col>

                    <Col
                        style={{ paddingLeft: 12 }}
                        span={8}
                        className={!value.hostPath ? 'hidden' : ''}
                    >
                        <Tooltip title={t('platform.configPage.appConfigsTab.pathInhostTooltip')}>
                            <Input
                                addonBefore={t('platform.configPage.appConfigsTab.pathInHost')}
                                className="code-input"
                                placeholder="/host/path/exists"
                                value={value.hostPath}
                                onChange={(e) => {
                                    const newApiData = Utils.copyObject(
                                        props.apiData
                                    )
                                    newApiData.appDefinition.volumes[
                                        index
                                    ].hostPath = e.target.value
                                    props.updateApiData(newApiData)
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col style={{ paddingLeft: 12 }} span={8}>
                        <Button
                            type="dashed"
                            onClick={() => {
                                const newApiData = Utils.copyObject(
                                    props.apiData
                                )
                                newApiData.appDefinition.volumes[
                                    index
                                ].hostPath = newApiData.appDefinition.volumes[
                                    index
                                ].hostPath
                                    ? ''
                                    : '/'
                                props.updateApiData(newApiData)
                            }}
                        >
                            {value.hostPath
                                ? t('platform.configPage.appConfigsTab.letKeepSecManage')
                                : t('platform.configPage.appConfigsTab.setSpecific')
                            }
                        </Button>
                    </Col>
                </Row>
            )
        })
    }
	function createVolSection() {
        if (!app?.hasPersistentData) return <div />

        return (
            <div>

                {createVolRows()}
                <br />
                <Button type="default" onClick={() => addVolumeClicked()}>
                    { t('platform.configPage.appConfigsTab.addPersistantDirectory')}
                </Button>
                <br />
                <br />
                <Row>
                    <div
                        className="md:min-w-[300px] min-w-full "
                    >
                        <Tooltip title={ t('platform.configPage.appConfigsTab.leaveEmpty')}>
                            <Input
                                addonBefore={t('platform.configPage.appConfigsTab.nodeId')}
                                className="code-input"
                                value={app?.nodeId ? app?.nodeId : ''}
                                disabled={!forceEditableNodeId}
                                onChange={(e) => {
                                    const newApiData = Utils.copyObject(
                                        props.apiData
                                    )
                                    newApiData.appDefinition.nodeId =
                                        e.target.value
                                    props.updateApiData(newApiData)
                                }}
                            />
                        </Tooltip>
                    </div>
                    <Col span={12} style={{ paddingLeft: 24 }}>
                        <Tooltip title={ t('platform.configPage.appConfigsTab.changeNodeIdTooltip')}>
                            <Button
                                type="default"
                                disabled={forceEditableNodeId}
                                onClick={() =>
                                    setForceEditableNodeId(true)
                                }
                            >
                                { t('platform.configPage.appConfigsTab.editNodeIdButton')}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>

                <br />
                <br />
            </div>
        )
    }



 	

 	const addPortMappingClicked = () => {
        const newApiData = Utils.copyObject(props.apiData)
        newApiData.appDefinition.ports = newApiData.appDefinition.ports || []
        newApiData.appDefinition.ports.push({
            containerPort: 0,
            hostPort: 0,
        })
        props.updateApiData(newApiData)
    }


     const addVolumeClicked = () => {
        const newApiData = Utils.copyObject(props?.apiData)
        newApiData.appDefinition.volumes =
            newApiData.appDefinition.volumes || []
        newApiData.appDefinition.volumes.push({
            containerPath: '',
            volumeName: '',
        })
        props.updateApiData(newApiData)
    }


    function addEnvVarClicked() {
        const newApiData = Utils.copyObject(props.apiData)
        newApiData.appDefinition.envVars =
            newApiData.appDefinition.envVars || []
        newApiData.appDefinition.envVars.push({
            key: '',
            value: '',
        })
        props.updateApiData(newApiData)
    }

    const envVars = props?.apiData?.appDefinition.envVars || []
    function createEnvVarSection() {

        if (envVarBulk?.envVarBulkEdit) {
            return (
                <div>
                    <Row style={{ paddingBottom: 12 }}>
                        <Col span={24}>
                            <Input.TextArea
                                className="code-input"
                                placeholder={'key1=value1\nkey2=value2'}
                                rows={7}
                                value={
                                    envVarBulk?.envVarBulkVals
                                        ? envVarBulk.envVarBulkVals
                                        : convertEnvVarsToBulk(envVars)
                                }
                                onChange={(e) => {
                                    const newApiData = Utils.copyObject(
                                        props.apiData
                                    )
                                    const keyVals = parseEnvVars(
                                        e.target.value
                                    )
                                    const envVars = []
                                    Object.keys(keyVals).forEach((k) => {
                                        envVars.push({
                                            key: k,
                                            value: keyVals[k],
                                        })
                                    })
                                    newApiData.appDefinition.envVars = envVars
                                    props.updateApiData(newApiData)
                                    setEnvVarBulkEdit({
                                    	...envVarBulk,
                                        envVarBulkVals: e.target.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }

        const rows = envVars.map((value, index) => {
            return (
                <Row style={{ paddingBottom: 12 }} key={`${index}`}>
                    <Col span={8}>
                        <Input
                            className="code-input"
                            placeholder="key"
                            value={value.key}
                            type="text"
                            onChange={(e) => {
                                const newApiData = Utils.copyObject(
                                    props?.apiData
                                )
                                newApiData.appDefinition.envVars[index].key =
                                    e.target.value
                                props.updateApiData(newApiData)
                            }}
                        />
                    </Col>
                    <Col style={{ paddingLeft: 12 }} span={16}>
                        <Input.TextArea
                            className="code-input"
                            placeholder="value"
                            rows={1}
                            value={value.value}
                            onChange={(e) => {
                                const newApiData = Utils.copyObject(
                                    props.apiData
                                )
                                newApiData.appDefinition.envVars[index].value =
                                    e.target.value
                                props.updateApiData(newApiData)
                            }}
                        />
                    </Col>
                </Row>
            )
        })

        return (
            <div>
                {rows}

                <br />

                <Button
                    type="default"
                    onClick={() => addEnvVarClicked()}
                >
                    { t('platform.configPage.appConfigsTab.addKeypair')}
                </Button>
            </div>
        )
    }

	return(
		<div className=" ">
			<div>
	        	<p>
					<span className="text-sm xl:text-base font-semibold">
						 { t('platform.configPage.appConfigsTab.envVars')}
					</span>
	        		<br/>
	        		{!(app?.envVars && !!app?.envVars.length) && envsVar.length === 0  ?
	        			<span><i>{ t('platform.configPage.appConfigsTab.notHavingEnvVars')}</i></span>
	        			: 
	        			null
	        		}
	        	</p>
	        	<Row align="middle" justify="end">
                    <h5>
                    { t('platform.configPage.appConfigsTab.buildEdit')}&nbsp;{' '}
                        <Switch
                            onChange={(val) => {
                                setEnvVarBulkEdit({
                                    envVarBulkEdit: val,
                                    envVarBulkVals: '',
                                })
                            }}
                        />
                    </h5>
                </Row>

	        	<div>
	        		{createEnvVarSection()}
	        	</div>
	        </div>

	        <div className="pt-10">
	        	<p>
	        		<span className="font-semibold text-sm xl:text-base">{ t('platform.configPage.appConfigsTab.portMap')}</span>
	        		<br/>
	        		{!(app?.ports && !!app?.ports.length)  ?
	        			<span><i>{ t('platform.configPage.appConfigsTab.notHavingportmapping')}</i></span>
	        			: 
	        			null
	        		}
	        	</p>

	        	<div className="pt-5">
	        		{createPortRows()}
               		 <br />
	                <Button
	                    type="default"
	                    onClick={() => addPortMappingClicked()}
	                >
	                    { t('platform.configPage.appConfigsTab.addPortmappingBtn')}
	                </Button>
	        	</div>
	        </div>

	        <div className="pt-10">
	        	<p>
	        		<span className="text-sm xl:text-base font-semibold">{ t('platform.configPage.appConfigsTab.persistantDirectory')}</span>
	        		<br/>
	        		{!(app?.volumes && !!app?.volumes.length)  ?
	        			<span><i>  { t('platform.configPage.appConfigsTab.notHavingPersiDirrectory')}</i></span>
	        			: 
	        			null
	        		}
	        	</p>

	        	<div className="pt-5">
	        		{createVolSection()}
	        	</div>
	        </div>

	        <div className="pt-10 ">
	        	<Row>
                    <div
                        span={6}
                        className=" min-w-wull md:min-w-[300px] "
                    >
                        <Tooltip title={ t('platform.configPage.appConfigsTab.nberRunningRunn')}>
                            <Input
                                addonBefore={ t('platform.configPage.appConfigsTab.instanceCount')}
                                type="number"
                                defaultValue={app?.instanceCount + ''}
                                disabled={
                                    app?.hasPersistentData &&
                                    !forceEditableInstanceCount
                                }
                                onChange={(e) => {
                                    const newApiData = Utils.copyObject(
                                        props.apiData
                                    )
                                    newApiData.appDefinition.instanceCount =
                                        Number(e.target.value)
                                    props.updateApiData(newApiData)
                                }}
                            />
                        </Tooltip>
                    </div>
                    <Col span={6}>
                        <div
                            style={{ paddingLeft: 24 }}
                            className={
                                !app?.hasPersistentData ? 'hidden' : ''
                            }
                        >
                            <Tooltip title={ t('platform.configPage.appConfigsTab.multipleInstancePreventTooltip')}>
                                <Button
                                    type="default"
                                    disabled={
                                        forceEditableInstanceCount
                                    }
                                    onClick={() =>
                                        setForceEditableInstanceCount(true)
                                    }
                                >
                                    { t('platform.configPage.appConfigsTab.multipleInstancePreventEditBtn')}
                                </Button>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
	        </div>

	        {/* <div className="pt-10">
	        	<p>
	        		<span className="text-sm xl:text-base font-semibold"> { t('platform.configPage.appConfigsTab.prevScript')}</span>
	        		<br/>
	        	</p>
	        	<Col span={24}>
                    <Input.TextArea
                        spellCheck={false}
                        autoCorrect="off"
                        autoComplete="off"
                        autoCapitalize="off"
                        className="code-input"
                        placeholder="var preDeployFunction = function (capRoverAppObj, dockerUpdateObject) ..."
                        rows={4}
                        value={
                            app?.preDeployFunction
                                ? app?.preDeployFunction
                                : ''
                        }
                        onChange={(e) => {
                            const newApiData = Utils.copyObject(
                                props.apiData
                            )
                            newApiData.appDefinition.preDeployFunction =
                                e.target.value
                            props.updateApiData(newApiData)
                        }}
                    />
                </Col>

	        </div>

	        <div className="pt-10">
	        	<p>
	        		<span className="text-sm xl:text-base font-semibold"> { t('platform.configPage.appConfigsTab.servupOveride')} </span>
	        		<br/>
	        		
	        	</p>

	        	<div className="pt-4">
	        		<Input.TextArea
	                    spellCheck={false}
	                    autoCorrect="off"
	                    autoComplete="off"
	                    autoCapitalize="off"
	                    className="code-input"
	                    placeholder={`## JSON / YAML
	                        {
	                          "TaskTemplate": {
	                            "ContainerSpec": {
	                            "Image": "busybox",
	                            "Args": [
	                                "top"
	                            ]....`
	                    }
	                    rows={4}
	                    value={
	                        app?.serviceUpdateOverride
	                            ? app?.serviceUpdateOverride
	                            : ''
	                    }
	                    onChange={(e) => {
	                        const newApiData = Utils.copyObject(
	                            props.apiData
	                        )
	                        newApiData.appDefinition.serviceUpdateOverride =
	                            e.target.value
	                        props.updateApiData(newApiData)
	                    }}
	                />
	        	</div>
	        </div> */}

	        <div className="pt-10">
	        	<p>
	        		<span className="text-sm xl:text-base font-semibold">
	        			{ t('platform.configPage.appConfigsTab.servTags')}
	        		</span>
	        		<br/>
	        	</p>

	        	<div style={{ marginTop: 10 }}>
                    <span
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            setTagsEditMode(!tagsEditMode)
                        }}
                    >
                        {tagsEditMode ? (
                            <EditFilled />
                        ) : (
                            <EditOutlined />
                        )}
                    </span>
                    <span
                        className={
                            app?.tags && !!app?.tags.length
                                ? 'hidden'
                                : ''
                        }
                    ></span>
                    <span>{createTagsValues()}</span>
                </div>
                <div
                    style={{
                        height: 36,
                    }}
                />
	        </div>
        </div>
	)

}

