import axios from 'axios'

const controller = new AbortController()
// 5 second timeout:

const timeoutId = setTimeout(() => controller.abort(), 500000)

export const get = (path, header, noJson) => {
  const xsrfToken = localStorage.getItem('xsrfToken');
    const r = fetch(path, {
         method: 'GET',
         'credentials': 'include',
         headers: {
          //  'Content-Type': 'application/json',
           'x-xsrf-token': xsrfToken,
          //  'skip-auth': process.env.REACT_APP_ENV === "local" ,
           ...header,
      },
      
      //signal: controller.signal 
    }).then(
      (response) =>noJson? response : response.json()
    ).then(
      (responseJson) => {
        return responseJson;
      }
    ).catch(e => console.log('error geting //', e))
    return r;
}

export const  post = async (path, data, header, json=true, useCookie=true, isMini=false) => {
  let header2 = {
    'Content-Type': json ? 'application/json' : "application/x-www-form-urlencoded",
    ...header
    
  }
  const xsrfToken = localStorage.getItem('xsrfToken'); 
  if(!isMini){
    header2 = {
      'x-xsrf-token': xsrfToken,
      // 'skip-auth': process.env.REACT_APP_ENV === "local" ,
      ...header2
    }
  }
  return axios.post(path, data, {
      headers: header2,
      // method: "post",
      timeout: 5000000000,
      // url: path,
      withCredentials: useCookie
      // data: data
  }).then(
      (response) => {
        return response.data
      }
  ).catch(function (error) {
      console.log('axios error', error)
      return error.response?.data //{code: error?.response?.status, data: error?.response?.data, errror: true}
  });
}
export const post2 = async (path, data, header, json=true) => {
    const r = fetch(path, {
        method: 'POST',
        'credentials': 'include',
        headers: {
          'Content-Type': json ? 'application/json' : "application/x-www-form-urlencoded",
          // 'skip-auth': process.env.REACT_APP_ENV === "local" ,
          ...header
     },
     body: json ? JSON.stringify(data) : data,
     //signal: controller.signal 
   }).then(
     (response) => {
      if(true){
        console.log('response response ', response)
        return response.json()
      }else{
        return response
      }
    }
   ).then(
     (responseJson) => {
       return responseJson;
     }
   )
   
   return r;
}

export const put = async (path, data, header, useCookie=true, isMini=false) => {
  let header2 = {
    'Content-Type': 'application/json' ,
    ...header
  }
  const xsrfToken = localStorage.getItem('xsrfToken');
  if(!isMini){
    header2 = {
      'x-xsrf-token': xsrfToken,
      // 'skip-auth': process.env.REACT_APP_ENV === "local" ,
      ...header2
    }
  }
    const r = fetch(path, {
        method: 'put',
        'credentials': useCookie ? 'include': null,
        headers: {
          ...header2
     },
     body: JSON.stringify(data),
     //signal: controller.signal 
   }).then(
     (response) => response.json()
   ).then(
     (responseJson) => {
       return responseJson;
     }
   )
   
   return r;
}
export const onDelete = (path, data, header, useCookie=true, isMini=false) => {

  let header2 = {
    'Content-Type': 'application/json' ,
    ...header
  }
  const xsrfToken = localStorage.getItem('xsrfToken');
  if(!isMini){
    header2 = {
      'x-xsrf-token': xsrfToken,
      // 'skip-auth': process.env.REACT_APP_ENV === "local" ,
      ...header2
    }
  }

    const r = fetch(path, {
        method: 'DELETE',
        'credentials': useCookie ? 'include' : null,
        headers: header2,
      body: JSON.stringify(data),
      //signal: controller.signal 
    }).then(
      (response) => response
    ).then(
      (responseJson) => {
        return responseJson;
      }
    )
    return r;
}