import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';
import { QuestionOutlined } from "@ant-design/icons";
import { Button, Input, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import almalinux from '../../../assets/img/almalinux.png'
import centos from '../../../assets/img/centos.png'
import debian from '../../../assets/img/debian.png'
import controlPlane from '../../../assets/img/k8/controlplane.png'
import k8sImage from '../../../assets/img/k8/kubernetes-kp.webp'
import nodeSelector from '../../../assets/img/k8/node-kp.png'
import kpsLogo from '../../../assets/img/KPS-logo-56px.png'
import arrow from '../../../assets/img/networking/arrow.png'
import ha from '../../../assets/img/networking/computer.png'
import external from '../../../assets/img/networking/external.png'
import left from '../../../assets/img/networking/left.png'
import world from '../../../assets/img/networking/world.png'
import world2 from '../../../assets/img/networking/world2.png'
import ubuntu from '../../../assets/img/ubuntu.png'
import { InputText } from '../../../components/Bloc';
import Utils from '../../../utils';

const imgs = {
    ubuntu,
    debian,
    almalinux,
    centos
}
const sourceContrainct = {
	custom: "all",
	default_domain: "all",
	vpc: "tcp;udp",
	interconnect: "tcp;udp",
	external: "tcp;udp",
	vpsmini: "https;http"
}

const balancedContrainct = {
	vps: "vpc;custom;external;default_domain",
	vpssec: "vpc;custom;interconnect;default_domain;external",
	vpssecmini: "custom;default_domain",
	vpsmini: "custom;default_domain",
	kps: "custom",
	kubernetes: "external;custom;interconnect;default_domain;ha;vpc",
}

const shouldDisplay = (node, type) => {
	const str = node?.IsMini ? 'vpsmini' : node?.IsMiniSec ? "vpssecmini" : node?.IsSec? "vpssec" : "vps"
	return balancedContrainct[str]?.includes(type)
}
const destinationString = (node, kube, kps) => {
	if(kube) return balancedContrainct["kubernetes"]
	if(kps) return balancedContrainct["kps"]
	// const str = node?.IsMini ? 'vpsmini' : node?.IsMiniSec ? "vpssecmini" : node?.IsSec? "vpssec" : "vps"
	// return balancedContrainct[str]
	return balancedContrainct["vps"]
}



const types = [
		{
			label: "HTTP",
			name: "http",
		},
		{
			label: "HTTPS",
			name: "https",
		},
		{
			label: "TCP",
			name: "tcp",
		},
		{
			label: "UDP",
			name: "udp",
		},
	]

const tab = [
    'ubuntu',
    'debian',
    'almalinux',,
    'centos'
]

const Select2orMore = ({isForCustom}) => {
	return(
			<p className=" text-center d-inline font-semibold">
				{!isForCustom ? 
					"Select 2 or more instances"
					:
					"Enter 2 or more IPs/domains"
				} {" "}
				<Tooltip 
                  title={
					<>
						<span> <b>Default domain:</b> Redirect traffic to given KeepSec domains of the services.</span> <br />
						<span> <b>External:</b> Redirect traffic to public IPs of the services.</span> <br />
						<span> <b>VPC:</b> Redirect traffic to the VPC network private IPs of the services.</span>
					</>
				  }
               >
                  <Button className="w-7 " type="default" shape="circle" icon={<QuestionOutlined />} />
               </Tooltip>
			</p>
	)
}

export function Source(props){
	const attr = props?.destination ? "destination" : "source"
	const [sources, setSource] = useState([])

	useEffect(()=>{
		const sourcs = [
			{
				src: world, 
				name: "Default domain",
				type: "default_domain",
				desc: props.server?.domain
			},
			{
				src: external,
				name: "External",
				type: "external",
				desc:  props.server?.OpenstackInstanceExtIp
			},
			{
				src: left,
				name: "VPC",
				type: "vpc",
				desc: props.server?.vpc?.find(vpc => !!vpc?.isDefault)?.privateIp  //[props.server?.ksNetwork?.name]?.[0]?.addr
			},
			{
				src: arrow,
				name: "InterconnectKS",
				type: "interconnect",
				desc: props.server?.netmaker?.[0]?.node?.address?.IP
			}
		]
		if(!props?.destination){
			sourcs.push({
				src: world2,
				name: "Custom domain",
				type: "custom",
				desc: null
			})
		}
		if(props?.destination){
			sourcs.push({
				src: ha,
				name: "High Availability",
				type: "ha",
				desc: null
			})
		}
		setSource([...sourcs])
	}, [props.server])
	return(
		<div className='flex gap-4 flex-wrap items-center justify-center'> 
			{sources
			.filter(source => {
				if(props.filterByvpsM){
					return balancedContrainct['vpsmini'].includes(source?.type)
				}else{
					if(props?.destination){
						if(props?.selectedTab === "Compute"){
							let shouldDisplay = false
							const toMap = props?.vps_params?.instances || []
							for (let i = 0; i < toMap.length; i++) {
								shouldDisplay = destinationString(toMap[i]).includes(source?.type)
								if(shouldDisplay) return true
							}
							return false
						}
						if(props?.selectedTab === "Kubernetes"){
							
							return destinationString(null, true).includes(source?.type)
						}
						if(props?.selectedTab === "Platform"){
							
							return destinationString(null, null, true).includes(source?.type)
						}
					}else{
						return true
					}
				}
			})
			.map((source, k) =>{
				return(
		            <div  
		            	key={k}
		            	className={`
		            		${!props?.destination ? "w-56 xl:w-60 2xl:w-64" : "w-44"} 
		            		relative 
		            		dark:bg-bgPagedark  
		            		mt-5  
		            		cursor-pointer 
		            		rounded-xl
		            		dark:hover:shadow-slate-700
		                	hover:bg-white  hover:shadow-2xl
		                	 ${props?.selectedType === source?.type ? "bg-white shadow-xl" : 'bg-neutral-100 '}
		                	`
		                }

		                onClick={() => {
		                    if (!props?.creating) {
		                    	let temp = { }
		                    	if(attr ==="source"){
		                    		temp = { type: null }
		                    	}
	                        	if(attr ==="source"&&(source?.type==="interconnect"||source?.type==="vpc")){
	                            	temp = {...temp, destination: source }
	                        	}
	                            props.setVpsParams({ ...props.vps_params, [attr]: source, ...temp })
		                        // if (selectedType == source?.type) {
		                        // 	let temp = {}
		                        // 	if(attr ==="source"&&(source?.type==="interconnect"||source?.type==="vpc")){
		                        //     	temp = {destination: null }
		                        // 	}
		                        //     props.setVpsParams({ 
		                        //     	...props.vps_params,
		                        //     	[attr]: null,
		                        //     	...temp
		                        //     })
		                        // } else {
		                            

		                        // }
		                    }
		                }} 
		            >
		                
		                {props?.selectedType === source?.type &&
		                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
		                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
		                    </svg>
		                } 

		                <div className={'flex py-2 px-2 items-center gap-2'}
		                >
		                    <img 
		                    	src={source.src} 
		                    	alt="" 
		                    	className={`${!props?.destination ? "w-8 2xl:w-10" : "w-5 2xl:w-7"}`} 

		                    />
		                    
		                    <p className='text-primary 2xl:leading-4 leading-3 dark:text-darkTextColor font-semibold'>
			                    <span className='2xl:text-base  text-sm'>
			                    	{source.name}{!props?.destination ? ":" : ""}
			                    </span>
			                    {!props?.destination?
				                    <>
				                    	<br/>
					                    {source?.type==="custom" ?
					                    	<Input 
					                    		onChange={(e) => props.setVpsParams({...props.vps_params, customDomain: e.target.value})} 
												placeholder="Enter a custom domain" 
												className="w-full rounded h-6"
												value={props.vps_params?.customDomain}
												required={props?.selectedType === source?.type}
					                    	/>
					                    	:
						                    <span className='2xl:text-xs text-[12px]'>
						                    	{source.desc}
						                    </span>
					                    }
			                    	</>
			                    	:
			                    	null
			                    }
		                    </p>
		                    
		                </div>
		            </div> 
				)
			})} 

            
        </div>
	)
}


export function Type(props){
	const source = props.vps_params?.source?.type
	const selectedType = props.vps_params?.type?.name

	return(   
		<div className='flex gap-4 flex-wrap items-center justify-center'> 
			{types.filter(typ => {
					if(!props.filterByvpsM){
						return !source ? false : sourceContrainct[source]==="all" ? true : sourceContrainct[source]?.includes(typ?.name)
					}else{
						return sourceContrainct["vpsmini"]?.includes(typ?.name)
					}
				}
			).map((type, k) =>{
				return(
		            <div  
		            	key={k}
		            	className={`
		            		w-44 
		            		relative 
		            		dark:bg-bgPagedark  
		            		mt-5  
		            		cursor-pointer 
		            		rounded-xl
		            		dark:hover:shadow-slate-700
		                	hover:bg-white  hover:shadow-2xl
		                	 ${selectedType === type?.name ? "bg-white shadow-xl" : 'bg-neutral-100 '}
		                	`
		                }

		                onClick={() => {
		                    if (!props?.creating) {
		                        props.setVpsParams({ ...props.vps_params, type: type })
		                        // if (selectedType == type?.name) {
		                        //     props.setVpsParams({ ...props.vps_params, type: null })
		                        // } else {

		                        // }
		                    }
		                }} 
		            >
		                
		                {selectedType === type?.name &&
		                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
		                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
		                    </svg>
		                } 

		                <div className={'flex py-2 px-2 items-center gap-2'}
		                >
		                    <img src={world2} alt="" className='w-8 2xl:w-10' />
		                    
		                    <p className='text-primary 2xl:leading-4 leading-3 dark:text-darkTextColor font-semibold'>
			                    <span className='2xl:text-base  text-sm'>
			                    	{type.label}
			                    </span>
		                    </p>
		                    
		                </div>
		            </div> 
				)
			})} 

            
        </div>
	)
}


export function KubernetesLoadBalancer(props){

	const { kubernetes } =  useSelector(app => app.k8s) 

	return(
		<div>
			<Select2orMore/>
			<div className="mt-3 pb-5 w-full xl:w-[1000px] mx-auto">
	            <span className="font-semibold dark:text-darkTextColor">KUBERNETES ({kubernetes?.length || 0})</span>
	            <div className="mt-3 rounded-xl  border border-gray-300 dark:border-gray-800 p-5">
	                {(kubernetes && kubernetes.length !== 0) &&
	                    <div className="flex flex-row gap-6  mb-1  items-center">
	                        {kubernetes.map((kube, i)=>{
	                        	return(
	                        		<div key={i} className="flex flex gap-6  mb-1  items-center">
	                        					<div >
	                        						<div 
											            className={
											            `	
											            	cursor-pointer  
											                server 
											                bg-grey 
											                block 
											                2xl:w-[270px]
											                w-[220px]
											                rounded-lg  
											                shadow-lg 
											                dark:bg-bgPagedark 
											                dark:text-darkTextColor
											                relative
											            `}
											            onClick={() => {
										                    if (!props?.creating) {
										                    	const oldSec = props?.vps_params?.clusters || []
										                        
										                        if (oldSec?.includes(kube?.id)) {
										                            props.setVpsParams({...props?.vps_params, clusters: oldSec.filter(c => c !== kube?.id)})
										                        } else {
										                            oldSec.push(kube?.id)
										                            props.setVpsParams({...props?.vps_params, clusters: [...oldSec]})
										                        }
										                    }
										                }} 
											        >
											        	{props?.vps_params?.clusters?.includes(kube.id) &&
										                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
										                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
										                    </svg>
										                }

											            <div className="p-2">
											                <div className='flex justify-between items-start'>
											                    <div className='flex gap-2'>
											                        <img src={k8sImage} alt="" className='2xl:w-14 2xl:h-14 w-10 h-10' />
											                        <div>
											                            <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
											                                {kube?.clusters?.find(x => !!x.isPrimaryInstance )?.name}
											                            </span> 
											                            <br/>
											                            <span className="2xl:text-sm font-semibold inline-flex text-xs">
											                                High Availability
											                                {kube?.isHA  ?
											                                    <CheckCircleFilled className="text-primary" />
											                                    :
											                                    <CloseCircleFilled className="text-gray" />
											                                }
											                            </span>
											                        </div>
											                    </div> 
											                </div>
											            </div>
											        </div>
	                        					</div>
	                        		</div>
	                        	)
	                        })}
	                    </div>
	                }
	            </div>
	        </div>
        </div>
	)
}

export function KubernetesLoadBalancer2(props){

	const { kubernetes } =  useSelector(app => app.k8s) 

	return(
		<div>
			<Select2orMore/>
			<div className="mt-3 pb-5 w-full xl:w-[1000px] mx-auto">
	            <span className="font-semibold dark:text-darkTextColor">KUBERNETES ({kubernetes?.length || 0})</span>
	            <div className="mt-3 rounded-xl  border border-gray-300 dark:border-gray-800 p-5">
	                {(kubernetes && kubernetes.length !== 0) &&
	                    <div className="flex flex-row gap-6  mb-1 justify-center  items-center">
	                        {kubernetes.map((kube, i)=>{
	                        	return(
	                        		<div key={i} className="flex flex gap-6  mb-1 justify-center  items-center">
	                        			{kube?.clusters?.sort((x, y) => Number(!!x.isKubeMaster || null) - Number(!!x.isKubeMaster || null)).map((cluster, j) =>{
	                        				return(
	                        					<div key={j}>
	                        						<div 
											            className={
											            `	
											            	cursor-pointer  
											                server 
											                bg-grey 
											                block 
											                2xl:w-[270px]
											                w-[220px]
											                rounded-lg  
											                shadow-lg 
											                dark:bg-bgPagedark 
											                dark:text-darkTextColor
											                relative
											            `}
											            onClick={() => {
										                    if (!props?.creating) {
										                    	const oldSec = props?.vps_params?.clusters || []
										                        
										                        if (oldSec?.includes(cluster?.id)) {
										                            props.setVpsParams({...props?.vps_params, clusters: oldSec.filter(c => c !== cluster?.id)})
										                        } else {
										                            oldSec.push(cluster?.id)
										                            props.setVpsParams({...props?.vps_params, clusters: [...oldSec]})
										                        }
										                    }
										                }} 
											        >
											        	{props?.vps_params?.clusters?.includes(cluster.id) &&
										                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
										                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
										                    </svg>
										                }

											            <div className="p-2">
											                <div className='flex justify-between items-start'>
											                    <div className='flex gap-2'>
											                        <img src={k8sImage} alt="" className='2xl:w-14 2xl:h-14 w-10 h-10' />
											                        <div>
											                            <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
											                                {cluster?.name}
											                            </span> 
											                            <br/>
											                            <span className="2xl:text-sm font-semibold inline-flex text-xs">
											                                High Availability
											                                {kube?.isHA  && cluster?.isKubeMaster ?
											                                    <CheckCircleFilled className="text-primary" />
											                                    :
											                                    <CloseCircleFilled className="text-gray" />
											                                }
											                            </span>
											                        </div>
											                    </div> 
											                </div>
											            </div>
											        </div>
	                        					</div>
	                        				)
	                        			})}
	                        		</div>
	                        	)
	                        })}
	                    </div>
	                }
	            </div>
	        </div>
        </div>
	)
}

export function ComputeLoadBalancer(props){
	const {filterByvpsM, setBalanceByVpsM} = props
	const [listMap, setListMap] = useState(null)
	const list = useSelector(app => {
		if(props.app){
			return app.webapp?.list || []
		}
        return app.vps?.list || []
    })

	useEffect(()=>{
		if(list){
			setListMap(list.filter(n => { 
				if(props.app){
					return Utils.is(n, 'up')
				}else {
					if(!filterByvpsM){
						return  Utils.is(n, 'up') && n?.service?.task_name !== "vpsmini"
					}else{
						return  Utils.is(n, 'up') && n?.service?.task_name === "vpsmini"
					}
				}
			})
		)
		}
	}, [list, filterByvpsM])

	return(
		<div>
			<Select2orMore/>
			<div className="mt-3 pb-5 w-full xl:w-[1000px] mx-auto">
				<div className='flex justify-between items-center'>
					<span className="font-semibold dark:text-darkTextColor">
						{props?.app ? "PLATFORM" : "COMPUTE"} (
							{
								list?.length && listMap ? listMap.length : 0
							}
						)
					</span>
					<span style={{display: props?.app && "none"}} className='font-semibold'>
						Balance VPSM instead: {" "}
						<Switch checked={filterByvpsM} onChange={(checked)=>setBalanceByVpsM(checked)} />
					</span>
				</div>
	            <div className="mt-3 rounded-xl  border border-gray-300 dark:border-gray-800 p-5">
	                {(list && listMap && listMap.length) ?
                    <div className="flex flex-row gap-6  mb-1  items-center">
                        {listMap.filter(server => Utils.is(server, "up")).map((node, i)=>{
	                        return(
	                        		<div key={i}>
	            						<div 
								            className={
								            `	
								            	cursor-pointer  
								                server 
								                bg-grey 
								                block 
								                2xl:w-[270px]
								                w-[220px]
								                rounded-lg  
								                shadow-lg 
								                dark:bg-bgPagedark 
								                dark:text-darkTextColor
								                relative
								            `}
								            onClick={() => {
							                    if (!props?.creating) {
							                    	const oldSec = props?.vps_params?.instances || []
							                        
							                        if (oldSec?.includes(node?.Id)) {
							                            props.setVpsParams({...props?.vps_params, instances: oldSec.filter(c => c !== node?.Id)})
							                        } else {
							                            oldSec.push(node?.Id)
							                            props.setVpsParams({...props?.vps_params, instances: [...oldSec]})
							                        }
							                    }
							                }} 
								        >
								        	{props?.vps_params?.instances?.includes(node.Id) &&
							                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
							                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
							                    </svg>
							                }

								            <div className="p-2">
								                <div className='flex justify-between items-start'>
								                    <div className='flex gap-2'>
								                        <img 
								                        	src={props?.app ? kpsLogo : imgs[tab.find(k => node?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} 
								                        	alt="" 
								                        	className='2xl:w-14 2xl:h-14 w-10 h-10'
								                        />
								                        <div>
								                            <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
								                                {node?.DisplayName}
								                            </span> 
								                            <br/>
								                            <span className="2xl:text-sm font-semibold inline-flex text-xs">
								                                {node?.OpenstackInstanceExtIp || node?.OpenstackInstanceLocalIp} 
								                            </span>
								                        </div>
								                    </div> 
								                </div>
								            </div>
								        </div>
	            					</div>
	                        )
                        })}
                    </div>
                    :
                    null
                }
	            </div>
	        </div>
        </div>
	)
}
export function ComputeLoadBalancerInstance(props){
	const dest = props?.vps_params?.destination?.type

	const list = useSelector(app => {
		if(props.app){
			return app.webapp?.list || []
		}
        return app.vps?.list?.filter(vps => vps?.service?.task_name !== "vpsmini") || []
    })

	function getInstances(){
		const instances = []
		for (let i = 0; i < list.length; i++) {
			const inst = list[i]
			if(props?.vps_params?.instances?.includes(inst?.Id)){
				instances.push(inst)
			}
		}
		return instances
	}
	return(
		<div>
			<div className="mt-3 pb-5">
	            <div className="mt-3 rounded-xl   p-5">
	                {(props?.vps_params?.instances?.length) ?
                    <div className="flex flex-row gap-6  mb-1 justify-center items-center">
                        {getInstances().filter(serv => {
                        	if(props.app){
                        		return serv.ServiceType === "KPS_CLOUD"
                        	}else{
                        		return serv.ServiceType !== "KPS_CLOUD"
                        	}
                        })?.map((node, i)=>{
                        	return(
                        		<div key={i}>
            						<div 
							            className={
							            `	
							            	cursor-pointer  
							                server 
							                bg-grey 
							                block 
							                2xl:w-[270px]
							                w-[220px]
							                rounded-lg  
							                shadow-lg 
							                dark:bg-bgPagedark 
							                dark:text-darkTextColor
							                relative
							            `}
							            onClick={() => {
						                    if (!props?.creating) {
						                    	const oldSec = props?.vps_params?.computeIp || []
						                        
						                        if (oldSec?.includes(node?.Id)) {
						                            props.setVpsParams({...props?.vps_params, computeIp: oldSec.filter(c => c !== node?.Id)})
						                        } else {
						                            oldSec.push(node?.Id)
						                            props.setVpsParams({...props?.vps_params, computeIp: [...oldSec]})
						                        }
						                    }
						                }} 
							        >
							        	{props?.vps_params?.computeIp?.includes(node?.Id) &&
						                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
						                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
						                    </svg>
						                }

							            <div className="p-2">
							                <div className='flex justify-between items-start'>
							                    <div className='flex gap-2'>
							                        <img src={props?.app ? kpsLogo : imgs[tab.find(k => node?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} alt="" className='2xl:w-14 2xl:h-14 w-10 h-10' />
							                        <div>
							                            <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
							                                {node?.DisplayName}
							                            </span> 
							                            <br/>
							                            <span className="2xl:text-sm font-semibold inline-flex text-xs">
							                                {node?.OpenstackInstanceExtIp || node?.OpenstackInstanceLocalIp} 
							                            </span>
							                        </div>
							                    </div> 
							                </div>
							            </div>
							        </div>
            					</div>
                        	)
                        })}
                    </div>
                    :
                    null
                }
	            </div>
	        </div>
        </div>
	)
}

export function KubernetesLoadBalancerInstance(props){

	const dest = props?.vps_params?.destination?.type

	const { kubernetes } =  useSelector(app => app.k8s)
	const [nodes, setListNode] = useState([])
 
	useEffect(()=>{
		let totalNode = [];
		let clusts = []
		for (let i = 0; i < kubernetes.length; i++) {
			const clus = kubernetes[i].clusters || []
			clusts = clusts.concat(clus)
		}
	    for (let i = 0; i < props?.vps_params?.clusters?.length; i++) {
	        const instances = clusts.find(c => c.id === props?.vps_params?.clusters[i])?.instances
	        totalNode = totalNode.concat(instances.filter(ins => ins.isKubeMaster))
	    }
		
    	setListNode(totalNode)
	}, [props?.vps_params?.clusters])
	return(
		<div className="mt-3 pb-5">
            <div className="mt-3 rounded-xl   px-5 py-3">
                {(nodes && nodes.length) ?
                    <div className="flex flex-row gap-6  mb-1  items-center">
                        {nodes.map((node, i)=>{
                        	return(
                        		<div key={i}>
            						<div 
							            className={
							            `	
							            	cursor-pointer  
							                server 
							                bg-grey 
							                block 
							                2xl:w-[270px]
							                w-[220px]
							                rounded-lg  
							                shadow-lg 
							                dark:bg-bgPagedark 
							                dark:text-darkTextColor
							                relative
							            `}
							            onClick={() => {
						                    if (!props?.creating) {
						                        
						                        if (props?.vps_params?.serviceId === node?.Id) {
						                            props.setVpsParams({...props?.vps_params, serviceId: null})
						                        } else {
						                            props.setVpsParams({...props?.vps_params, serviceId: node?.Id})
						                        }
						                    }
						                }} 
							        >
							        	{props?.vps_params?.serviceId === node?.Id &&
						                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
						                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
						                    </svg>
						                }

							            <div className="p-2">
							                <div className='flex justify-between items-start'>
							                    <div className='flex gap-2'>
							                        <img src={node?.isKubeMaster? controlPlane : nodeSelector} alt="" className='2xl:w-14 2xl:h-14 w-10 h-10' />
							                        <div>
							                            <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
							                                {node?.DisplayName}
							                            </span> 
							                            <br/>
							                            <span className="2xl:text-sm font-semibold inline-flex text-xs">
							                                {node?.OpenstackInstanceExtIp || node?.OpenstackInstanceLocalIp} 
							                            </span>
							                        </div>
							                    </div> 
							                </div>
							            </div>
							        </div>
            					</div>
                        	)
                        })}
                    </div>
                    :
                    null
                } 
            </div>
        </div>
	)
}

export function CustomIpDomaineInput(props){
	const {sourceIps, setSourceIp} = props

	return(
		<div>
			<Select2orMore isForCustom/>
			<div className="mt-3 pb-5 w-full xl:w-[1000px] mx-auto">
	            <div className="mt-3 rounded-xl  border border-gray-300 dark:border-gray-800 p-5">
					<div className="flex flex-col w-[380px]">
						{sourceIps && sourceIps.map((d, i) => (
							<div key={i} className={`${d.id !== 1 ? "pt-2" : ""} relative flex gap-3`}>
								<InputText 
									key={i}
									name="serviceAdress" 
									required={true}
									placeholder={"mydomain"+(i+1) +".co or IP"+(i+1)}
									value={sourceIps[i]?.serviceAdress} 
									onChange={(ev)=>{
										sourceIps[i] = {...d, [ev.target.name]: ev.target.value}
										setSourceIp([...sourceIps])
									}} 
								/>
								{d.id > 2 ?
									<Button
										danger
										className="absolute left-[395px]  "
										icon={<MinusCircleOutlined className=""   />}
										onClick={()=>{
											setSourceIp([...sourceIps.filter(dest => dest.id !== d.id)])
										}}
									/>
									:
									null
								}
							</div>
						))}
						<div className="pt-5">
							<Button 
								onClick={() => {
									sourceIps.push({id: sourceIps.length + 1})
									setSourceIp([...sourceIps])
								}} 
								type="primary" 
								title={"Add new service to balance"} 
							>
								{"Add new line"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}