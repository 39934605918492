import compute from './compute'
import navbar from './core/navbar'
import sidebar from './core/sidebar'
import k8s from './k8s'
import network from './network'
import platform from './platform'
import security from './security'

export default{
    compute,
    platform,
    security,
    network,
    k8s,
    core: {
        sidebar,
        navbar,
        constants: {
            PASSWORD_LENGTH_ERROR: "Le nouveau mot de passe doit comporter au moins 6 caractères.",
            PASSWORD_CHARACTER_ERROR: "Le mot de passe comporte des caractères non autorisés.",
            STANDARD_ERROR_MESSAGE: "Une erreur s'est produite, veuillez réessayer ou contactez-nous !",
            PASSWORD_DONT_MATCH: "Les valeurs des mots de passe ne correspondent pas.",
            ACTION_DONE_MESSAGE: "Operation effectué!",
            INVALID_USERNAME: "Le nom d'utilisateur n'est pas valide !",
            INVALID_SERVICE_IMAGE: "Il semble que vous n'ayez pas choisi l'image de votre instance!",
            MISSING_REQUIRED_FIELD: "Tous les champs réquis n'ont pas été remplis",
            ERROR_MESSAGE_403: "Vous n'êtes pas autorisé à effectuer cette action.",
            SUBSCRIPTION_CANCELLED_MESSAGE: "Votre abonnement a été annulé !"
        }
    },
    serverInformations: "Informations du serveur",
    indentifyInstance: "Identifiez votre instance",
    indentifyInstanceHelpMessage: "Ne doit pas contenir d'espaces, de caractères spéciaux ou de symboles",
    indentifyInstancePlaceholder: "Nom d'utilisateur utilisé pour se connecter via SSH",
    serverPasswordLabel: "Mot de passe du serveur",
    serverPasswordpalceHolder: "Mot de passe utilisé pour se connecter via SSH",
    serverPasswordConfirmLabel: "Confirmation du mot de passe",
    serverPasswordConfirmPlaceholder: "Confirmez le mot de passe saisi ici",

    preview: {
      keyFeatures: "Principales fonctionnalités"
    },
    package: {
      month: "mois",
      hour: "heure"
    },
    fund: {
      title: "Ajouter des fonds",
      costCal: "CALCULATEUR DE COÛT",
      recurring: "Récurrent",
      perM: "/mois",
      custom: "Personnalisé ",
      getKsPoint: "Obtenez {{point}} KsPoints chaque mois",
    },
    deploy: {
      identifyYourInstance: "Identifiez votre instance",
      serverNameTooltip: "Ne doit pas contenir d'espaces, de caractères spéciaux ou de symboles",
      vpsMiniServNameTooltip: "Doit contenir uniquement des caractères alphanumériques et des barres de soulignement (_)",
      serverNameTooltip2: "Must contain only alphanumeric characters, hyphens, and/or underscores, and must not start or end with a hyphen or an underscore. Max 15 characters. Example: instance_01, instance-01.",
      identfyInstancePlaceholder: "Les ressources de votre instance doivent avoir des noms uniques",
      instanceUser: "Utilisateur du serveur",
      instanceUserPlaceholder: "Nom d'utilisateur utilisé pour se connecter via SSH",
      instancePassword: "Mot de passe du serveur",
      sshPasword: "Mot de passe utilisé pour se connecter via SSH",
      passwordConfirm: "Confirmation du mot de passe",
      passwordConfirmPlaceholder: "Confirmation du mot de passe",
      datacenterLocationTitleSection: "Choisissez l'emplacement de votre service",
      pickInstanceImageSectionTitle: "Choisissez votre image d'instance",
      serverInformationSectiontitle: "Informations sur le serveur",
      sslEmail: "E-mail pour SSL",
      validEmailAddrCERT: "Adresse e-mail valide pour obtenir des certificats",
      chooseAVpcType: "Choisissez votre type de VPC",
      choseDatacenterLocationRegion: "Choisissez la région de l'emplacement de votre centre de données",
      datcenterLocationregDesc: "Les réseaux VPC ne peuvent contenir que des ressources situées dans la même région du centre de données",
      noGateway: "Pas de passerelle",
      recommended: "Recommandé",
      extGateway: "Passerelle externe",
      subnetSpec: "Vous devrez spécifier une adresse IP ou une taille de sous-réseau",
      nameAndDesc: "Choisissez un nom et une description",
      nameAndDescLabel: "Ajoutez un nom pour trouver votre VPC plus facilement",
      vpcName: "Nom",
      vpcNamePlaceholder: "Saisissez le nom de votre VPC ici",
      vpcDescription: "Description",
      vpcDescriptionPlaceholder: "Les ressources de votre instance doivent avoir des noms uniques",
      addNewNode: "Ajouter un nœud",
      addNewControlPlane: "Ajouter des contrôleurs",
      selectPackageSectionTitle: "Choisissez votre plan d'instance",
      deplayByAdminSectionTitle: "Choisissez votre plan d'instance",
      errorServiceName: 'La valeur "{{serviceName}}" est incorrecte. Elle doit contenir uniquement des caractères alphanumériques, des underscores ou des tirets et ne pas dépasser 15 caractères. Exemple : server_01, my-serv_02.',
      deployNewBtn: "Déployer nouveau",
      waitNotify: "Veuillez patienter entre 4 et 6 minutes pour voir votre nouveau KPS disponible",
      okUndestoodBtn: "Ok, compris",
      ______: "________",
      deployBottom: {
        summary: "Résumé",
        valueNotDefined: "(Non défini)",
        image: "Image",
        plan: "Plan",
        perMonth: "/mois",
        tAmount: "Montant total",
        comingSoon: "Bientôt disponible",
        deployNow: "Déployer maintenant",
        deploying: "Déploiement en cours",
        waitingForpaypal: "En attente de PayPal",
        location: "Emplacement",
        discount: "Remise",
        finalprice: "Prix final",
        perMonthWithTax: "/ mois (TPS + TVQ incluse)",
        promoCode: "Code promo",
        promoCodePlaceholder: "Veuillez saisir votre code promo",
        day7FreeThen: "7 jours d'essai gratuit {{then}}",
        then: ", puis",
        trialCodeLabel: "Code d'essai",
        trialCodePlaceholder: "Veuillez saisir votre code d'essai",
        ______: "________"
      },
      khks: {
        paypalLabel: "Abonnement Kubernetes",
        chooseClusterLocation: "Choisissez l'emplacement du cluster",
        clusterVersion: "Choisissez votre version de cluster",
        chooseControlPlanPlan: "Choisissez votre plan de contrôleur de cluster {{padStart}}",
        chooseNodePlan: "Choisissez votre plan de nœud {{padStart}}",
        clusternInfo: "Information du cluster",
        identifyCuster: "Identifiez votre cluster",
        identifyClusterTootip: "Ne doit pas contenir d'espaces, de caractères spéciaux ou de symboles",
        idClusterPaceHolder: "Les ressources du cluster doivent avoir des noms uniques",
        selecteAdditionOption: "Sélectionner des options supplémentaires",
        haRecommend: "Nous recommandons la haute disponibilité pour éliminer un point de défaillance unique et augmenter la tranquillité d'esprit pour les charges de travail de production.",
        getExtra: "Obtenir une fiabilité supplémentaire pour les charges de travail critiques",
        haLongTxt: 'Un <span className="text-blue-500 inline-flex items-center"> plan de contrôleur de cluster à haute disponibilité </span> crée plusieurs réplicas de composants de plan de contrôle pour maximiser l\'accès au cluster et le temps de disponibilité avec un SLA de 99,99%.',
        identifyHACuster: "Identifiez votre cluster HA",
        addHA: "Ajouter la haute disponibilité (HA)",
        chooseClusterHALocation: "Choisissez l'emplacement du cluster HA {{number}}",
        hahClusterInfo: "Information du cluster HA {{number}}",
        haNodenamePlacholder: "Les ressources du cluster doivent avoir des noms uniques",
        haNodenameTooltip: "Ne doit pas contenir d'espaces, de caractères spéciaux ou de symboles",
        addHaCluster: "Ajouter un autre cluster HA",
        deleteHACluster: "Enlever le cluster HA précédent",
        chooseYourInstancePlan: "Choisissez votre plan de nœud",
        idYourNode: "Identifiez votre nœud",
        resUNiqueName: "Les ressources du cluster doivent avoir des noms uniques",
        resUNiqueNameTooltip: "Ne doit pas contenir d'espaces, de caractères spéciaux ou de symboles",
        addNewNode: "Ajouter un nœud",
        addControlPlane: "Ajouter des contrôleurs",
        totalControlPlanShouldOdd: "Le total des plans de contrôleur doit être un nombre impair.",
        totalHHAShouldOdd: "Le total des plans de contrôleur des clusters HA ne doit pas être un nombre impair.",
        totalALl: "Le total de tous les plans de contrôleur, y compris les clusters HA, doit être un nombre impair.",
        nameDplucated: "Le nom '"+"{{string}} "+"' est dupliqué.",
        nodePlanDontPicked: "Le plan de nœud {{zeroPad}} du cluster principal n'a pas été sélectionné",
        masterPlanDontPicked: "Le plan de contrôleur {{zeroPad}} du cluster principal n'a pas été sélectionné",
        least2HA: "Vous devez ajouter au moins 2 plans de contrôleurs pour un cluster HA",
        locationClustedidntPicked: "L'emplacement du cluster HA {{zeroPad}} n'a pas été sélectionné.",
        nodeOfHAClusterDidntPicked: "Le plan de nœud {{y}} du cluster HA {{zeroPad}} n'a pas été sélectionné.",
        controkPlanOfHAClusterDidntPicked: "Le plan de contrôleur {{y}} du cluster HA {{zeroPad}} n'a pas été sélectionné.",
        kubeVersionNotSelected: "La version de Kubernetes n'est pas sélectionnée",
        applypromoCode: "Vous devez appliquer le code promo !",
        locationDidSelect: "L'emplacement du service n'est pas sélectionné",
        trialCodeInvalid: "Le code d'essai n'est pas valide !",
        haClusteLeast2controlPlane: "Les clusters HA doivent avoir au moins 2 plans de contrôleur.",
        khksServiceCreated: "Cluster K8S créé",
        ______: "________",
        ______: "________",
    }
    },
    deleteModal: {
      title: "Voulez-vous confirmer ?",
      warning: "Attention",
      warContent1: "Ce processus supprimera automatiquement ce service.",
      warContent2: "D'autres ressources qui dépendent de ce service pourraient être affectées.",
      cancelBtn: "Non, j'annuler",
      yesBtn: "Oui, je confirme",
      ____: "__________",
    },
    rebootModal: {
      title: "Redémarrer cette instance ?",
      warning: "Attention",
      warContent1: "Ce processus rendra tout site Web ou service sur votre instance temporairement indisponible.",
      warContent2: "Voulez-vous vraiment redémarrer votre instance ?",
      cancelBtn: "Non, annuler",
      yesBtn: "Oui, redémarrer",
      doneMessage: "Serveur {{DisplayName}} redémarré",
      ____: "__________",
    },
    stopModal: {
      title: "Arrêter cette instance ?",
      warning: "Attention",
      warContent1: "Ce processus rendra tout site Web ou service sur votre instance indisponible jusqu'à ce que vous le redémarriez.",
      warContent2: "Les instances arrêtées continuent à entraîner des frais.",
      warContent3: "Supprimez vos instances pour arrêter les frais.",
      warContent4: "Voulez-vous vraiment arrêter votre instance ?",
      cancelBtn: "Non, annuler",
      yesBtn: "Oui, arrêter",
      doneMessage: "Serveur {{DisplayName}} arrêté",
      ____: "__________",
    },
    rebuildModal: {
      title: "Reconstruire cette instance ?",
      warning: "Attention",
      warContent1: "Ce processus rendra tout site Web ou service sur votre instance indisponible.",
      warContent2: "Ce processus supprimera automatiquement cette instance, y compris toutes ses données, et la reconstruira.",
      warContent3: "Voulez-vous vraiment reconstruire votre instance ?",
      cancelBtn: "Non, annuler",
      yesBtn: "Oui, reconstruire",
      ____: "__________",
    },
    startModal: {
      title: "Démarrer cette instance ?",
      warning: "Attention",
      warContent1: "Voulez-vous vraiment démarrer votre instance ?",
      warContent2: "__________",
      cancelBtn: "Non, annuler",
      doneMessage: "Serveur {{DisplayName}} démarré",
      yesBtn: "Oui, démarrer",
      ____: "__________",
    },
    blueprintModal: {
      warning: "Attention",
      warContent1: "Ne pas interrompre le processus d'installation et suivre les instructions données.",
      warContent2: "Veuillez noter tous les noms d'utilisateur, mots de passe et/ou ports à ouvrir à la fin de l'installation.",
      warContent3: "D'autres ressources qui dépendent de cette instance pourraient être affectées.",
      cancelBtn: "J'annule",
      yesBtn: "J'installe",
      okeyBtn: "OK",
      dbUsrPass: "Mot de passe de l'utilisateur de la base de données",
      dbRootPass: "Mot de passe root de la base de données",
      pleaseConfirmInstallItem: "Veuillez confirmer l'installation de  {{item}}",
      pleaeKeepTheAppCredentialSecure: "Veuillez conserver les informations d'identification de l'application en toute sécurité",
      blueDeployed: "Le plan a été déployé avec succès",
      ____: "__________",
    },
    support: {
      docs: {
          cartTitle: "Docs",
          cartDesc: "Parcourez notre documentation pour obtenir des conseils techniques ou d'utilisation",
          link: "Aller aux docs",
      },
      blog: {
        cartTitle: "Blog",
        cartDesc: "Lisez nos articles de blog pour être à jour sur les produits et les actualités de KeepSec",
        link: "Aller au blog",
      },
      chat: {
          cartTitle: "Chat en direct",
          cartDesc: "Recevoir du support de KeepSec à travers notre chat en direct",
          link: "Démarrer un chat",
      },
      ____: "__________",
  }
}
