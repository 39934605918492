import { Divider } from 'antd'

import down from '../assets/img/arrow-down.png'
import up from '../assets/img/arrow-up.png'

export default function VpsImage(props){
    
    if(props.image && props.image.name.includes('rescue')){
        // return
    }
	return (
        <div className=''>  
            <div  className={
                    ((props?.vps_params.image && props.image.list.find(img => img.name.toLowerCase() === props?.vps_params.image.toLowerCase())  )? " border-primary " : " border-neutral-100 ") +
                    'border-2 w-48 xl:w-52 2xl:w-64 relative dark:bg-bgPagedark  mt-5  cursor-pointer rounded-xl ' +
                    ((props.selectedImage == props.image.id) ? "hover:bg-white  hover:shadow-2xl " : '') + "" + ((props.clickedImage == props.image.id) ? "bg-white shadow-xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')
                }
            >
                
                {props.noOption && (props.vps_params.image == props.image.name) &&
                    <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                } 

                <div className={'flex px-5 py-3 items-center '+(props.noOption? 'gap-6' : 'justify-between') }
                    onClick={() => {
                        if (!props.creating) {
                            if(props.clickedImage == props.image.id ){
                                props.setClickedImage(null);
                                if(props.noOption)props.setVpsParams({ ...props.vps_params, image: null})
                                
                            }else{
                                props.setClickedImage(props.image.id);
                                if(props.noOption) props.setVpsParams({ ...props.vps_params, image: props.image.name })
                            }
                        }
                    }} onMouseOver={() => props.setSelectedImage(props.image.id)}
                >
                    <img src={props.uri} alt="" className='2xl:w-10 2xl:h-10 w-6 h-6' />
                    
                    <p className='text-primary dark:text-darkTextColor font-semibold text-base 2xl:text-xl'>
                        {
                            (props?.vps_params.image && props.image.list.find(img => img.name.toLowerCase() === props?.vps_params.image.toLowerCase())  ) ?
                                props?.vps_params.image.split(' ')[1]
                            :
                                props.image.name

                        }
                    </p>
                    
                    {!props.noOption && <img src={props.clickedImage != props.image.id ? down : up} alt="arrow" className='w-6' />}
                </div>

                {!props.noOption && (props.clickedImage == props.image.id) &&
                    <div className='absolute z-[1] '> 
                        {props.image.list.map((img, i) =>{
                            return(
                                <div onClick={() => {
                                    if (!props.creating) {
                                        if(props.vps_params.image == img.name ){
                                            props.setVpsParams({ ...props.vps_params, image: null})
                                        }else{
                                            props.setVpsParams({ ...props.vps_params, image: img.name })
                                            props.setClickedImage(null);
                                        }
                                    }
                                }}  
                                key={i}
                                className={`
                                    w-48 xl:w-52 2xl:w-64 
                                    hover:bg-white  hover:shadow-2xl 
                                    bg-white shadow-xl dark:bg-bgPagedark  
                                    cursor-pointer  hover:bg-gray-200
                                    ${i===0 ? " rounded-t-xl " : ""}
                                    ${props.image.list?.length === i+1 ? " rounded-b-xl " : ""}
                                    `
                                    }
                                >

                                    {(props.vps_params.image == img.name) &&
                                        <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                            <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>
                                    } 

                                    <div className='flex px-5 py-3 items-center'>
                                        <img src={props.uri} alt="" className='2xl:w-7 2xl:h-7 w-5 h-5' />
                                        &nbsp;&nbsp;
                                        <p className='text-primary dark:text-darkTextColor font-semibold 2xl:text-xl text-base'>
                                            {img.name.includes('Almalinux') ? "AlmaLinux "+img.name.split(' ')[1] : img.name}
                                        </p>
                                    </div>
                                </div> 
                            )
                        }) }                    
                    </div> 
                }
            </div> 

            
        </div>                  
	)
}