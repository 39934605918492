export default{
    khks: {
        deploy: {
            addNewNode: "Ajout nœud",
            addNewControlPlane: "Ajout contrôleur",
        },
    },
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
    _____: "_________",
}