import { RocketOutlined } from '@ant-design/icons'
import { Button, Col, Input, message, Row, Tag, Tooltip } from 'antd'
import deepEqual from 'deep-equal'
import { useEffect, useState } from 'react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CAPTAIN from '../../../../lib/libCaprover';
import Utils from '../../../../utils';
import AppBuildLog from './AppBuildLog'
import AppLogsView from './AppLogsView'
import { GitRepoForm, TarUploader, UploaderPlainTextBase } from './UploadTextBase';

const formatText = (
	inputString,
	delimiters,
	replacements
) => {
	const delimiterRegex = new RegExp(`(${delimiters.join('|')})`, 'g')
	const parts = inputString.split(delimiterRegex)

	const retArray = parts.map((part, index) => {
		let indexFound = delimiters.indexOf(part)

		if (indexFound === -1) return <span key={index}>{part}</span>

		return React.cloneElement(replacements[indexFound], { key: index })
	})

	return <span>{retArray}</span>
}
export default function Deployment(props){
    const {t} = useTranslation()
    const [isAppBuilding, setIsBuilding] = useState(false)
    const [buildLogs, setBuildLogs] = useState('')
    const [lastLineNumberPrinted, setLastLineNumberPrinted] = useState(-10000)
    const [appLogs, setAppLogs] = useState("")
	const {messageApi} = useSelector(app => app.core )
	const app = props?.apiData?.appDefinition
    const rootDomain = props?.apiData?.rootDomain

    let willUnmountSoon = false

	
	function fetchLogs(app2) {
		const app3 = app2 || app

		// See https://docs.docker.com/engine/api/v1.30/#operation/ContainerAttach for logs headers
		const separators = [
			'00000000',
			'01000000',
			'02000000',
			'03000000', // This is not in the Docker docs, but can actually happen when the log stream is broken https://github.com/caprover/caprover/issues/366
		]
		const ansiRegex = Utils.getAnsiColorRegex()
		CAPTAIN
			.fetchAppLogsInHex(app3.appName)
			.then(function (logInfo) {
				const logsProcessed = logInfo?.data.logs
					.split(new RegExp(separators.join('|'), 'g'))
					.map((rawRow) => {
						let time = 0

						let textUtf8 = Utils.convertHexStringToUtf8(rawRow)

						try {
							time = new Date(textUtf8.substring(0, 30)).getTime()
						} catch (err) {
							// ignore... it's just a failure in fetching logs. Ignore to avoid additional noise in console
						}

						return {
							text: textUtf8,
							time: time,
						}
					})
					.sort((a, b) =>
						a.time > b.time ? 1 : b.time > a.time ? -1 : 0
					)
					.map((a) => {
						return a.text
					})
					.join('')
					.replace(ansiRegex, '')

				if (logsProcessed === appLogs) {
					return
				}

				const firstLogs = !appLogs

				let textareaNow = document.getElementById('applogs-text-id')
				// Almost at the bottom. So keep the scroll at the bottom. Otherwise, user, may have manually scrolled up. Respect the user!
				const shouldScrollToBottom =
					firstLogs ||
					(!!textareaNow &&
						Math.abs(
							textareaNow.scrollTop -
								(textareaNow.scrollHeight -
									textareaNow.offsetHeight)
						) < 100)
                setAppLogs(logsProcessed);

                setTimeout(() => {
                    let textarea = document.getElementById('applogs-text-id');
                    if (!textarea) return;
                
                    // Check if the user is still near the bottom *after* logs have been updated
                    const isNearBottom = Math.abs(
                        textarea.scrollTop - (textarea.scrollHeight - textarea.offsetHeight)
                    ) < 100;
                
                    if (isNearBottom) {
                        textarea.scrollTop = textarea.scrollHeight;
                    }
                }, 100);                        
			})
			.catch(function (error) {
				console.log("-----------------------",error)
				setAppLogs('fetching app log failed...')
			})
			.then(function () {
				setTimeout(() => {
					if (!willUnmountSoon) {
						fetchLogs()
					}
				}, 5000) // Just a random number to avoid hitting at the same time as build log fetch!
			})
	}


	function fetchBuildLogs(app2) {
		const app3 = app2 || app
		CAPTAIN
			.fetchBuildLogs(app3.appName)
			.then(function (logInfo) {
				logInfo = logInfo.data
				if (isAppBuilding && !logInfo.isAppBuilding) {
					// App was building but not anymore
					// onAppBuildFinished()
				}

				setIsBuilding(logInfo.isAppBuilding)
				
				let lines = logInfo.logs.lines
				let firstLineNumberOfLogs = logInfo.logs.firstLineNumber
				let firstLinesToPrint = 0
				if (firstLineNumberOfLogs > lastLineNumberPrinted) {
					if (firstLineNumberOfLogs < 0) {
						// This is the very first fetch, probably firstLineNumberOfLogs is around -50
						firstLinesToPrint = -firstLineNumberOfLogs
					} else {
						setBuildLogs( `${buildLogs}[[ TRUNCATED ]]\n`)
						
					}
				} else {
					firstLinesToPrint =
						lastLineNumberPrinted - firstLineNumberOfLogs
				}
				setLastLineNumberPrinted(firstLineNumberOfLogs + lines.length)

				let lineAdded = false

				let buildLogs2 = buildLogs
				const ansiRegex = Utils.getAnsiColorRegex()
				for (let i = firstLinesToPrint; i < lines.length; i++) {
					const newLine = (lines[i] || '')
						.trim()
						.replace(ansiRegex, '')
					buildLogs2 += newLine + '\n'

					lineAdded = true
				}
				setBuildLogs(buildLogs2)

                if (lineAdded) {
                    setTimeout(function () {
                        let textarea = document.getElementById('buildlog-text-id');
                        if (!textarea) return;
                
                        // Check if the user was near the bottom before adding new lines
                        const isNearBottom = Math.abs(
                            textarea.scrollTop - (textarea.scrollHeight - textarea.offsetHeight)
                        ) < 100;
                
                        if (isNearBottom) {
                            textarea.scrollTop = textarea.scrollHeight;
                        }
                    }, 100);
                }                
			})
			.catch(
				(er)=>console.log('error fetching build logs', er)
			)
	}

	// function onAppBuildFinished(app) {
    //     CAPTAIN
    //         .getAllApps()
    //         .then(function (data) {
    //             data = data?.data
    //             const appDefs = data.appDefinitions
    //             for (let index = 0; index < appDefs.length; index++) {
    //                 const element = appDefs[index]
    //                 if (
    //                     element.appName == appName
    //                 ) {
    //                     return Utils.copyObject(element)
    //                 }
    //             }
    //             throw new Error('App not found!')
    //         })
    //         .then(function (app) {
    //             setUpdateVersion({
    //                 deployedVersion: app?.deployedVersion,
    //                 versions: app?.versions,
    //             })
    //         })
    //         .catch((err)=>console.log('Could not fetch app build infos', err))
    // }

	useEffect(() => {
        let fetchBuildLogsInterval  = null
		if(props?.apiData?.appDefinition){
			fetchBuildLogsInterval = setInterval(function () {
				fetchBuildLogs(props?.apiData?.appDefinition)
				fetchLogs(props?.apiData?.appDefinition)
			}, 2000)
		}
        return () => {
			willUnmountSoon = true
            if (fetchBuildLogsInterval) {
                clearInterval(fetchBuildLogsInterval)
            }
        } 
    // }, [])
    }, [props?.apiData?.appDefinition])
	// 

	const [initRepoInfo, setInitRepoInfo] = useState(null)
	const [loadingForceRebuild, setLoadingForceRebuild] = useState(false)
	useEffect(() => {
        if(!initRepoInfo && app){
			const initRepoInfo = appPushWebhook
            ? { ...appPushWebhook.repoInfo }
            : {
                  user: '',
                  password: '',
                  branch: '',
                  sshKey: '',
                  repo: '',
            }
			setInitRepoInfo(initRepoInfo)
		}
    }, [app])

	const [buildLogRecreationId, setBuildLogsRecreatedId] = useState(null)
	const [forceEditableCaptainDefinitionPath, setForceEditableCaptainDefinitionPath] = useState(false)
	const onUploadSuccess = () => {
        messageApi.info(t('platform.configPage.deploymentTab.buildStarted'))
		setBuildLogsRecreatedId(`${new Date().getTime()}`)
        // DomUtils.scrollToTopBar()
    }

	const isMobile = false
	const hasPushToken = app?.appPushWebhook?.pushWebhookToken
	const repoInfo = app?.appPushWebhook
		? app.appPushWebhook?.repoInfo
		: {
			user: '',
			password: '',
			branch: '',
			sshKey: '',
			repo: '',
		}

	const webhookPushUrlRelativePath = hasPushToken
		? `/user/apps/webhooks/triggerbuild?namespace=captain&token=${
				app.appPushWebhook?.pushWebhookToken
			}`
		: ''
	//${props.apiData?.captainSubDomain}
	const webhookPushUrlFullPath = `${window.location.protocol}//captain.${props.apiData?.rootDomain}/api/v2${webhookPushUrlRelativePath}`
	const appPushWebhook  = props?.apiData?.appDefinition?.appPushWebhook
    

	
    return(
    	<div className=" ">
        	<AppLogsView 
                app={app} 
                rootDomain={rootDomain} 
                //lastLineNumberPrinted={props.lastLineNumberPrinted} 
                appLogs={appLogs} 
            />
        	<AppBuildLog 
        		app={app} 
        		isAppBuilding={isAppBuilding} 
        		//lastLineNumberPrinted={props.lastLineNumberPrinted} 
        		buildLogs={buildLogs} 
        	/>
			<div>
			<div style={{ height: 40 }} />
                {/* <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    Method 1: Official CLI
                </h4>
				<div className='pb-2'>
					{formatText(
						
							'Use CLI deploy command. This is the easiest method as it only requires a simple command like %s. Read more about it in the docs'
						,
						['%s'],
						[<Tag color="error">KeepSec deploy</Tag>]
					)}{' '}
					<a href={"https://caprover.com/docs/get-started.html#step-4-deploy-the-test-app"} target="_blank" rel="noopener noreferrer">
						{'(here)'}
					</a>
					.{' '}
					If you're using CI/CD to run<Tag color="error">KeepSec deploy</Tag> and you do not wish to use your password, you can use app-specific tokens
					{' '}
					<a href={"https://caprover.com/docs/ci-cd-integration.html#app-tokens"} target="_blank" rel="noopener noreferrer">
						{'(here)'}
					</a>
					.
				</div>
                <Row
                    justify="start"
                    style={{ marginTop: isMobile ? 15 : 0 }}
                >
                    <Col flex="0">
                        <Button
                            style={{
                                margin: 5,
                            }}
							loading={props.loading}
                            block={isMobile}
                            onClick={() => {
                                const newApiData = Utils.copyObject(
                                    props?.apiData
                                )
                                let tokenConfig =
                                    newApiData?.appDefinition?.appDeployTokenConfig
                                if (!tokenConfig) {
                                    tokenConfig = {
                                        enabled: false,
                                    }
                                }
                                tokenConfig.enabled = !tokenConfig.enabled
                                newApiData.appDefinition.appDeployTokenConfig =
                                    tokenConfig
                                props.updateApiData(newApiData)
                                // This is a hack! Find a better way!
                                // We need this delay, otherwise the new state will not be used by onUpdateConfigAndSave
                                setTimeout(
                                    ()=>props.onUpdateConfigAndSave(newApiData),
                                    100
                                )
                            }}
                        >
                            {app?.appDeployTokenConfig?.enabled
                                ?  'Disable App Token'
                                  
                                : 
                                      'Enable App Token'
                                  }
                        </Button>
                    </Col>{' '}
                    <Col flex="auto">
                        <Input
                            onFocus={(e) => {
                                if (
                                    !!app?.appDeployTokenConfig?.appDeployToken
                                ) {
                                    e.target.select()
                                    document.execCommand('copy')
                                    messageApi.info('Copied to clipboard!' )
                                    
                                }
                            }}
                            style={{
                                margin: 5,
                            }}
                            className="code-input"
                            readOnly={true}
                            disabled={!app?.appDeployTokenConfig?.appDeployToken}
                            value={
                                app?.appDeployTokenConfig?.enabled
                                    ? app?.appDeployTokenConfig?.appDeployToken
                                    : '** ' + 'Enable App Token to generate a random app token' + ' **'
                            }
                        />
                    </Col>
                </Row>
				<div style={{ height: 20 }} /> */}

				<div style={{ height: 20 }} />
                <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    {t('platform.configPage.deploymentTab.method1')}
                </h4>
                <UploaderPlainTextBase
                    appName={app?.appName}
					type={"imageName"}
                    onUploadSucceeded={() => onUploadSuccess()}
                />
				
				<div style={{ height: 20 }} />
                <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    {t('platform.configPage.deploymentTab.method2')}
                </h4>
                <UploaderPlainTextBase
                    appName={app?.appName}
                    onUploadSucceeded={() => onUploadSuccess()}
					type={"dockerfile"}
                />
                <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    {t('platform.configPage.deploymentTab.method3')}
                </h4>
                <p className='pb-2'>
                    {formatText(
                        t('platform.configPage.deploymentTab.method3_1'),
                        ['%s'],
                        [<Tag color="magenta">.tar</Tag>]
                    )}
                </p>

                <TarUploader
                    appName={app?.appName}
                    onUploadSucceeded={() => onUploadSuccess()}
                />


				<div style={{ height: 40 }} />
                <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    {t('platform.configPage.deploymentTab.method4')}
                </h4>
                <p>
                    {t('platform.configPage.deploymentTab.method4_1')}
                    <br />
                </p>
                <Row>
                    <Input
                        onFocus={(e) => {
                            if (hasPushToken) {
                                e.target.select()
                                document.execCommand('copy')
                                message.info(
                                    t('platform.configPage.deploymentTab.copy')
                                )
                            }
                        }}
                        className="code-input"
                        readOnly={true}
                        disabled={!hasPushToken}
                        value={
                            hasPushToken
                                ? webhookPushUrlFullPath
                                : '** ' +
                                t('platform.configPage.deploymentTab.method4_2')
                                +
                                ' **'
                        }
                    />
                </Row>
                <br />
				{app ?
					<GitRepoForm
						gitRepoValues={repoInfo}
						updateRepoInfo={(newRepo) => {
							const newApiData = Utils.copyObject(props.apiData)
							if (newApiData.appDefinition.appPushWebhook) {
								newApiData.appDefinition.appPushWebhook.repoInfo = Utils.copyObject(newRepo)
							} else {
								newApiData.appDefinition.appPushWebhook = {
									repoInfo: Utils.copyObject(newRepo),
								}
							}
							props.updateApiData(newApiData)
						}}
					/>
					: 
					null
				}
                <Row
                    justify="end"
                    style={{ marginTop: props.isMobile ? 15 : 0 }}
                >
                    <Button
                        disabled={!hasPushToken}
                        type={hasPushToken ? "primary" : "default"}
                        style={{
                            marginInlineEnd: props.isMobile ? 0 : 10,
                        }}
                        block={isMobile}
						loading={loadingForceRebuild}
                        onClick={() => {
							const globalData = {
								path: webhookPushUrlRelativePath,
								data: {}
							}
							setLoadingForceRebuild(true)
							return CAPTAIN.globalPoster(globalData).then((res)=>{
								console.log('Result globalPoster REPO ', res)
								onUploadSuccess()
								setLoadingForceRebuild(false)
							}).catch((err)=>{
								console.error('Error webhookPushUrlRelativePath ', err)
								setLoadingForceRebuild(false)
							})
                        }}
                    >
                        {t('platform.configPage.deploymentTab.forceBuild')}
                    </Button>
                    <Button
						disabled={deepEqual(repoInfo, initRepoInfo)}
                        type={!deepEqual(repoInfo, initRepoInfo) ? "primary" : "default"}
                        style={{ marginTop: isMobile ? 15 : 0}}
                        block={isMobile}
						loading={props.loading}
                        onClick={async () =>{
							const res = await props.onUpdateConfigAndSave()
							if(res && res?.status === 100){
								messageApi.success(res?.description || "New config has been saved successfully.")
							}
						}}
                    >
                        {t('platform.configPage.deploymentTab.saveRestart')}
                    </Button>
                </Row>
				
				

				{/* <div style={{ height: 20 }} />
                <h4 className='font-bold text-primary'>
                    <RocketOutlined />
                    {
                        'Method 5: Deploy captain-definition file'
                    }
                </h4>
                <UploaderPlainTextBase
                    appName={app?.appName}
                    onUploadSucceeded={() => onUploadSuccess()}
					type={"appDefinition"}
                /> */}
                

				<div style={{ height: 20 }} />
                {/* <Row>
                    <Col 
                        xs={{ span: 24 }}
                        lg={{ span: 6 }}
                        style={{ minWidth: isMobile ? '100%' : 400 }}
                    >
                        {isMobile &&
                            'captain-definition path'
                        }
						{app ?
							<Tooltip
								title={'Edit only if you have placed your captain-definition file in a subdirectory of your project'}
							>
								<Input
									addonBefore={
										!isMobile &&'captain-definition path'
									}
									type="text"
									defaultValue={app?.captainDefinitionRelativeFilePath + ''}
									disabled={
										!forceEditableCaptainDefinitionPath
									}
									onChange={(e) => {
										const newApiData = Utils.copyObject(
											props.apiData
										)
										newApiData.appDefinition.captainDefinitionRelativeFilePath =
											e.target.value
										props.updateApiData(newApiData)
									}}
								/>
							</Tooltip>
							:
							null
						}
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <div
                            style={{
                                marginInlineStart: isMobile ? 0 : 24,
                                marginTop: isMobile ? 8 : 0,
                            }}
                        >
                            <Tooltip
                                title={
                                    "You shouldn't need to change this path unless you have a repository with multiple captain-definition files (mono repos). Read docs for captain definition before editing this"
                                }
                            >
                                <Button
                                    type="default"
                                    block={isMobile}
                                    disabled={
                                        forceEditableCaptainDefinitionPath
                                    }
                                    onClick={() =>
										setForceEditableCaptainDefinitionPath(true)
                                    }
                                >
                                    {
                                        'Edit'
                                    }
                                </Button>
                            </Tooltip>
                            <Button
                                style={{
                                    marginInlineStart: isMobile
                                        ? 0
                                        : 20,
                                    marginTop: isMobile ? 8 : 0,
                                }}
								loading={props.loading}
                                block={isMobile}
                                disabled={
                                    !forceEditableCaptainDefinitionPath
                                }
                                type={forceEditableCaptainDefinitionPath ? "primary" : "default"}
                                onClick={async () =>{
                                    const r = await props.onUpdateConfigAndSave()
									if(r?.status === 100) return messageApi.success(r?.description|| "Done !")
									return messageApi.error("An error occurred while saving") 
								}
                                }
                            >
                                {
                                    t('platform.configPage.deploymentTab.saveRestart')
                                }
                            </Button>
                        </div>
                    </Col>

                    <Col span={6} />
                </Row> */}
			</div>
        	<p>
        	</p>
        </div>
   	)
}    