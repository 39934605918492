import React from "react"
import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { FaComments } from "react-icons/fa";

import { ThemeContext } from '../../theme/ThemeContext';

export default function Chat() {
    const { t, i18n } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const lang = i18n.language || 'en';

    const openCrispChat = () => {
        setTimeout(() => {
            if (window.$crisp) {
                try {
                    // Open Crisp chat window
                    window.$crisp.push(['do', 'chat:open']);

                    // Set the initial message for the chat
                    const placeholder =
                        lang === 'fr'
                            ? 'Placeholder in French'
                            : 'Placeholder in English';

                    window.$crisp.push(['set', 'message:text', [placeholder]]);
                } catch (error) {
                    console.error('Error executing Crisp commands: ', error);
                }
            } else {
                console.error('Crisp is not initialized properly.');
            }
        }, 500); // Adjust the delay as needed
    };

    return (
        <div className='h-full '>
             <div className={"px-3 md:px-8 h-full  dark:text-darkTextColor pt-10  "+ (theme !== "dark" ? "  support-back " : "")}>
                <div className="container h-full mx-auto max-w-full">
                    <div className="block dark:bg-bgPagedark p-6 rounded-lg shadow-2xl bg-white max-w-sm text-center mx-auto">
                        <div>
                            <FaComments  className='text-5xl text-primary dark:text-darkTextColor mx-auto'/>
                        </div> 
                        <h5 className="text-primary dark:text-darkTextColor font-semibold text-xl leading-tight font-medium mb-2">
                        {t('support.chat.cartTitle')} 
                        </h5>
                        <p className="text-gray-700 text-base mb-4 dark:text-darkTextColor">
                        {t('support.chat.cartDesc')} 
                        </p>
                            <button
                                onClick={openCrispChat}
                                className="font-bold mx-auto lg:block px-4 py-1 text-sm text-gray-700 dark:text-darkTextColor transition duration-150 ease-in-out  bg-transparent  border dark:border-[3px] border-primary rounded focus:outline-none hover:text-white  hover:bg-primary hover:border-primary sm:px-8 sm:py-3"
                            >
                                 {t('support.chat.link')}
                            </button>
                    </div>
                </div>
                {/*<div className={'absolute bottom-0 w-screen left-0 support-back bg-no-repeat md:bg-repeat'} style={{ height: "400px", zIndex: -1 }}>
                </div>*/}
            </div>
        </div> 
    );
}
