import {  useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { 
        InputTextAdd, 
        LabelAdd, 
        AddServiceSectionTitle
} from '../../../components/Bloc';
import UserMgt from '../../../lib/user_managment';
import { useNavigate } from "react-router-dom";
import Utils from '../../../utils';
import { KsLbPack } from '../../../components/vps/Packages';
import { Tooltip } from 'antd';
import  DeployServerWrapper from '../../DeployServerWrapper';


export default function DeployKsIb(props) {
    const {isTrial, isPromo} = props
    const [heightBottomC, setBottomCalculedHeight] = useState(0)
    const { vps_packages } = useSelector(app => app.vps)
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: "KS-lb",
        // email: "",
        location: null,
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isAdminFreeDeployment: null
    })

 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }


    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
        return vps_packages
    }
 


    useEffect(() => {
        getVpsTypes().then(vps_packages => {
                if(Utils.getUrlParam('prefer')){
                    const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                    const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                    if(pack){
                        setClicked(parseInt(selectedIdVpsTypes));
                        onClaculFlavor(vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes)))

                    }
                    window.localStorage.removeItem('@user_first_service')
                }
        })
        
    }, [])


    
    async function functionFinish(){
        await UserMgt.get_user_vps()
        navigate('networking/kslb')
    }

    function validate(){
        if (!vps_params.vpsTypeId) {
            return {
                isFail: true,
                message: "Package not selected"
            }
        }
        if (!vps_params.location) {
            return {
                isFail: true,
                message: "Service location not selected"
            }
        }

        if (isPromo && !codeApplied) {
            return {
                isFail: true,
                message: "You should apply promo code !"
            }
        }
        return {
            isFail: false
        }
    }


    function onClaculFlavor(pack) {
        if(pack) setPack({...pack})
        else setPack(null)
        setVpsParams({ ...vps_params, vpsTypeId: pack?.Id,  plan_id: (isTrial? pack?.PaypalPackIdFree : pack?.PaypalPackId) })

    }

    //PaypalPackId
    const concertPack = vps_packages &&  
                        vps_packages.filter(p => p.service?.task_name === "kslb")
                        .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    return (
        <DeployServerWrapper
            paypal={paypal}
            packSelected={packSelected}
            isTrial={isTrial}
            isPromo={isPromo}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            handleChange={handleChange}
            setBottomCalculedHeight={setBottomCalculedHeight}
            isNetwork

            codeApplied={codeApplied}
            codeApplying={null}
            type={"KS-LB"}
            functionFinish={functionFinish}
            setPaypal={setPaypal}
            validate={validate}
            setCreating={setCreating}
            setCodeApplied={setCodeApplied}
            setCodeApplying={()=>{}}
        >   
            <div>
                <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                    <KsLbPack
                        concertPack={concertPack} 
                        setSelected={setSelected} 
                        creating={creating} 
                        clicked={clicked} 
                        setClicked={setClicked} 
                        onClaculFlavor={onClaculFlavor} 
                        setPackPaypal={()=>{}} 
                        selected={selected} 
                    />
                </div>

                <AddServiceSectionTitle
                    title="KS-LB Informations"
                />
                <div className={'w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10 '} style={{paddingBottom: heightBottomC+25}}>
                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="serv-name"
                            placeholder={"Identify your KS-LB instance"}
                        />
                        <Tooltip open={hoverServerName}   title="Must not contain spaces, special characters or symbols">
                            <InputTextAdd
                                idx="serv-name"
                                value={vps_params.server_name}
                                name="server_name"
                                pattern="[A-Za-z0-9]{1,}"
                                onChange={handleChange}
                                required
                                hover
                                onHover={(state) => setHoverToolTopServerName(state)}
                                disabled={creating}
                                placeholder='Your instance resources must have unique names'
                            />
                        </Tooltip>
                    </div>
                </div>
            </div> 
        </DeployServerWrapper>
    );
}



