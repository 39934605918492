export default{
    kwaf: {
        userDash: "Interface utilisateur",
        hostname: "Nom d'hôte public",
        collumName: "Nom",
        publicIp: "IP public",
        notDefined: "(Non défini)",
        privateIp: "IP privée",
        vpcIp:  'IP VPC',
        state: 'État',
        running: "En marche",
        down: "En arrêt",
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
    },
    vullify: {
        collumName: "Nom",
        publicIp: "IP public",
        notDefined: "(Non défini)",
        privateIp: "IP privée",
        vpcIp:  'IP VPC',
        running: "En marche",
        down: "En arrêt",
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
        _____: '______',
    }
}