export default{
    state: "State",
    running: "Running",
    down: "Stopped",
    start: "Start",
    stop: "Stop",
    reboot: "Reboot",
    publicHostname: "Public Hostname",
    privateHostname: "Private Hostname",
    notDefined: "Not defined",
    publicIp: "Public IP",
    ipVpc: "VPC IP",
    passwordUpdated: "Password updated.",

    location: "Location",
    openPorts: "Open Ports",
    port: "Port",
    openPortTooltips: "Ports will be only accessible by the domain. Want to use other ports? You can use NGROK, Cloudflare Tunnels or other alternatives.",
    //Today texto
    
    _______: "_______",
    vps: {
        tab1:{
            title: "Connect",
            h1: "Connect to your instances",
            desc: "You can connect using your browser or your own compatible SSH client",
            useBrowser: "Use your browser",
            usingWebBrowserText: "Connect using our browser-based SSH client",
            useOwn: "Use your own SSH client",
            connectToSSH: "Connect using an  SSH client",
            connectTo: "CONNECT TO",
            username: "USER NAME",
            newPass: "New password",
            enterNewpass: 'Enter new password',
            confirmNewPass: "New password confirmation",
            enterConfirmNewPass: 'Enter the new password confirmation',
            updatePassBtn: "Change password"

        },
        networkTab:{
            title: "Networking",
            h1: "IPv4 Networking",
            desc: "The IP address of your instance is accessible to the internet, the private IP address is accessible only to other ressources in your KeepSec  account.",
            publicIp: "PUBLIC IP",
            ipi4firewall: "IPv4 Firewall",
            createRuleOpenPort: "Create rules to open ports to the internet or to specify IPv4 addresses and ranges.",
            leanHowCreateRule: "Lean more about firewall rules",
            whatIs: "What is this for ?",
            needEnabled: "VPC needs to be enabled and have the server in the availability zone",
            vpcSubnet: "VPC SUBNET",
            vpcNeedEnabled: "VPC needs to be enabled",
            ipv4net: "IPv4 Dedicated Network",
            ipv4NetDesc: "This network will only be accessible to other VPS's added in your VPC availability zone.",
            enable: "Enable",
            leanMore: "Lean more",
            addRemoveVpc: "Add and remove VPS from your VPC network",
            zone: "Zone",
            search: "Input search ",
            myVps: "My VPS",
            addTozone: "Add {{serveName}}  to current zone",
            removeTozone: "Remove {{serveName}}  to current zone",
            az: "Availability Zone",
            addRule: "Add Rule",
            ipVpc: "VPC IP",
            ____: "______",
        },
        rebuildTab:{
            title: "Rebuild",
            h1: "Rebuild this instance",
            go: "Rebuild",
            pickTorebuild: "Choose your rebuild image"
        },
        upgradeTab:{
            title: "Upgrade",
            h1: "Upgrade",
            pickToUpgrade: "Choose your upgrade plan",
            noPlan: "No upgrade plan available !",
            upgradeNow: "Upgrade now",
            _____: "_______",
        },
        blueprint:{
            title: "Blueprint",
            h1: "Select a blueprint"

        },
        security:{
            title: "Security",
            vuln: {
                title: "Port Vulnerability Scan",
                desc: "Scan for potential vulnerabilities on the open ports of the server",
                scan: "Scan",
                title2: "Vulnerabilities",
                desc: "Note the device may not be impacted by all these issues, the vulnerabilities are implied based on the software and version.",
                noVuln: "No Vulnerability found on your server !",
                noInfo: "No informations available",
                _____: "_______",
            },
            port: {
                title: "Open Port",
                noPort: "No openned port on your server!",
                noInfo: "No informations available"
            },
            general: {
                title: "General Informations",
                hostname: "Hostname",
                general: "No hostname found !",
                noInfo: "No informations available",
            },
            tech:{
                title: "Web Technologies",
                noTech: "No Web Technologies found on your server !",
                noInfo: "No informations available"
            }
        },
        storageTab:{
            title: "Storage",
            h1: "Volume Blocks Storage",

        },
    },
    console: "Console",
    files: "Files",
    vpsmini: {
        overview: {
            title: "Overview",
            howtoConnectUsingFTP: "Connect using an FTP client",
            username: "Username",
            useOwnFTP: "Use your own FTP client",
            useOwnDomain: "Use your own domain",
            makeSureTypeAEnabled: "Please make sure that you have a type \"A\" DNS record \n pointing to",
            domainList: "Domain list",
            addNewDomain: "Add new domain",
            removeDomain: "Remove this domain",
            addFirstDomain: "Add your first domain",
            microBlueprint: "Micro Blueprint",
            cutomDomainAddInput: "My own domain here",
            cutomDomainSave: "Save",
            ______: "_________",
            ______: "_________",
        },
        changePassworw: {
            title: "Reset Password",
            h1: "Change password of this instance",
            newPassLabel: "New password",
            newPassPlaceHolder: "Enter new password",
            newPassConfirmLabel: "New password confirmation",
            newPassConfirmLabel: "Enter the new password confirmation",
            newPassConfirmLabelPlaceholder: "Enter the new password confirmation",
            changePassword: "Change password",
            ______: "_________",
        }
    }
}