export default{
    settingsPopup: {
        language: "Langue",
        browserDefault: "Défaut du navigateur",
        en: "English",
        fr: "Français",
        viewMode: "Mode d'affichage - bêta",
        light: "Clair",
        dark: "Sombre",
        more: "Plus de paramètres utilisateur",
        myAccount: "Mon compte",
        logout: "Déconnexion"
    }
}
