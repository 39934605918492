import { Button as AntdButton, message } from "antd"
import { useEffect, useState } from 'react';
import React from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from "react-i18next";
import { AiOutlineGlobal } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { FaDownload, FaNetworkWired } from "react-icons/fa";
import { MdWarning } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

import { ExtLink } from 'components/Bloc';
import { Texto } from "layouts/TextoLayout";
import { updateHeadLink } from "reducer/action/coreActions";
import { save_rebootable_service, save_stopable_service } from "reducer/action/coreActions";

import almalinux from '../../assets/img/almalinux.png'
import blueprint from '../../assets/img/blue/blueprint-KS.png'
import cloudpanel from '../../assets/img/blue/cloudpanel.png'
import cpanel from '../../assets/img/blue/cpanel-logo.png'
import cyberpanel from '../../assets/img/blue/cyberpanel-logo.png'
import docker from '../../assets/img/blue/docker-logo.png'
import gitlab from '../../assets/img/blue/gitlab.png'
import uptimeKuma from '../../assets/img/blue/uptime_kuma.png'
import joomla from '../../assets/img/blue/joomla-logo.png'
import LibreNMS from '../../assets/img/blue/LibreNMS-2-logo.png'
import mariadb from '../../assets/img/blue/mariadb.png'
import minecraftBedrock from '../../assets/img/blue/minecraft-bedrock-logo.png'
import minecraft from '../../assets/img/blue/minecraft-logo.png'
import mongodb from '../../assets/img/blue/mongodb.png'
import netmaker from '../../assets/img/blue/Netmaker-logo.png'
import nextcloud from '../../assets/img/blue/nextcloud-logo.png'
import nginxproxymanager from '../../assets/img/blue/nginxproxymanager.png'
import odoo from '../../assets/img/blue/odoo.png'
import openvpn from '../../assets/img/blue/openvpn.png'
import plesk from '../../assets/img/blue/plesk-logo.png'
import portainer from '../../assets/img/blue/portainer.png'
import pritunl_logo from '../../assets/img/blue/pritunl-logo.png'
import pufferpanel from '../../assets/img/blue/pufferpanel.png'
import traefik from '../../assets/img/blue/traefik.png'
import Webmin from '../../assets/img/blue/Webmin-logo.png'
import wordpress from '../../assets/img/blue/wordpress-logo.png'
import centos from '../../assets/img/centos.png'
import debian from '../../assets/img/debian.png'
// import docker from '../../assets/img/docker.png'
import linux from '../../assets/img/linux.png'
import ubuntu from '../../assets/img/ubuntu.png'
import { DeleteServiceTap, InputPassAdd, InputTextAdd, LabelAdd, NetworkRender, PageTitle, Spiner } from '../../components/Bloc';
import BlueprintCart from '../../components/BlueprintCart'
import { Toast } from '../../components/Notify';
import { OkButton } from "../../components/OkButton"
import { TabSelector } from "../../components/TabSelector";
import UserMgt from '../../lib/user_managment';
import VPSapi from '../../lib/vps_service';
import Utils from '../../utils';
import { NetworkingVps } from './manage/NetworkingVps'
import RebuildVPS from './manage/RebuildVPS'
import StorageVps from './manage/StorageVps'
import UpgradeVps from './manage/UpgradeVps'

const imgs = {
	ubuntu,
	debian,
	almalinux,
	docker,
	linux,
	centos
}
const tab = [
	'ubuntu',
	'debian',
	'almalinux',
	'docker',
	'linux',
	'centos'
]

const blueprints = [
	{name: "Cyberpanel", payload: "Cyberpanel", version: "Latest", img: cyberpanel},
	{name: "cPanel", payload: "cPanel", version: "Latest", img: cpanel},
	{name: "CloudPanel", payload: "CloudPanel", version: "Latest", img: cloudpanel},
	{name: "Plesk", payload: "Plesk", version: "Latest", img: plesk},
	{name: "Webmin", payload: "Webmin", version: "Latest", img: Webmin},
	{name: "NextCloud", payload: "NextCloud", version: "Latest", img: nextcloud},
	{name: "LibreNMS", payload: "LibreNMS", version: "Latest", img: LibreNMS},
	{name: "Netmaker", payload: "Netmaker", version: "Latest", img: netmaker},
	{name: "OpenVPN", payload: "OpenVPN", version: "Latest", img: openvpn},
	{name: "Wordpress", payload: "Wordpress", version: "Latest", img: wordpress},
	{name: "Joomla", payload: "Joomla", version: "Latest", img: joomla},
	{name: "Odoo", payload: "Odoo", version: "Latest", img: odoo},
	{name: "Minecraft Java", payload: "MinecraftJava", version: "Latest", img: minecraft},
	{name: "Minecraft Bedrock", payload: "MinecraftBedrock", version: "Latest", img: minecraftBedrock},
	{name: "Pufferpanel", payload: "Pufferpanel", version: "Latest", img: pufferpanel},
	{name: "Docker", payload: "Docker", version: "Latest", img: docker},
	{name: "Portainer", payload: "Portainer", version: "Latest", img: portainer},
	{name: "Nginx Proxy Manager", payload: "NginxProxyManager", version: "Latest", img: nginxproxymanager},
	{name: "Traefik Proxy", payload: "Traefik", version: "Latest", img: traefik},
	{name: "Pritunl", payload: "Pritunl", version: "Latest", img: pritunl_logo},
	{name: "Uptime Kuma", payload: "UptimeKuma", version: "Latest", img: uptimeKuma},
	{name: "GitLab", payload: "GitLab", version: "Latest", img: gitlab},
	{name: "MariaDB", payload: "MariaDB", version: "11.7.2", img: mariadb},
	{name: "MongoDB", payload: "MongoDB", version: "5.0.19", img: mongodb},
]


export default function ManageVps({ props }) {

	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage()
	const [server, setvps] = useState(null)
	const [onRun, setonRun] = useState({})
	const [errorScan, setError] = useState(false)

	const [updatingPassword, setUpdatingPassword] = useState(false)

	const [update, setUpdate] = useState({
		password: null,
		confirmation: null
	})

	const [scanObj, setScanresult] = useState(null)
	const [onGoingScan, setongoingscan] = useState(false)
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useTabs([
		"Connect",
		"Networking",
		"Delete",
		"Rebuild",
		"Upgrade",
		"Blueprint",
		"Security",
		"Storage",
	]);


	const handleChange = (ev) => {
        const { name, value } = ev.target
        return setUpdate({...update, [name]: value})
    }



	const onUpdatePassword = async (e) => {
		e.preventDefault();
        try {
			if(
				update.password && 
				(String(update.password).trim()).length >= 6 
			){
				if (Utils.testPassworsVps(update.password)) {
		            return messageApi.open({
		                type: 'error',
		                content: t("compute.validatPassword")
		            })
		        }
				if(update.password === update.confirmation){
					setUpdatingPassword(true)
					const data = {
						// action: "reset_password",
						password: update.password
					}
					const result = await UserMgt.onVpsAction("reset_password", server?.Id,  data)
					setUpdatingPassword(false)
					if(!result.error){
						messageApi.open({
							type: 'success',
							content: t("compute.passwordUpdated"),
						});
						await UserMgt.get_user_vps()
					}else{
						messageApi.open({
							type: 'error',
							content: t("core.constants.STANDARD_ERROR_MESSAGE"),
						});
					}
				}else{
					messageApi.open({
						type: 'error',
						content: t("core.constants.PASSWORD_DONT_MATCH"),
					});
					return
				}
			}else{
				messageApi.open({
					type: 'error',
					content: t("core.constants.PASSWORD_LENGTH_ERROR"),
				});
				return
			}
		} catch (error) {
			console.log('Update password error ', error)
		}
    }

	const loadVps = async () => {
		const vps = await UserMgt.getUniqueVps(id) //.user_vps
		if(!vps?.error){
			setvps(vps?.server)
			return vps?.server
		}else{
			messageApi.open({
				type: 'error',
				content: t("core.constants.STANDARD_ERROR_MESSAGE"),
			});
			return navigate(-1)
		}
	}
	async function onReboot() {
		dispatch(save_rebootable_service(server))
	}
	async function onStop() {
		return dispatch(save_stopable_service(server, "/"))
	}
	async function onStart() {
		setonRun({...onRun, start: true })
		const result = await  UserMgt.onVpsAction("start", server?.Id)
		console.log('result onStop', result)
		setonRun({...onRun,  start: null })
		if (result && !result.error) {
			await UserMgt.get_user_vps()
			Toast.fire({
				icon: 'success',
				title: "Server " + server.OpenstackInstanceName + " started" //result.details
			})
			return navigate(-1)
		} else {

			Toast.fire({
				icon: 'error',
				title: result?.message || "An error occured, if this persist, please tell us"
			})

		}
	}
	
	
	useEffect(() => { 

        let headLinks = [];
		loadVps().then((vps) => {
			if(vps && vps.IsMiniSec){
				headLinks = [
					{
						link: "/compute/vpssecmini",
						item: 'vpssecmini',
						alt: "VPSminisec short link"
					}
				]
				if(Utils.getUrlParam('from-connect')){
					headLinks.push({link: '/compute/vps/connect/'+vps.Id, label: "Connect", arrow: true})
				}
				headLinks.push({label: vps?.DisplayName, arrow: true})
				
			}else{

				headLinks = [
					{
						link: "/compute/vps",
						item: 'vps',
						alt: "VPS short link"
					},
					{label: vps?.DisplayName, arrow: true},
				]
			}
			dispatch(updateHeadLink(headLinks))
				
		})
		
		if (Utils.getUrlParam('act') == "up") {
			setSelectedTab('Upgrade')
		}
		if (Utils.getUrlParam('act') == "s") {
			setSelectedTab('Storage')
		}
		return () => dispatch(updateHeadLink([]))
	}, [])


	const getNetwork = async (serv) => {
		try {
			const result = await UserMgt.getNetwork(serv?.Id)
			console.log('result network', result)
			if(result?.network) setvps({...serv, network: result.network})
		} catch (error) {
			console.log('Error get network', error)
		}
	}
	const onScan = async () => {
		setongoingscan(true)
		setError(false)

		const result = await VPSapi.scan(server?.OpenstackInstanceExtIp)
		if (!result?.ip) {
			setError(true)
			setongoingscan(false)
			return;
		}
		setongoingscan(false)
		setScanresult(result)
		console.log("result result onscan", result)
	}

	

	async function onDelete() {
		Utils.dispatchToDelete(server, '/compute/vps')
	}





	return (
		<>
			<div className="dark:text-darkTextColor pb-10">
				{contextHolder}
				<div className="container mx-auto max-w-full px-3 md:px-8">
					{server &&
						<div>
							<div className="md:flex gap-6 items-center">
								<div className="md:w-1/12 md:flex hidden">
									<img src={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]} alt="" className='w-20 h-20' />
								</div>
								<div className="w-full md:w-11/12 ">
									<div>
										
										<div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
											<div>
												<img
													src={imgs[tab.find(k => server?.OpenstackInstanceImageName?.toLowerCase().includes(k))]}
													alt=""
													className="w-14 h-14 md:hidden"
												/>
												<span className="text-xl xl:text-2xl 2xl:text-3xl font-medium">
													{server?.DisplayName || server?.OpenstackInstanceName}
												</span> <br />
												<div className="text-sm 2xl:text-base">
													<span>{server.type?.OpenstackFlavorName}</span> <br />
													<span>{server?.location?.name || "Montreal, Zone A"}</span> <br />
												</div>
											</div>

											<div className="text-sm 2xl:text-base">
												<div className="flex flex-col md:flex-row gap-2 md:gap-4">
													{server?.VpsStatus === "down" ?<OkButton loanding={onRun['start']} click server={server} fun={onStart} title={t("compute.start")} /> : null}
													{server?.VpsStatus === "up" ?<OkButton loanding={onRun['stop']} click server={server} fun={onStop} title={t("compute.stop")} />  : null}
													<OkButton loanding={onRun['reboot']} click server={server} fun={onReboot} title={t("compute.reboot")} />
												</div>
												<p className="text-right pt-1">{t("compute.state")}:{" "} 
												<span className="font-bold">
													{server?.VpsStatus  === "up" ? t("compute.running") : t("compute.down")}
												</span>
												</p>
											</div>
										</div>
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2">

										<div className="text-left">
											<p className="pt-2 text-sm 2xl:text-base">
												{t("compute.publicHostname")}: <span className="font-bold text-primary">
													{server?.domain || (server.OpenstackInstanceName + (server?.IsMiniSec ? ".vpssecmini" :".vps" )+".keepsec.cloud")}
													</span> <br />
												{t("compute.privateHostname")}: <span className="font-bold  text-primary">
													{server.OpenstackInstanceName || "("+t("compute.notDefined"+")")}
												</span> <br />

											</p>
										</div>
										<div className="text-lef md:text-right text-sm 2xl:text-base">
											<p className=" pt-2">
											{t("compute.publicIp")}: <span className="font-bold">{server.OpenstackInstanceExtIp}</span> <br />
												{t("compute.ipVpc")}: 
												<span className="font-bold">
													<NetworkRender
														vpc={server?.vpc}
													/>
												</span> 
												<br />
												OS: <span className="font-bold">{server.OpenstackInstanceImageName}</span> <br />
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="">
								<nav className="flex w-full overflow-y-auto  border-b border-gray-300 dark:border-gray-800">
									<TabSelector
										isActive={selectedTab === "Connect"}
										onClick={() => setSelectedTab("Connect")}
									>
										{t("compute.vps.tab1.title")} 
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Networking"}
										onClick={() => setSelectedTab("Networking")}
									>
										{t("compute.vps.networkTab.title")}
									</TabSelector>
									<TabSelector
										isActive={selectedTab === "Rebuild"}
										onClick={() => setSelectedTab("Rebuild")}
									>
										{t("compute.vps.rebuildTab.title")}
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Upgrade"}
										onClick={() => setSelectedTab("Upgrade")}
									>
										{t("compute.vps.upgradeTab.title")}
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Blueprint"}
										onClick={() => setSelectedTab("Blueprint")}
									>
										{t("compute.vps.blueprint.title")}
									</TabSelector>

									<TabSelector
										isActive={selectedTab === "Security"}
										onClick={() => setSelectedTab("Security")}
									>
										{t("compute.vps.security.title")}
									</TabSelector>
									<TabSelector
										isActive={selectedTab === "Storage"}
										onClick={() => setSelectedTab("Storage")}
									>
										{t("compute.vps.storageTab.title")}
									</TabSelector>
									
									<TabSelector
										isActive={selectedTab === "Delete"}
										onClick={() => setSelectedTab("Delete")}
									>
										{t("deleteCompoent.title")}
									</TabSelector>


								</nav>

								<div className="pt-5">
									<TabPanel hidden={selectedTab !== "Connect"}>
										<div className="  flex justify-between flex-wrap gap-2 xl:gap-4 2xl:gap-6 w-full">
											<div className="w-full lg:w-1/2 border-b-2 md:border-b-0 pb-5">
												<div className="flex gap-2 items-center">
													<PageTitle text={t("compute.vps.tab1.h1")} />
													<BiHelpCircle className="text-blue-700 text-2xl" />
												</div>
												<Texto small className="pt-2">
													{t("compute.vps.tab1.desc")}
												</Texto>
												
												<Texto className="pt-4">
													{t("compute.vps.tab1.useBrowser")}
												</Texto>

												<Texto small className="pt-2">
													{t("compute.vps.tab1.usingWebBrowserText")}
												</Texto>
												<br />
												<br />
												<Link to={"/compute/vps/" + server.Id + "/console"}>
													<OkButton title={t("connectSshLink")} />
												</Link>

												<Texto className="pt-4">
													{t("compute.vps.tab1.useOwn")}
												</Texto>

												<br />
												<ExtLink 
													href={"#"}
													text={t("compute.vps.tab1.connectToSSH")}
												/>
												<div className="py-2 px-4 mt-5 border-2 rounded-md border-gray-300 w-full lg:w-2/3 shadow-lg">
													
													<Texto small className=" font-semibold">
														{t("compute.vps.tab1.connectTo")}
													</Texto>

													<br />
													<span className="text-base xl:text-xl 2xl:text-2xl font-bold">
														{server.OpenstackInstanceExtIp}
													</span>
													<br />
													<Texto small className="mt-3 font-semibold">
														{t("compute.vps.tab1.username")}
													</Texto>
													<br />
													<span className="text-2xl font-bold">
														{server.SshUsername || "("+t("compute.notDefined")+")"}
													</span>
												</div>
											</div>
											<form onSubmit={onUpdatePassword} className="w-full pb-10 lg:w-[400px] 2xl:w-[500px] pt-2 md:pt-0">
												<div className="">
													<LabelAdd
														htmlFor="password"
														toLowerCase
														placeholder={t("compute.vps.tab1.newPass")}
													/>
													<InputPassAdd
														idx="password"
														value={update.password}
														name="password"
														onChange={handleChange}
														required
														pattern={true}
														disabled={updatingPassword}
														placeholder={t("compute.vps.tab1.enterNewpass")}
													/>
												</div>
												<div>
													<LabelAdd
														toLowerCase
														htmlFor="confirmation"
														placeholder={t("compute.vps.tab1.confirmNewPass")}
													/>
													<InputTextAdd
														idx="confirmation"
														type="password"
														value={update.confirmation}
														name="confirmation"
														onChange={handleChange}
														required
														disabled={updatingPassword}
														placeholder={t("compute.vps.tab1.enterConfirmNewPass")}
													/>
												</div>
												<div className="pt-5">
													
														<AntdButton 
															// onClick={onUpdatePassword} 
															size="large" 
															type="primary" 
															htmlType="submit"
															className=" w-56 flex items-center font-bold py-4 px-4 text-center justify-center" 
															loading={updatingPassword}>
															{t("compute.vps.tab1.updatePassBtn")}
														</AntdButton>
												  	{/*</Popconfirm>*/}
												</div>
											</form>
										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Networking"}>
										<div className=" ">
											<NetworkingVps onGetNetwork={getNetwork} server={server} loadVps={loadVps} />
										</div>
									</TabPanel>


									<TabPanel hidden={selectedTab !== "Delete"}>
										<div className="">
											<DeleteServiceTap 
												server={server} 
												onDelete={onDelete}
												onRun={onRun}
											/>
										</div>
									</TabPanel>


									<TabPanel hidden={selectedTab !== "Rebuild"}>
										<div className="">
											<RebuildVPS server_id={server?.OpenstackInstanceId} Id={server?.Id}/>

										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Upgrade"}>
										<div className="">
											<UpgradeVps vpsId={id} loadVps={loadVps} server={server} />

										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Blueprint"}>
										<div className="pb-10">

											<div className="flex gap-2 items-center " >
												<img src={blueprint} alt="" className='w-8 h-8 xl:w-12 xl:h-12 2xl:w-20 2xl:h-20' />
												<PageTitle text={t("compute.vps.blueprint.h1")} />
												
											</div>

											<div className="w-11/12 pt-10 grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-4">
												{
													blueprints.map((blueprint, k) => <BlueprintCart server={server} k={k} blueprint={blueprint} />)
												}

											</div>
										</div>

									</TabPanel>

									<TabPanel hidden={selectedTab !== "Security"}>
										<div className=" pb-10">

											<div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-5">
												<div className="pt-3 lg:col-span-2 rounded-md bg-neutral-100 dark:bg-bgPagedark  shadow-lg border dark:border-zinc-800 dark:shadow-darkSiderbar">
													<div className="p-5" >
														<span className="flex text-xl">
															<FaDownload className='w-6 h-6 mr-2' />
															<span className="font-bold">{t("compute.vps.security.vuln.title")}</span>
														</span>
														<Texto small className=" mt-3">
															{t("compute.vps.security.vuln.desc")}
														</Texto>

														<div className="pt-3">
															<OkButton title={t("compute.vps.security.vuln.scan")} loanding={onGoingScan} click fun={onScan} noRadius />
														</div>
													</div>
												</div>
												<div className="lg:col-span-3  rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
													<div className=" p-5 pt-3">
														<span className="flex text-xl">
															<FaNetworkWired className='w-6 h-6 mr-2' />
															<span className="font-bold">{t("compute.vps.security.port.title")} </span>
														</span>
														{!onGoingScan ?
															<div className="flex gap-4 pt-2">
																{scanObj && scanObj?.ports.map((port, id) => {
																	return (
																		<div key={id} className="2xl:w-14 h-10 w-10 2xl:text-base text-sm rounded 2xl:h-14 mt-2 font-bold cursor-pointer text-white dark:text-darkTextColor dark:bg-primary  justify-center items-center bg-primary flex">
																			{port}
																		</div>
																	)
																})}
																{scanObj && scanObj?.ports.length === 0 ?
																	<Texto small className="bg-primary dark:text-darkTextColor">{t("compute.vps.security.port.noPort")}</Texto>
																	:
																	null
																}
																{errorScan ? <Texto small className=" dark:text-darkTextColor">{t("compute.vps.security.port.noInfo")}</Texto> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<Spiner fontSize={30} />
															</div>
														}
													</div>
												</div>
											</div>

											<div className="grid grid-cols-1 gap-3 lg:mt-8 mt-3 md:grid-cols-2 lg:grid-cols-5">
												<div className="pt-3 lg:col-span-2">
													<div className="rounded-md bg-neutral-100 dark:bg-bgPagedark p-5 shadow-lg border dark:border-zinc-800 dark:shadow-darkSiderbar" >
														<span className="flex text-xl">
															<AiOutlineGlobal className='w-6 h-6 mr-2' />
															<span className="font-bold">{t("compute.vps.security.general.title")}</span>
														</span>
														{!onGoingScan ?
															<div>
																<div className="pt-3 flex gap-5 pb-2 border-b-2 border-b dark:border-zinc-800">
																	{scanObj && scanObj?.hostnames && <Texto small className="font-bold">{t("compute.vps.security.general.hostname")}</Texto>}
																	{scanObj &&
																		<div className="flex flex-wrap">
																			{scanObj?.hostnames.map((hostname, id) => <span>{id !== 0 ? " ," : ""} {hostname}</span>)}
																		</div>
																	}


																	{scanObj && scanObj?.hostnames.length === 0 ?
																		<Texto small className=" dark:text-darkTextColor">{t("compute.vps.security.general.noHost")}</Texto>
																		:
																		null
																	}

																	{errorScan ? <Texto small className=" dark:text-darkTextColor">{t("compute.vps.security.general.noInfo")}</Texto> : null}
																</div>

																{scanObj && scanObj?.tags.map((tag, id) => {
																	return (
																		<div className="inline-block mr-3" key={id}>
																			<div className="rounded py-2 px-5 mt-2 font-bold cursor-pointer dark:text-white dark:text-darkTextColor   justify-center items-center border-3 border-green-700 border">
																				{tag}
																			</div>
																		</div>
																	)
																})}

															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<Spiner fontSize={30} />
															</div>
														}
													</div>
												</div>

												<div className="pt-3 lg:col-span-3">
													<div className="lg:col-span-2  p-5 rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
														<span className="flex text-xl">
															<MdWarning className='w-6 h-6 mr-2' />
															<span className="font-bold">{t("compute.vps.security.tech.title")} </span>
														</span>

														{!onGoingScan ?
															<div className=" gap-4 pt-2">
																{scanObj && scanObj?.cpes.map((cpe, id) => {
																	return (
																		<div className="inline-block mr-3" key={id}>
																			<div className="rounded py-2 px-5 mt-2 font-bold cursor-pointer dark:text-white dark:text-darkTextColor   justify-center items-center border-3 border-green-700 border">
																				{cpe}
																			</div>
																		</div>
																	)
																})}
																{scanObj && scanObj?.cpes.length === 0 ?
																	<Texto small className="text-primary dark:text-darkTextColor">{t("compute.vps.security.tech.noTech")}</Texto>
																	:
																	null
																}
																{errorScan ? <Texto small className=" dark:text-darkTextColor">{t("compute.vps.security.tech.noInfo")}</Texto> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<Spiner fontSize={30} />
															</div>
														}
													</div>
												</div>

											</div>

											<div className="grid grid-cols-1 gap-3 lg:mt-8 mt-3 ">

												<div className="pt-3 2xl:text-base text-sm">
													<div className="lg:col-span-2  p-5 rounded-md shadow-lg border  dark:shadow-darkSiderbar dark:border-zinc-800  bg-neutral-100 dark:bg-bgPagedark">
														<span className="flex text-xl">
															<MdWarning className='w-6 h-6 mr-2' />
															<span className="font-bold">{t("compute.vps.security.vuln.title2")} </span>
														</span>
														<span className="text-xs">{t("compute.vps.security.vuln.desc")}</span>




														{!onGoingScan ?
															<div className="flex gap-4 pt-2">
																{scanObj && scanObj?.vulns.map((vuln, id) => {
																	return (
																		<div key={id} className="pt-3 flex gap-10 pb-2 border-b border-b-2 dark:border-zinc-800">
																			<span className="font-bold w-1/4">{vuln.cve}</span>
																			<span>{vuln.desc}</span>
																		</div>
																	)
																})}
																{scanObj && scanObj?.vulns.length === 0 ?
																	<Texto small className="bg-primary dark:text-darkTextColor">{t("compute.vps.security.vuln.noVuln")}</Texto>
																	:
																	null
																}
																{errorScan ? <Texto small className=" dark:text-darkTextColor">{t("compute.vps.security.vuln.noInfo")}</Texto> : null}
															</div>
															:
															<div className="flex justify-center items-center pt-12">
																<Spiner fontSize={30} />
															</div>
														}
													</div>
												</div>
											</div>


										</div>
									</TabPanel>

									<TabPanel hidden={selectedTab !== "Storage"}>
										<div className="">
											<StorageVps loadVps={loadVps} server={server} />

										</div>
									</TabPanel>
								</div>


							</div>
						</div>
					}

					{!server &&
						<div className='flex justify-center h-48 items-center'>
							<Spiner fontSize={50} />
						</div>
					}
				</div>
			</div>
		</>
	);
}










