import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { message } from 'antd'
import { Spiner } from 'components/Bloc'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { updateHeadLink } from 'reducer/action/coreActions'

import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/TabSelector";
import { Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';

import { PDashboard } from './PDashboard'
import { PSecurityLog } from './PSecurityLog'
import { Hosts404 } from './Hosts404'
import { Streams } from './Streams'
import { RedirectionHosts } from './RedirectionHosts'
import { PProxyHosts } from './PProxyHosts'
import { PSettings, PSettingsAdv } from './PSettings'
import { PAccessLists } from './PAccessLists'
import { PCertificates } from './PCertificates'
import { PUsers } from './PUsers'
import { PAuditLog } from './PAuditLog'
import UserMgt from '../../lib/user_managment';
import KSPROXY from 'lib/proxy'
import { SiNginxproxymanager } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineStream } from "react-icons/md";
import { TbError404 } from "react-icons/tb";



const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };





export default function KSProxy({server, isKwaf}) {
    const tabs = [
        "Dashboard",
        "Hosts", 
        "Access Lists",        
        "SSL Certificates",
        "Users",
        "Audit Logs",
        "Settings", 
     ]
    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()
    // const [server, setvps] = useState(null)
    const [proxyHosts, setProxyHost] = useState(null)
    const [streams, setStreams] = useState(null)
    const [hosts404, set404hosts] = useState(null)
    const [users, setUsers] = useState(null)
    const [redirectHosts, setRedirectHost] = useState(null)
    const [accessList, setAccessList] = useState(null)
    // const [users, setUsers] = useState([])
    const [loadingUser, setload] = useState(false)
    const [loadingAcc, setloadAcc] = useState(false)
    const { id } = useParams()
    if(isKwaf && !tabs.includes("Security logs")){
        tabs.splice(6, 0, 'Security logs');
    }
    const [selectedTab, setSelectedTab] = useTabs(tabs);
    const [subTab, setSubTab] = useState("")
    const [subTab2, setSubTab2] = useState("")
    const navigate = useNavigate()

    

    async function getUsers(){
        setload(true)
        try{
          const result = await KSPROXY.getUsers(id)
         result && setUsers(Object.values(result).filter( arr => typeof arr === "object" && arr.email !== "admin@keepsec.waf"))
         !result && setUsers([])
        }catch(e){
          console.log('Cant get proxy host ', e)
        }
        setload(false)
    }

    async function getAccesslist(){
        setloadAcc(true)
        try{
          const result = await KSPROXY.getAccesslist(id)
         result && setAccessList(Object.values(result).filter( arr => typeof arr === "object"))
         !result && setAccessList([])
        }catch(e){
          console.log('Cant get proxy host ', e)
        }
        setloadAcc(false)
      }
 


    useEffect(()=>{
        if(server){
            getUsers()
            getAccesslist()

        }
        // loadVps().then((server) => {
        //     // const headLinks = [
        //     //     {
        //     //         link: "/security/vullify",
        //     //         item: 'vullify',
        //     //         alt: "Vullify short link"
        //     //     },
        //     //     {label: server?.DisplayName, arrow: true, link: "security/vullify/"+server?.Id+"/connect"},
        //     //     {
        //     //         // link: "/security/vullify/",
        //     //         label: 'KSProxy',
        //     //         // alt: "Vullify short link",
        //     //         arrow: true
        //     //     }
        //     // ]
        //     // dispatch(updateHeadLink(headLinks))
                
        // })
        // return () => dispatch(updateHeadLink([]))
    }, [server])


    function onSubItem(item){
        setSelectedTab("Hosts"); 
        setSubTab(item)
    }
    function onSubItem2(item){
        setSelectedTab("Settings"); 
        setSubTab2(item)
    }

    const items = [
      {
        key: '1',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem('Proxy Hosts')}>
            <SiNginxproxymanager className="text-primary" />
            Proxy hosts
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem('Redirection Hosts')}>
            <FaExternalLinkAlt className="text-primary" />
            Redirection Hosts
          </a>
        )
      },
      {
        key: '3',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem('Streams')}>
            <MdOutlineStream className="text-primary " />
            Streams
          </a>
        )
      },
      {
        key: '4',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem('Hosts')}>
            <TbError404 className="text-primary " />
            404 Hosts
          </a>
        )
      },
    ];

    const itemsSetting = [
      {
        key: '10',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem2('kwaf')}>
            {/*<SiNginxproxymanager className="text-primary" />*/}
            K-WAF
          </a>
        ),
      },
      {
        key: '11',
        label: (
          <a className="flex gap-2 items-center" onClick={()=>onSubItem2('kwafAd')}>
            {/*<FaExternalLinkAlt className="text-primary" />*/}
            K-WAF Advanced
          </a>
        )
      }
    ];

    return (
       <div>
            {contextHolder}

            <div className=' dark:text-darkTextColor '>
                <div className="">
                    {server ?
                        <div className="pt-5">
                            <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            	{tabs.map(tab =>
    	                            <TabSelector
    	                              isActive={selectedTab === tab}
    	                              onClick={() => {
                                            if(!isKwaf && tab !=="Hosts"){
                                                return setSelectedTab(tab)
                                            }else if(isKwaf && !["Hosts", "Settings"].includes(tab)) {
                                                return setSelectedTab(tab)
                                            }
                                        }}
    	                              key={tab}
                                      dropdown={(tab ==="Hosts" || tab ==="Settings")}
    	                            >
    	                               {!isKwaf ?
                                            tab !=="Hosts" ?
                                                tab
                                            :
                                            <Dropdown
                                                menu={{
                                                  items
                                                }}
                                                className="w-full"
                                              >
                                                <a className="md:pr-8 pr-3">
                                                  <Space>
                                                    {tab}
                                                    <DownOutlined />
                                                  </Space>
                                                </a>
                                            </Dropdown>
                                        :
                                            tab !=="Hosts" && tab !=="Settings" ?
                                                tab
                                            :
                                            <Dropdown
                                                menu={{ 
                                                  items: tab ==="Hosts" ? items : itemsSetting
                                                }}
                                                className="w-full"
                                              >
                                                <a className="md:pr-8 pr-3">
                                                  <Space>
                                                    {tab}
                                                    <DownOutlined />
                                                  </Space>
                                                </a>
                                            </Dropdown>

                                        }
    	                            </TabSelector>
                            	)}
                               
                              </nav>
                              <div className="pt-5">
                                  <TabPanel hidden={selectedTab !== "Dashboard"}>
                                       <div className="text-center ">
                                            <PDashboard
                                                proxyHosts={proxyHosts} 
                                                redirectHosts={redirectHosts}
                                                streams={streams}
                                                hosts404={hosts404}
                                                onSubItem={onSubItem}
                                                isKwaf={isKwaf}
                                            />
                                       </div>   
                                  </TabPanel>
                                  <TabPanel hidden={selectedTab !== "Access Lists"}>
                                       <div className="text-center w-full">
                                            <PAccessLists 
                                                accessList={accessList}
                                                setAccessList={setAccessList}
                                                server={server} 
                                                messageApi={messageApi}
                                                getAccesslist={getAccesslist}
                                                loading={loadingAcc}
                                                isKwaf={isKwaf}
                                            />
                                       </div>   
                                  </TabPanel> 
                                  <TabPanel hidden={selectedTab !== "SSL Certificates"}>
                                       <div className="text-center w-full">
                                            <PCertificates 
                                                // accessList={accessList}
                                                // setAccessList={setAccessList}
                                                server={server} 
                                                isKwaf={isKwaf} 
                                                // messageApi={messageApi}
                                                // getAccesslist={getAccesslist}
                                                // loading={loadingAcc}
                                            />
                                       </div>   
                                  </TabPanel> 
                                  <TabPanel hidden={selectedTab !== "Users"}>
                                       <div className="text-center w-full">
                                            <PUsers 
                                                users={users} 
                                                server={server} 
                                                setUsers={setUsers}
                                                getUsers={getUsers}
                                                loading={loadingUser}
                                                messageApi={messageApi}
                                                isKwaf={isKwaf}
                                            />
                                       </div>   
                                  </TabPanel> 
                                  <TabPanel hidden={selectedTab !== "Audit Logs"}>
                                       <div className="text-center w-full">
                                            <PAuditLog isKwaf={isKwaf} server={server}  />
                                       </div>   
                                  </TabPanel> 
                                  <TabPanel hidden={selectedTab !== "Settings"}>
                                       <div className="text-center w-full">
                                            {!isKwaf ?
                                                <PSettings isKwaf={isKwaf} server={server}  />
                                                :
                                                <div className="text-center w-full">
                                                    <div style={{display: subTab2 !== "kwaf" && "none"}}>   
                                                        <PSettings isKwaf={isKwaf} server={server}  />
                                                    </div>  
                                                    <div style={{display: subTab2 !== "kwafAd" && "none"}}>   
                                                        <PSettingsAdv isKwaf={isKwaf} server={server}  />
                                                    </div>   
                                                </div>   
                                            }
                                       </div>   
                                  </TabPanel>
                                  <TabPanel hidden={selectedTab !== "Hosts"}>
                                       <div className="text-center w-full">
                                           <div style={{display: subTab !== "Proxy Hosts" && "none"}}>   
                                                <PProxyHosts 
                                                    proxyHosts={proxyHosts} 
                                                    setProxyHost={setProxyHost}
                                                    server={server} 
                                                    users={users}
                                                    isKwaf={isKwaf}
                                                    accessList={accessList}
                                                    messageApi={messageApi}
                                                /> 
                                           </div> 
                                           <div style={{display: subTab !== "Redirection Hosts" && "none"}}>   
                                                <RedirectionHosts 
                                                    redirectHosts={redirectHosts}
                                                    server={server} 
                                                    setRedirectHost={setRedirectHost}
                                                    messageApi={messageApi} 
                                                    isKwaf={isKwaf} 
                                                /> 
                                           </div> 
                                           <div style={{display: subTab !== "Streams" && "none"}}>   
                                                <Streams
                                                    streams={streams} 
                                                    server={server}
                                                    setStreams={setStreams}
                                                    messageApi={messageApi}
                                                    isKwaf={isKwaf}
                                                /> 
                                           </div> 
                                           <div style={{display: subTab !== "Hosts" && "none"}}>   
                                                <Hosts404 
                                                    hosts404={hosts404} 
                                                    server={server} 
                                                    set404hosts={set404hosts}
                                                    messageApi={messageApi}
                                                    isKwaf={isKwaf}
                                                /> 
                                           </div>   

                                           
                                       </div>   
                                  </TabPanel> 
                                  <TabPanel hidden={selectedTab !== "Security logs"}>
                                       <div className="text-center w-full">  
                                            <PSecurityLog 
                                                hosts404={hosts404} 
                                                server={server} 
                                                set404hosts={set404hosts}
                                                messageApi={messageApi}
                                                isKwaf={isKwaf}
                                            />  
                                        </div>   
                                  </TabPanel> 
                              </div>


                                {/*<TabPanel hidden={selectedTab !== "History"}>
                                    <div className="pt-3 ">
                                        
                                    </div> 
                              </TabPanel>
                                <TabPanel hidden={selectedTab !== "Info"}>
                                    <div className="pt-3">

                                        

                                        
                                    </div>
                              </TabPanel>
                                <TabPanel hidden={selectedTab !== "Credit"}>
                                   
                              </TabPanel>*/}
                        </div>
                        :
                        <div className='flex my-auto h-full justify-center items-center'>
                            <Spiner fontSize={50} />
                        </div>
                    }

            
                </div>
            </div>
        </div>
    );
}
