import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

import { ExtLink } from 'components/Bloc';

import { Logo } from '../../components/Logo';
import { Toast } from '../../components/Notify';
import { SocialLogin } from '../../components/SocialLogin';
import UserAPI from "../../lib/keepsec_auth_service"

import "./Auth.css"

export default function SignUpMethod() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const navigate = useNavigate()
    const user =  useSelector(app => app?.user?.user) 
    // const [Gaccess_token, setGaToken] = useState(null)

    useEffect(() => {
        
        if(user) return navigate('/')
    }, [])

   


    async function onLogin(e, otherAuth){
        try {
            const referrerId = localStorage.getItem('referrerId')
            const result = await UserAPI.user_login({...otherAuth, referrerId: referrerId}, dispatch)
            console.log('result', result)
            setCreating(false)
            if(result.error){
                return Toast.fire({
                    icon: 'error',
                    title: result.message
                })
            }else{ 
                if(referrerId) localStorage.removeItem('referrerId')
                Toast.fire({
                    icon: 'success',
                    title: t('Signed in successfully')
                })
                
                navigate('/')
                
            }
        } catch (error) {
            console.log('error api', error)
            Toast.fire({
                icon: 'error',
                title: error.message
            })
        }
        //navigate('/')

    }
    return ( 
        <div className='bgCustom'>

            <div className="flex items-center justify-center min-h-screen  content ">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-2xl w-[400px]">
                    <div className='pb-4'>
                        <Logo/>
                        <h2 className="mt-4 text-3xl font-extrabold text-center text-gray-900">
                            {t('Create your account')} 
                        </h2>
                        <p className='text-[13px] text-center mt-4'>
                        {t('KeepSec is securing your future, one byte at a time!')}
                        </p>
                    </div>
                   
                    <SocialLogin signup setCreating={setCreating} onCallback={onLogin} /> 

                    <button
                        className="
                        relative
                        flex
                        justify-center
                        items-center
                        w-full
                        px-4
                        py-2
                        text-base
                        font-medium
                        text-white
                        bg-primary
                        group
                        rounded-xl
                        hover:bg-black hover:border-primary
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-500
                        gap-2
                        "
                        onClick={() => {
                            navigate('/registrations/email')
                        }}
                    >
                        {!creating ?
                            null
                            :
                            <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        {!creating ?
                            <span>{t('Sign up with Email')}</span> :
                            <span>{t('Ongoing')} ...</span>
                        }
                    </button>
                    

                    <div className="flex  justify-center text-xs flex-wrap gap-2 mt-3">
                        <div className="flex items-center gap-2">
                        {t('Already have an account?')}
                            <Link
                                to="/login"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Log in')}
                                </span>
                            </Link>
                            <br></br>
                            <Link
                                to="/forgot_password/new"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Forgot your password?')}
                                </span>
                            </Link>
                        </div> 
                    </div>
                    <div className="flex items-center justify-center text-center text-xs pt-5">
                        <span>
                            {t('By signing up, you agree to the')} {" "}
                            <br/>
                            <ExtLink 
                                href='https://www.keepsec.ca/legal#tos'
                                text={t('Terms of Service')}
                                className="font-bold text-xs 2xl:text-xs" 
                            />
                           {" "}  {t('and')} {" "}
                            <ExtLink 
                                href='https://www.keepsec.ca/legal#privacy' 
                                text={t('Privacy Policy')}
                                className="font-bold text-xs 2xl:text-xs"
                            />
                        </span>
                    </div>
                    

                </div>
            </div>
        </div>
    );
}












