import compute from './compute'
import navbar from './core/navbar'
import sidebar from './core/sidebar'
import k8s from './k8s'
import network from './network'
import platform from './platform'
import security from './security'

export default{
    compute,
    platform,
    security,
    network,
    k8s,
    core: {
        sidebar, 
        navbar,
        constants: {
            PASSWORD_LENGTH_ERROR: "The new password must be at least 6 characters.",
            PASSWORD_CHARACTER_ERROR: "The password contains invalid characters.",
            STANDARD_ERROR_MESSAGE: "An errors occured, please try again or contact us!",
            PASSWORD_DONT_MATCH: "The passwords values don't match.",
            ACTION_DONE_MESSAGE: "Action done!",
            INVALID_USERNAME: "Username is invalid!",
            INVALID_SERVICE_IMAGE: "It look like you didn't pick instance image!",
            MISSING_REQUIRED_FIELD: "All required fields are not filled!",
            ERROR_MESSAGE_403: "You are not allowed to perform this action.",
            SUBSCRIPTION_CANCELLED_MESSAGE: "Your subscription has been cancelled!"
        }
    },
    serverInformations: "Server informations",
    indentifyInstance: "Identify your instance",
    indentifyInstanceHelpMessage: "Must not contain spaces, special characters or symbols",
    indentifyInstancePlaceholder: "Username used to login via SSH",
    serverPasswordLabel: "Server password",
    serverPasswordpalceHolder: "Password used to login via SSH",
    serverPasswordConfirmLabel: "Password confirmation",
    serverPasswordConfirmPlaceholder: "Confirm the password entered here",
    
    preview:{
        keyFeatures: "Key features",
    },
    package:{
        month: "month",
        hour: "hour",
    },
    fund: {
        title: "Add Funds",
        costCal: "COST CALCULATOR",
        recurring: "Recurring",
        perM: "/month",
        custom: "Custom",
        getKsPoint: "Get {{point}} KsPoints each month",
    },
    deploy: {
        identifyYourInstance: "Identify your instance",
        serverNameTooltip: "Must not contain spaces, special characters or symbols",
        serverNameTooltip2: "Must contain only alphanumeric characters, a single hyphen and/or underscore, and must not start or end with a hyphen or an underscore. Max 15 characters. Example: instance_01, instance-01.",
        vpsMiniServNameTooltip: "Must contain only alphanumeric or underscore _ characters ",
        identfyInstancePlaceholder: "Your instance resources must have unique names",
        instanceUser: "Server user",
        instanceUserPlaceholder: "Username used to login via SSH",
        instancePassword: "Server password",
        sshPasword: "Password used to login via SSH",
        passwordConfirm: "Password confirmation",
        passwordConfirmPlaceholder: "Password confirmation",
        datacenterLocationTitleSection: "Choose your service location",
        pickInstanceImageSectionTitle: "Choose your instance image",
        serverInformationSectiontitle: "Server informations",
        sslEmail: "Email for SSL",
        validEmailAddrCERT: "Valid email address to get certificates",
        chooseAVpcType: "Choose your VPC Type",
        choseDatacenterLocationRegion: "Choose your datacenter location region",
        datcenterLocationregDesc: "VPC networks can only contain ressources that are at the same datacenter region",
        noGateway: "No gateway",
        recommended: "Recommended",
        extGateway: "External gateway",
        subnetSpec: "You will need to specify a subnet IP or size",
        nameAndDesc: "Chose a name and description",
        nameAndDescLabel: "Add a name to find your VPC more easily",
        vpcName: "Name",
        vpcNamePlaceholder: "Type your VPC name here",
        vpcDescription: "Description",
        vpcDescriptionPlaceholder: "Your instance resources must have unique names",
        addNewNode: "Add a node",
        addNewControlPlane: "Add control planes",
        selectPackageSectionTitle: "Choose your instance plan",
        deplayByAdminSectionTitle: "Choose your instance plan",
        errorServiceName: 'The value "{{serviceName}}" is incorrect. It must contain only alphanumeric characters, underscores, or hyphens and must not exceed 15 characters. Example: server_01, my-serv_02',
        deployNewBtn: "Deploy new",
        waitNotify: "Please wait between 4 to 6 minutes to see your new KPS available",
        okUndestoodBtn: "Ok, Understood",
        ______: "________",
        deployBottom: {
            summary: "Summary",
            valueNotDefined: "(Not defined)",
            image: "Image",
            plan: "Plan",
            perMonth: "/month",
            tAmount: "Total amount",
            comingSoon: "Coming soon",
            deployNow: "Deploy Now",
            deploying: "Deploying",
            waitingForpaypal: "Waiting for paypal",
            location: "Location",
            discount: "Discount",
            finalprice: "Final price",
            perMonthWithTax: "/ month (include TPS + TVQ)",
            promoCode: "Promo code",
            promoCodePlaceholder: "Please enter  your promocode code",
            day7FreeThen: "7-days free trial {{then}}",
            then: ", then",
            trialCodeLabel: "Trial code",
            trialCodePlaceholder: "Please enter  your trial code",
            ______: "________",
        },
        khks: {
            paypalLabel: "kubernetes subscription",
            chooseClusterLocation: "Choose your cluster location",
            clusterVersion: "Choose your cluster version",
            chooseControlPlanPlan: "Choose your control plane {{padStart}} plan ",
            chooseNodePlan: "Choose your node {{padStart}} plan",
            clusternInfo: "Cluster informations",
            identifyCuster: "Identify your cluster",
            identifyClusterTootip: "Must not contain spaces, special characters or symbols",
            idClusterPaceHolder: "Your cluster resources must have unique names",
            selecteAdditionOption: "Select additionnal options",
            haRecommend: "We recommend high availability to eliminate a single point of failure and increase peace of mind for production workloads.",
            getExtra: "Get extra reliability for critical workloads",
            haLongTxt: 'A <span className="text-blue-500 inline-flex items-center"> high availability control plane </span> creates multiple replicas of control plane components to maximize cluster access and uptime with a 99.99% SLA.',
            addHA: "Add high availability (HA)",
            chooseClusterHALocation: "Choose your HA cluster {{number}} location",
            hahClusterInfo: "HA cluster {{number}} informations",
            haNodenamePlacholder: "Your cluster resources must have unique names",
            haNodenameTooltip: "Must not contain spaces, special characters or symbols",
            identifyHACuster: "Identify your HA cluster",
            addHaCluster: "Add another HA cluster",
            deleteHACluster: "Remove previous HA cluster",
            chooseYourInstancePlan: "Choose your node plan",
            idYourNode: "Identify your Node",
            resUNiqueName: "Your cluster resources must have unique names",
            resUNiqueNameTooltip: "Must not contain spaces, special characters or symbols other",
            addNewNode: "Add a node",
            addControlPlane: "Add control planes",
            totalControlPlanShouldOdd: "The total of control planes should be an odd number.",
            totalHHAShouldOdd: "The total of HA clusters control planes should not be an odd number.",
            totalALl: "The total of all control planes including HA clusters should be an odd number.",
            nameDplucated: "The name '"+"{{string}} "+"'is duplicated.",
            nodePlanDontPicked: "Node {{zeroPad}} plan of primary cluster didn't get picked",
            masterPlanDontPicked: "Control plane {{zeroPad}} of primary cluster didn't get picked",
            least2HA: "You should add at least 2 control planes for a HA cluster",
            locationClustedidntPicked: "Location of HA cluster {{zeroPad}} didn't selected.",
            nodeOfHAClusterDidntPicked: "Node {{y}} plan of HA cluster {{zeroPad}} didn't get picked.",
            controkPlanOfHAClusterDidntPicked: "Control Plane {{y}} plan of HA cluster {{zeroPad}} didn't get picked.",
            kubeVersionNotSelected: "kubernetes version not selected",
            applypromoCode: "You should apply promo code !",
            locationDidSelect: "Service location not selected",
            trialCodeInvalid: "Trial code is not valid !",
            haClusteLeast2controlPlane: "The HA clusters should have at least 2 control planes.",
            khksServiceCreated: "K8S cluster created",
            ______: "________",
            ______: "________",
        }
    },
    deleteModal:{
        title: "Do you confirm?",
        warning: "Warning",
        warContent1: "This process will automatically remove this service.",
        warContent2: "Other ressources that rely on this service might be affected.",
        cancelBtn: "No, I don't",
        yesBtn: "Yes, I do",
        _____: "__________",
    },
    rebootModal:{
        title: "Reboot this instance?",
        warning: "Warning",
        warContent1: "This proccess will make any website or service on your instance temporarily unavailable.",
        warContent2: "Do you really want to reboot your instance ?",
        cancelBtn: "No, Cancel",
        yesBtn: "Yes, Reboot",
        doneMessage: "Server {{DisplayName}} rebooted",
    },
    stopModal:{
        title: "Stop this instance?",
        warning: "Warning",
        warContent1: "This proccess will make any website or service on your instance unavailable until your start it again.",
        warContent2: "Stopped instances continue to occur charges.",
        warContent3: "Delete your instances to stop occuring charges.",
        warContent4: "Do you really want to stop your instance?",
        cancelBtn: "No, Cancel",
        yesBtn: "Yes, Stop",
        doneMessage: "Server {{DisplayName}} rebooted",
        _____: "__________",
    },
    rebuildModal:{
        title: "Rebuild  this instance?",
        warning: "Warning",
        warContent1: "This proccess will make any website or service on your instance unavailable.",
        warContent2: "This process will automatically remove this instance including all of its data and rebuild it.",
        warContent3: "Do you really want to rebuild your instance?",
        cancelBtn: "No, Cancel",
        yesBtn: "Yes, Rebuild",
        _____: "__________",
    },
    startModal:{
        title: "Start  this instance?",
        warning: "Warning",
        warContent1: "Do you really want to start your instance?",
        warContent2: "__________",
        cancelBtn: "No, Cancel",
        doneMessage: "Server {{DisplayName}} started",
        yesBtn: "Yes, Start",
        _____: "__________",
    },
    blueprintModal:{
        title: "  this instance?",
        warning: "Warning",
        warContent1: "Do not interrupt the installation process and follow the instructions given.",
        warContent2: "Please note any usernames, passwords and/or ports to open given at the end of the installation.",
        warContent3: "Other ressources that rely on this instance might by affected.",
        cancelBtn: "No, Cancel",
        yesBtn: "Yes, Install",
        okeyBtn: "Okay",
        dbUsrPass: "DB User Password",
        dbRootPass: "DB Root Password",
        pleaseConfirmInstallItem: "Please confirm the install of  {{item}}",
        pleaeKeepTheAppCredentialSecure: "Please keep the app credentials secure",
        blueDeployed: "The blueprint has been deployed successfully",
        _____: "__________",
    },
    support: {
        docs: {
            cartTitle: "Docs",
            cartDesc: "Browse through our documentation for any technical guidance or usage tips",
            link: "Go to docs",
        },
        blog: {
            cartTitle: "Blog",
            cartDesc: "Read our blog posts to be up to date with KeepSec products and news",
            link: "Go to blog",
        },
        chat: {
            cartTitle: "Live chat",
            cartDesc: "Receive support from KeepSec through our live chat",
            link: "Start a chat",
        },
        _____: "__________",
    }
    
}