import fr from "./fr"

export default{
	//login
	"Sign up with GitHub": "S'inscrire avec GitHub",
	"Sign in with GitHub": "Se connecter avec GitHub",
	"Sign up with Google": "S'inscrire avec Google",
	"Sign in with Google": "Se connecter avec Google",
	"Sign up with Email": "S'inscrire par e-mail",
	"Email": "E-mail",
	"Log in to your account": "Accédez à votre compte",
	"Email here": "Votre e-mail ici",
	"Password": "Mot de passe",
	"Password here": "Votre mot de passe ici",
	"Log in": "Se connecter",
	"Ongoing": "En cours",
	"Not a member yet?": "Pas encore de compte?",
	"Sign up": "S'enregistrer",
	"Forgot your password?": "Mot de passe oublié?",
	"Signed in successfully": "Connexion réussie",
	// 2FA
	"Two-Factor Authentication": "Authentification à deux facteurs",
	"Open your authentication app and enter a code": "Ouvrir votre app d'authentification et entrer un code",
	"Can't access your authentication app?": "Vous ne pouvez pas accéder à votre app d'authentification?",
	"Enter a recovery code": "Entrer un code de recouvrement",
	"contact support": "contacter le support",
	"Verify code": "Vérifier le code",
	"Ongoing": "En cours",
	"Session expire in": "Session expire dans",
	//Register
	"Success! Please check your a mailbox and active your account !": "Succès! Veuilez activez votre compte au travers du liens qui vous a été envoyer dans un courrier !",
	"The password must be at least 6 characters !": "Le mot de passe doit contenir au-moins 6 charactères",
	"Sign Up with Email": "S'enregistrer par e-mail",
	"Sign up with": "S'enregistrer avec",
	"instead": "plutôt",
	"or": "ou",
	"Full name": "Nom complet",
	"Enter a full name": "Entrez votre nom complet",
	"Email address": "Addresse e-mail",
	"Enter your email address": "Entrez votre addresse e-mail",
	"Enter a password": "Entrez le mot de passe",
	"Already have an account?": "Vous avez déja un compte?",
	"By signing up, you agree to the": "En vous enregistrant, vous acceptez les",
	"Terms of Service": "Conditions d'utilisation",
	"and": "et",
	"Privacy Policy": "Politique de confidentialité",
	//Sign up method
	"Create your account": "Créer votre compte",
	"KeepSec is securing your future, one byte at a time!": "KeepSec sécurise votre future, un octet à la fois!",
	//Reset pass
	"New  password reset form": "Réinitialisation de mot de passe",
	"New password": "Nouveau mot de passe",
	"Confirmation": "Confirmation",
	"Reset now": "Réinitialiser",
	//Request reset pass
	"Request password reset form": "Démande de réinitialisation de mot de passe",
	"Enter the email addres": "Entrez votre addresse e-mail",
	"Send now": "Envoyer maintenant",
	//ACCOUNT VERIFICATION
	"Account verification": "Vérification du compte",
	"login now": "Se connecter",

	connectSshLink: "Se connecter avec SSH",
	copyToClip: "Copier dans le presse-papiers",
	copied: "Copié",
	new: "Déployez",
	prev: "Préc",
    next: "Suiv",
    addPlan: "Ajoutez un plan",
	learn: "Apprendre",
	//dashboard
	dashboard: {
		"goodMorning": "Bon matin",
		"goodAfternoon": "Bonne après-midi",
		"goodEvening": "Bonne soirée",
		"searchPlaceholder": "Filtrez par nom, localisation ou type",
		"tab1": {
			title: "Ressources",
			createService: "Déployez un service et decouvrez KeepSec!",
			noService: "Vous n'avez aucun service pour l'instant",
			lean: "Apprennez en plus sur nos services",
			compute: "CALCUL",
			platform: "PLATEFORME",
			kubernetes: "KUBERNETES",
			security: "SÉCURITÉ",
			networks: "RÉSEAUTIQUE",
			createNow: "Déployez un service maintenant",
			createVps: "Déployez un VPS",
			vpsDesc: "Ajoutez un serveur cloud",
			createKps: "Déployez un KPS",
			kpsDesc: "Ajoutez un serveur de plateforme d'applications",
			createKhks: "Déployez un cluster KHKS",
			khksDesc: "Ajoutez un cluster Kubernetes",
			createKwaf: "Déployez un K-WAF",
			kwafDesc: "Ajoutez un pare-feu d'applications web",
			addOnOne: "Mettre à niveau un service",
			createVolum: "Déployez un volume",
			upgradeStorage: "Mettre à niveau le stockage de votre service"
		}
	},
	service: {
		miniCard: {
			// ...
		},
		deploying: "En déploiement",
		tootipConsole: "Accéder à la console",
		tootipMAnage: "Accéder à la page de gestion",
		goToKhksManagePage: "Accéder à la page de gestion du KHKS",
		startServiceTooltip: "Démarrer le serveur avant d'utiliser la console",
		connect: "Se connecter en SSH",
		manage: "Gérer",
		addBlockStorage: "Ajoutez un stockage en blocs",
		stop: "Arrêter",
		start: "Démarrer",
		reboot: "Redémarrer",
		delete: "Supprimer",
		connect2: "Se connecter",
        goListAppPAGE: "Aller a la page de toutes les apps",
		running: "En marche",
		stopped: "En arrêt",
		ha: "Haute disponibilité",
		ha2: "Haute disponibilité",
		viewNodes: "Afficher les nodes",
		downConfig: "Télécharger la configuration",
		enabled: "Activé",
		notEnabled: "Non activé"
	},
	deleteCompoent:{
		h1: "Supprimer ce service ?",
		title: "Supprimer",
		text1: "La suppression de cette instance la détruira définitivement, y compris toutes ses données.",
		text2: "Êtes-vous sûr de vouloir supprimer cette instance ?",
		button: "Oui, supprimer",
		cannotDeleteServiceText: "Vous ne pouvez pas suprimer ce service."
	},
	volume: {
		create: "Créer un volume",
		lookDOntHave: "Il semble que vous n'avez pas de volumes de stockage de blocs",
		desc: "Les volumes sont des unités hautement disponibles de stockage de blocs que vous pouvez attacher à un VPS pour augmenter son espace disponible.",
	
		volumes: "Volumes",
		bsb: "Bases du stockage de blocs",
		discover: "Découvrez le stockage de blocs et ce que vous pouvez faire avec un volume.",
		overview: "Vue d'ensemble",
		apiDocs: "Documentation API",
		useStorageviaKeepApi: "Utilisez le volume de stockage via l'API KeepSec.",
		tellWhatThink: "Dites-nous ce que vous en pensez",
		subFeelBack: "Soumettez vos commentaires sur le stockage de blocs.",
		createComponent: {
			selectType: "SÉLECTIONNER LE TYPE DE VOLUME",
			ssd: "Disque à semi-conducteurs (SSD)",
			ssdDesc: "Les SSD offrent un stockage rapide et fiable avec un accès plus rapide aux données.",
			hdd: "Disque dur (HDD)",
			hddDesc: "Les HDD offrent un stockage rentable à grande capacité, idéal pour les tâches intensives en données.",
			selectServices: "SÉLECTIONNER LE SERVICE À ATTACHER",
			selectDropdown: "Sélectionner un service",
			nameV: "NOM DU VOLUME",
			nameVHolder: "Nom du volume",
			chooseConfigOptions: "CHOISIR LES OPTIONS DE CONFIGURATION",
			autoConfig: "automatique",
			manualConfig: "manuel",
			authMount: "Formater et monter automatiquement",
			autoMountDesc: "Nous choisirons les configurations par défaut appropriées. Ces paramètres peuvent être modifiés ultérieurement via SSH",
			manualConfigT: "Formater et monter manuellement",
			manualConfigTxt: "Nous attacherons toujours le volume. Vous pouvez ensuite formater et monter le volume manuellement.",
			chooseAFileSystem: "Choisissez un système de fichiers",
			addOpt: "OPTIONS ADDITIONNELLES",
			addOptTxt: "Nous recommandons le chiffrement pour ajouter une couche de sécurité et augmenter la tranquillité d'esprit.",
			getExta: "Obtenir une sécurité supplémentaire pour les données critiques",
			getExtraTxt: "Un volume chiffré avec un LUKS avec des clés de chiffrement aes-xts-plain64 et 256 bits supplémentaires.",
			addEncryp: "Ajoutez le chiffrement",
			storageSameRegion: "Les volumes de stockage de blocs sont créés dans la même région et le même projet que le service auquel ils sont attachés.",
			createBtn: "Créer un volume",
			vSizeFieldReq: "Le champ de taille du volume est obligatoire !",
			vSizeInGBReq: "Le champ Taille en Go est obligatoire !",
			specifyRegion: "Vous devez spécifier une région où vous allez créer le volume",
			vCreated: "Volume créé !",
		},
		detach: {
			wereDetach: "Le serveur a été détaché !",
			serName: "Nom du service",
			mPath: "Chemin monté",
			act: "Action",
			dtachbtn: "Détacher",
			modalTitle: "Détacher les instances du volume nommé {{name}}",
			cancelBtn: "Annuler",
			____: "______",
		},
		sizeModal: {
			doneMessage: "La taille du volume a été mise à niveau !",
			modalTitle: "Augmenter la taille du volume nommé {{name}}",
			pickNewSize: "Choisissez une nouvelle taille à augmenter.",
			cancelBtn: "Annuler",
			setNewSizeBtn: "Définir la nouvelle taille",
			____: "______",
			____: "______",
			____: "______",
			____: "______",
		},
		attachModal: {
			doneMessage1: "L'instance a été attachée au volume !",
			doneMessage: "Le volume a été attaché à l'instance !",
			modalTitle: "Attacher une instance au volume nommé {{name}}",
			selectNewIstanceToattach: "Sélectionnez une nouvelle instance à attacher.",
			cancelBtn: "Annuler",
			selectHere: "Sélectionnez une nouvelle instance ici",
			selectAService: "Sélectionner un service",
			attachBtn: "Attacher",
			title2: "Attacher un volume à une instance",
			selectNewVomlume: "Sélectionner un nouveau volume à attacher.",
			selectNewvomuehere: "Sélectionnez un nouveau volume ici",
			selectaVolume: "Sélectionnez un volume à attacher",
			____: "______",
		},
		sizeCard: {
			selectectNewSize: "SÉLECTIONNER LA TAILLE DU VOLUME",
			perHours: "/heure",
			enterSizeInGB: "Entrez la taille en Go",
			minValueTootlip: "La valeur minimale est de 10 Go",
			____: "______",
		},
		OOngoing: "L'opération est en cours !",
		formatConfirm: "Confirmation de formatage de {{name}}",
		formatConfirmText: "Voulez-vous vraiment formater le volume nommé {{name}}",
		okFormat: "Oui, je le veux",
		noFormat: "Non, je ne veux pas",
		attachBedoreFormat: "Attachez d'abord une instance au volume {{name}} avant de le formater",
		clickFOrmat: "Cliquez pour formater.",
		format: "Formater {{name}} ",
		detach: "Détacher",
		attach: "Attacher",
		increaseSize: "Augmenter la taille",
		takeSnapshot: "Prendre un instantané",
		configIns: "Instructions de configuration",
		deleteConfirm: "Détachez d'abord le volume {{name}} avant de le supprimer",
		deleteConfirm2: "Supprimer ce volume.",
		delete: "Supprimer",
		name: "Nom",
		autoConfig2: "Configuration automatique",
		nanualConfig2: "Configuration manuelle",
		serviceTableCol: "Services",
		on: "<b>{{DisplayName}}</b> dans  <b>{{mountPoint}}</b>",
		date: "Date",
		more: "Plus",
		____: "______",
	},
	account: {
		profile: {
		  twoFa: {
			disbaleTwoFaModal: {
			  title: "Désactiver l'authentification à deux facteurs",
			  p1: "Cela réduit le niveau de sécurité de votre compte et n'est pas recommandé.",
			  p2: "Êtes-vous sûr de vouloir continuer ?",
			  yesBtn: "Oui, désactiver 2FA",
			  nobtn: "Non, annuler",
			  successMessage: "L'authentification à deux facteurs a été désactivée dans votre compte.",
			  ______: "____"
			},
			default: "DÉFAUT",
			configured: "Configuré",
			backup: "SAUVEGARDE",
			backupCodes: "Codes de sauvegarde",
			remaining: "restant",
			disableBtn: "Désactiver 2FA",
			setupZfa: "Configurer 2FA",
			Zfasetup: "Configuration de 2FA",
			setTwofaAuth: "Configurer l'authentification à deux facteurs",
			chooseMethod: "Choisissez une méthode d'authentification",
			methodTxt: "En plus de votre nom d'utilisateur et de votre mot de passe, vous devrez saisir un code (livré via une application ou un SMS) pour vous connecter à votre compte",
			appAUth: "Application d'authentification",
			recommended: "Recommandé",
			getTheCode: "Obtenir des codes à partir d'une application comme Authly, 1Password, Microsoft Authentificator ou Google Authentificator",
			SMS: "SMS",
			receiveSMS: "Recevoir des codes par SMS",
			verifyHone: "Vérifiez votre numéro de téléphone",
			verifyNumTxt: "Saisissez votre numéro de téléphone portable et nous vous enverrons un code de vérification",
			sendSMS: "Envoyer le SMS",
			connectApp: "Connectez votre application",
			connectApptxt: "En utilisant une application d'authentification, scannez le code QR ci-dessous",
			copyQr: "Ou copiez la clé secrète pour la saisie manuelle",
			onceConnectAppTxt: "Une fois que vous avez connecté votre application, saisissez votre code de vérification à 6 chiffres le plus récent pour",
			digitCode: "Code à 6 chiffres",
			saveBackupCode: "Enregistrer les codes de sauvegarde",
			saveBackupTxt: "Faites une copie de vos codes, stockez-les en utilisant un gestionnaire de mots de passe ou dans un fichier crypté",
			cpyBtn: "Copier",
			downTxt: "Télécharger en tant que fichier .txt",
			warningTxt: "Si vous perdez l'accès à votre méthode d'authentification par défaut, utilisez l'un de ces codes pour retrouver l'accès à votre compte.",
			prev: "Précédent",
			cancel: "Annuler",
			next: "Suivant",
			confirm: "Confirmer",
			preventTxt: "Êtes-vous vraiment sûr d'avoir enregistré vos codes de sauvegarde ?",
			yes: "Oui",
			no: "Non",
			finish: "Terminer",
			methodNotready: "Cette méthode 2FA n'est pas encore prête.",
			erro6Digits: "Le code à 6 chiffres est incorrect.",
			choseMethod: "Choisissez une méthode",
			verify: "Vérifier",
			backCode: "Codes de sauvegarde",
			______: "____"
		  },
		  profileTab: {
			name: "Profil",
			firstName: "Prénom",
			lastName: "Nom de famille",
			email: "E-mail",
			phonenumber: "Numéro de téléphone",
			company: "Entreprise",
			address: "Adresse",
			address2: "Adresse 2",
			city: "Ville",
			ZipCode: "Code postal",
			website: "Site Web de l'entreprise",
			descirptionTextBefore: "Décrivez les services que vous exécutez avec votre compte KeepSec",
			desc: "Description",
			______: "____"
		  },
		  preferencesTab: {
			name: "Préférences",
			title: "Préférences utilisateur",
			light: "Mode clair",
			dark: "Mode sombre",
			dTimezone: "Fuseau horaire d'affichage",
			timezonePlaceholder: "Saisissez le mot de passe actuel",
			dateFormat: "Format de date",
			choice1: "Choix 1",
			updateProfilBtn: "Mettre à jour le profil"
		  },
		  authenticationTab: {
			name: "Authentification",
			title: "Authentification à deux facteurs",
			p1: "Sécurisez votre compte en activant l'authentification à deux facteurs.",
			p2: "Chaque fois que vous vous connectez, vous devrez fournir votre mot de passe et un code d'accès généré aléatoirement",
			currentPass: "Mot de passe actuel",
			currentPassPlaceholder: "Saisissez le mot de passe actuel",
			newPass: "Nouveau mot de passe",
			newPassPlaceholder: "Saisissez le nouveau mot de passe",
			confirmNew: "Confirmer le nouveau mot de passe",
			confirmNewPlcaholder: "Saisissez la confirmation du nouveau mot de passe",
			ongoing: "En cours",
			change: "Modifier le mot de passe",
			______: "____"
		  },
		  securityTab: {
			name: "Sécurité",
			______: "____",
			______: "____",
			______: "____"
		  }
		},
		reward: {
			token: "Jeton",
			click: "Clic",
			reg: "Inscription",
			refPur: "Achat par parrainage",
			xp: "63XP pour atteindre le niveau 3",
			level: "Niveau",
			getRewardTab: {
			  name: "Obtenir une récompense",
			  copyToclip: "Copier dans le presse-papiers",
			  copied: "Copié",
			  inviteViaEmail: "Inviter un ami par e-mail",
			  emailInputPlaceholder: "Saisissez l'adresse e-mail",
			  invite: "Inviter",
			  share: "Partager sur les réseaux sociaux",
			  criteria: "Critères pour les nouvelles références",
			  newUsrOnly: "1. <b>Nouveaux utilisateurs uniquement.</b> La personne à qui vous partagez le lien ne peut pas avoir de compte existant.",
			  rewardAre: "2. Les récompenses ne sont éligibles que pour les premiers achats.",
			  theRef: "3. Le client référé ne se rembourse pas et est <b>actif pendant au moins 30 jours.</b>",
			  youWill: "Vous recevrez un paiement de 20% arrondi du prix du plan de services acheté sous forme de jetons.",
			  theRef2: "La personne référencée bénéficiera d'une réduction de 5% sur le service jusqu'à son annulation.",
			  theOnce: "Une fois que vous aurez collecté des jetons de vos références, vous pourrez les échanger contre des articles dans la boutique.",
			  toBeAble: "Pour pouvoir retirer vos jetons en argent, vous aurez besoin d'au moins 50 jetons, un jeton équivaut à 1 $ CAD.",
			  term: "Conditions d'utilisation",
			  labelReq: "{{label}} est requis !",
			  notValidEMail: "{{label}} n'est pas une adresse e-mail valide !",
			  notValidPhone: "{{label}} n'est pas un numéro valide !",
			  ______: "_____"
			},
			storeTab: {
			  name: "Magasin",
			  ______: "_____"
			},
			winpriceTab: {
			  name: "Gagner un prix",
			  ______: "_____"
			},
			arcadesTab: {
			  name: "Magasin",
			  ______: "_____"
			},
			wthdrawTab: {
			  name: "Retrait",
			  ______: "_____"
			},
			historyTab: {
			  name: "Historique",
			  ______: "_____"
			},
			______: "_____",
		},
		billing: {
			overview: {
				name: "Aperçu",
				viwKube: "Voir Kubernetes",
				viwNet: "Voir Réseau",
				title: "Aperçu",
				explanation: "Il s'agit d'une estimation du montant que vous devez en fonction de votre utilisation actuelle du mois après crédits et prépaiements.",
				extDue: "Estimation du dû",
				paymentDue: "Paiement dû",
				prepayment: "Prépaiements",
				date: "Avril, 01 2025",
				totalUsage: "Utilisation totale",
				monthToDate: "Résumé du mois à ce jour 1er - 31 mars 2025",
				thesCharge: "Ces frais sont pris en compte dans votre solde de compte.",
				expand: "Tout développer",
				down: "Télécharger",
				subT: "Sous-total",
				tax: "Taxes",
				total: "TOTAL",
				viewIn: "Voir la facture",
				unknown: "Inconnu",
				amunt: "Montant",
				desc: "Description",
				status: "Statut",
				succ: "Succès",
				err: "Erreur",
				billingHist: "Historique de facturation",
				paymentMethod: {
					addBtn: "Ajoutez un mode de paiement",
					pymentsM: "Modes de paiement",
					primary: "Principal",
					longText: "Le mode de paiement principal est automatiquement débité à la fin de chaque cycle de facturation ou peut être utilisé pour prépayer votre facture.",
					whenAuto: "Lorsque le mode de paiement principal échoue, un mode de paiement de secours paie automatiquement le solde.",
					backup: "Sauvegarde",
					noBackup: "(Aucun mode de paiement de sauvegarde trouvé !)",
					noteInfo: "Notes d'information",
					youCurr: "Vous n'avez actuellement aucun mode de paiement enregistré.",
					promos: "Promotions",
					cpromo: "Code promotionnel",
					havePromo: "Si vous avez un code promotionnel, veuillez l'entrer ci-dessous pour recevoir votre crédit.",
					addPromoCode: "Ajoutez un nouveau code promotionnel",
					apllyCode: "Appliquer le code",
					delete: "Supprimer",
					setPrimary: "Définir comme principal",
					allText: "icône du mode de paiement",
					creditcard: "Carte de crédit",
					addCreditCard: "Ajoutez une carte de crédit",
					acceptable: "Nous acceptons une Visa, MasterCard, American Express, UnionPay et Discover",
					connectpaypal: "Connecter PayPal",
					connectPText: "Connectez votre compte PayPal pour les paiements automatiques",
					addmethodTitle: "Ajoutez un nouveau mode de paiement",
					selectAType: "Sélectionnez un type de mode de paiement à ajouter",
					fillForm: "Remplissez le formulaire",
					connYourPaypal: "Connectez votre PayPal",
					chooseDiff: "Choisissez un autre mode de paiement",
					letSet: "Définissons-le",
					signPaypal: "Connectez-vous à PayPal en cliquant sur le bouton ci-dessous et nous l'ajouterons comme mode de paiement récurrent à votre compte pour une utilisation future.",
					informatioNot: "Notes d'information",
					informNotesContent: "<b>Notes:</b>&nbsp; Afin de connecter PayPal en tant que mode de paiement enregistré, vous devez effectuer une transaction de <b>$5.00</b> pour vérifier votre compte, cette charge sera appliquée à votre première facture et ne doit être configurée qu'une seule fois.",
					Cancel: "Annuler",
					loanding: "Chargement...",
					oCanceled: "Opération annulée !",
					checkpaypalConfig: "Une erreur s'est produite sur PayPal. Veuillez vérifier si votre compte PayPal est correctement configuré.",
					selectPaypMethod: "Sélectionnez un mode de paiement",
					chooseAnother: "Choisissez un autre mode de paiement",
					noBackupFound: "(Aucun mode de paiement de sauvegarde trouvé !)",
					cancel: "Annuler",
					submit: "Soumettre",
					paymicon: "icône du mode de paiement",
					____: "_________",
				},
				____: "_________",
			},
			info: {
				name: "Informations de paiement",
				title: "Aucun paiement dû",
				p1: "Votre compte est entièrement payé et aucun paiement n'est dû pour le moment.",
				mkPayment: "Effectuer un paiement",
				lastInv: "Voir la dernière facture",
				credit: "Crédit",
				addFund: "Choisissez le montant du crédit",
				____: "_________",
			},
			credit: {
				name: "Crédit",
				summ: "Résumé",
				ksPointAndPrice: "KsPoint et prix: <i>(à définir) </i> ",
				empty: "(vide)",
				payWithPaypal: "Payer avec PayPal",
				paying: "Paiement en cours",
				waitpaypal: "Attente de PayPal",
				iAgree: "J'accepte les",
				term: "Conditions d'utilisation",
				____: "_________",
			},
			hist: {
				name: "Historique"
			}
		}
	},
	Deploying: "En déploiement",
	...fr

}
